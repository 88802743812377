<ion-accordion>
  <ion-item slot="header" color="blue200" lines="none">
    <ion-label color="blue600" class="ion-text-wrap">
      <h2>
        {{ presta.datePrestation || presta.createDateTime ? (presta.datePrestation || presta.createDateTime | date: 'mediumDate') : 'Pas de date' }}
        {{ presta.compteRendu?.titre ? presta.compteRendu?.titre : '' }}
      </h2>
    </ion-label>
    <ng-container *ngIf="presta.compteRendu?.textCr">
      <ion-icon
        class="cr-download"
        [style.cursor]="'pointer'"
        (click)="$event.stopPropagation(); envoyerCRParMail(presta.compteRendu)"
        *ngIf="presta.compteRendu && user?.role === UserRole.ROLE_ADMIN && presta.compteRendu.etat !== EtatCompteRenduEnum.EN_COURS"
        name="download-outline"
        color="blue600"
      ></ion-icon>
    </ng-container>
  </ion-item>
  <div slot="content">
    <app-prestation-content *ngIf="user" [user]="user" [presta]="presta" [mail]="mail"></app-prestation-content>
    <ion-item button lines="none">
      <ion-label class="ion-text-wrap">
        <ion-text color="grey900">
          <p><b>Avec :</b> {{ presta.consultant ? 'Dr ' + presta.consultant.nom : 'Aucun consultant enregistré' }}</p>
        </ion-text>
      </ion-label>
    </ion-item>
  </div>
</ion-accordion>
