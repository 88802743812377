import { Injectable } from '@angular/core';
import { Conversation, Interaction, SearchInteractionRequest } from '../../../models/interaction.model';
import { EMPTY, Observable } from 'rxjs';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { selectDeviceTokenWithCredentials } from '@core/store/selector/session.selectors';
import { switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { Page } from '../../../models/page.model';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class InteractionService {
  private readonly apiUrl = environment.API_URL;

  constructor(
    private readonly httpApiService: HttpApiService,
    private readonly store: Store,
  ) {}

  addInteractionRdv(idRdv: string, interaction: Interaction): Observable<Interaction> {
    return this.httpApiService.post<Interaction>(`api/interaction/${idRdv}`, interaction);
  }

  addInteraction(interaction: Interaction): Observable<Interaction> {
    return this.httpApiService.post<Interaction>(`api/interaction/`, interaction);
  }

  searchMessages(searchRequest: SearchInteractionRequest) {
    if (isEmpty(searchRequest)) {
      throw new Error('données vides');
    }

    return this.httpApiService.post<Interaction[]>(`api/interaction/search`, searchRequest);
  }

  subscribeToConversation(idConversation: string): Observable<Interaction> {
    return this.store.select(selectDeviceTokenWithCredentials).pipe(
      take(1),
      switchMap(({ credentials }) => {
        if (credentials?.access_token) {
          const endpoint = this.apiUrl + '/api/interaction/sseDemande/' + idConversation + '/' + credentials?.access_token;

          return this.httpApiService.getAsStream<Interaction>(endpoint);
        } else {
          return EMPTY;
        }
      }),
    );
  }

  unsubscribe(idConversation: string): Observable<void> {
    return this.httpApiService.get<void>('api/interaction/sseUnsubscribe/' + idConversation);
  }

  attributionInteraction(idConversation: string, idUser: string) {
    return this.httpApiService.post<void>(`api/interaction/updateAttribution/${idConversation}${idUser ? `/${idUser}` : ''}`);
  }

  getConversations(convReq: { size?: number; offset?: number; page?: number }) {
    if (isEmpty(convReq)) {
      throw new Error('données vides');
    }

    return this.httpApiService.post<Page<Conversation>>(`api/interaction/conversations/search`, convReq);
  }

  markConversationAsRead(idConversation: string) {
    return this.httpApiService.post<void>(`api/interaction/markAsRead/${idConversation}`);
  }

  getConversation(idConv: string) {
    return this.httpApiService.get<Conversation>(`api/interaction/conversation/${idConv}`);
  }

  countMessagesNonLus() {
    return this.httpApiService.post<number>(`api/interaction/conversations/count`, { nonLues: true });
  }
}
