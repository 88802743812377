import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isEmpty } from 'lodash';
import { map, tap } from 'rxjs';

import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { JwtToken } from 'app/models/jwt-token.model';
import { SessionActions } from '../actions/session.actions';
import { UserApiActions } from '../actions/user.actions';

@Injectable()
export class SessionEffects {
  startAppInitialization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.startAppInitialization),
      map(() => SessionActions.checkUserAuthentication()),
    );
  });

  checkUserAuthentication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.checkUserAuthentication),
      map(() => this.authenticationService.getCredentialsFromStorage()),
      map((credentials: JwtToken | null) => (isEmpty(credentials) ? SessionActions.finishAppInitialization() : SessionActions.setCredentials({ credentials }))),
    );
  });

  resetState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.resetState),
      tap(() => this.authenticationService.clearCredentials()),
      map(() => UserApiActions.logoutSucceeded()),
    );
  });

  resetCredentialOnUserError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.resetStateAndFinishAppInitialization),
        tap(() => {
          this.authenticationService.clearCredentials();
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authenticationService: AuthenticationService,
  ) {}
}
