<ion-header class="ion-no-border ion-no-shadow mobile">
  <ion-toolbar color="blue100"></ion-toolbar>
</ion-header>
<ion-content color="blue100">
  <div class="center">
    <div class="with-sidebar-inverted">
      <div class="welcome">
        <h2>Tarifs</h2>
      </div>
      <div class="line"></div>
    </div>
  </div>

  <!-- A REFONTE LINK OK -->

  <ion-grid fixed>
    <ion-row>
      <ion-col size-xs="12" size-md="6" size-sm="6" size-lg="6" size-xl="6">
        <ion-card class="infos-card" [routerLink]="['produits']">
          <ion-card-content>
            <div>
              <span>Produits</span>
            </div>
          </ion-card-content>
          <ion-item lines="none">
            <ion-icon name="eye-outline" slot="end"></ion-icon>
            <ion-label class="ion-text-wrap"><h1>Voir les tarifs</h1></ion-label>
          </ion-item>
        </ion-card>
      </ion-col>
      <ion-col size-xs="12" size-md="6" size-sm="6" size-lg="6" size-xl="6">
        <ion-card class="infos-card" [routerLink]="['actes']">
          <ion-card-content>
            <div>
              <span>Actes</span>
            </div>
          </ion-card-content>
          <ion-item lines="none">
            <ion-icon name="eye-outline" slot="end"></ion-icon>
            <ion-label class="ion-text-wrap"><h1>Voir les tarifs</h1></ion-label>
          </ion-item>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
