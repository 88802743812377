import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChatDiscussion } from 'app/models/chat.model';
import { PartenaireMilou, RdvStructure } from 'app/models/client.model';
import { ChatMessage } from 'app/models/message.model';
import { User } from 'app/models/user.model';

export const ChatActions = createActionGroup({
  source: 'Chat',
  events: {
    'Subscribe Channel': props<{ idDiscussion: string }>(),
    'Subscribe Channel Success': props<{ message: ChatMessage }>(),
    'Get new Message success': emptyProps(),
    'Get new Message error': emptyProps(),
    'Unsubscribe Channel': props<{ idDiscussion: string }>(),
    'Unsubscribe Channel Success': emptyProps(),
    getMessages: props<{ idDiscussion: string }>(),
    'Reset Message State': emptyProps(),
    'Get messages success': props<{ messages: ChatMessage[] }>(),
    'Get messages error': props<{ error: string }>(),
    'Maj Date Lecture': props<{ discussion: ChatDiscussion | null }>(),
    'Send Message': props<{ message: string; idDiscussion: string }>(),
    'Send Message Success': props<{ newMessage: ChatMessage }>(),
    'Cloture Discussion': props<{ idDiscussion: string }>(),
    'Load Veterinaires': emptyProps(),
    'Load Veterinaires Success': props<{ listVeto: User[] }>(),
    'Attribution Discussion': props<{ idDiscussion: string; idVeto: string | undefined }>(),
    'Attribution Discussion Success': props<{ discussion: ChatDiscussion }>(),
    sseChange: props<{ oldId: string; nextDiscussion: ChatDiscussion }>(),
    'Send Chat Dispo': props<{ dateDebut: string; dateFin: string; idStructure: string; idVeto: string | undefined }>(),
    'is Veto Dispo': props<{ idStructure: string; idVeto: string | undefined }>(),
    'is Veto Dispo Success': props<{ isDispo: boolean }>(),
    'is Milou Dispo': props<{ codeInsee: string | undefined }>(),
    'is Dispo Milou Success': props<{ rdvStructure: RdvStructure }>(),
    'get min price success': props<{ minPrice: number }>(),
    'get Partenaire VAD': props<{ codeInsee: string | undefined; indisponible: string }>(),
    'get Partenaire VAD success': props<{ listPartenaire: PartenaireMilou[]; indisponible: string }>(),
  },
});
