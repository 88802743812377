import { Component, Input } from '@angular/core';
import { CompteRendu, EtatCompteRenduEnum } from '../../../models/compte-rendu.model';
import { User, UserRole } from '../../../models/user.model';
import { Prestation } from '../../../models/rdv.model';
import { ModalController } from '@ionic/angular';
import { EnvoiCrComponent } from '../../rdv/wizard/components/compte-rendu/envoi-cr/envoi-cr.component';
import { CompteRenduService } from '../../rdv/wizard/services/compte-rendu.service';

@Component({
  selector: 'app-prestation',
  templateUrl: './prestation.component.html',
  styleUrls: ['./prestation.component.scss'],
})
export class PrestationComponent {
  @Input() presta!: Prestation;
  @Input() mail!: string;
  @Input() user!: User;
  protected readonly EtatCompteRenduEnum = EtatCompteRenduEnum;
  protected readonly UserRole = UserRole;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly compteRenduService: CompteRenduService,
  ) {}

  async envoyerCRParMail(cr: CompteRendu) {
    const modal = await this.modalCtrl.create({
      component: EnvoiCrComponent,
      cssClass: 'envoi-cr-modal',
      componentProps: {
        title: cr.etat === EtatCompteRenduEnum.ENVOYE ? 'Renvoyer le compte-rendu' : 'Envoyer le compte-rendu',
        email: this.mail,
        idRdv: cr.idRdv,
        idCr: cr!.id,
      },
    });
    await modal.present();
  }
}
