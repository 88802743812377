import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { UserRole } from '../../../../models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BotService } from '@core/services/bot/bot.service';
import { Store } from '@ngrx/store';
import { selectActiveAnimal } from '@core/store/selector/rendezvous.selector';
import { map, take } from 'rxjs/operators';
import { combineLatestWith, switchMap } from 'rxjs';
import { selectActiveStructure } from '@core/store/selector/admin.selector';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
})
export class ChatbotComponent {
  messageForm: FormGroup;

  @ViewChild('messageInput', { read: ElementRef }) set messageInputSet(messageInput: ElementRef) {
    if (messageInput) {
      setTimeout(() => {
        messageInput.nativeElement.setFocus();
      }, 20);
    }
  }

  isLoading = false;

  showChat = false;
  chatMessages: { fromUser: boolean; message: string }[] = [
    {
      fromUser: false,
      message: 'Bonjour, je suis Skapiroo. En quoi puis-je vous aider ?',
    },
  ];
  protected readonly UserRole = UserRole;

  constructor(
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly botService: BotService,
    private readonly store: Store,
  ) {
    this.messageForm = this.fb.group({
      message: ['', [Validators.minLength(1), Validators.maxLength(2024), Validators.required]],
    });
  }

  submit() {
    const question = this.messageForm.get('message')!.value;
    if (!question || question === '') {
      return;
    }
    this.isLoading = true;
    this.chatMessages.unshift({ fromUser: true, message: question });
    this.store
      .select(selectActiveAnimal)
      .pipe(
        take(1),
        combineLatestWith(
          this.store.select(selectActiveStructure).pipe(
            take(1),
            map(s => s?.id),
          ),
        ),
        switchMap(animalAndStructure => this.botService.ask(question, animalAndStructure[0], animalAndStructure[1])),
      )
      .subscribe({
        next: response => {
          this.isLoading = false;
          this.chatMessages.unshift({ fromUser: false, message: response });

          this.cd.markForCheck();
        },
        complete: () => {
          this.isLoading = false;
        },
        error: e => {
          console.error(e);
          this.isLoading = false;
        },
      });
    this.messageForm.reset();
  }

  showOrHideChat() {
    this.showChat = !this.showChat;
  }
}
