import { Component, Input } from '@angular/core';
import { TooltipPosition } from '../../tooltip/tooltip.model';
import { RdvDomicile } from '../../../../../models/rdv.model';
import { User } from '../../../../../models/user.model';

@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss'],
})
export class ConsultationComponent {
  @Input() consultation!: RdvDomicile;
  @Input() user!: User;
  @Input() idAnimalFilter?: string;

  protected readonly position = TooltipPosition;

  constructor() {}

  goToConsultation(id: string) {
    window.open('/rendezvous/' + id, '_blank');
  }
}
