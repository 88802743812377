import { Injectable } from '@angular/core';
import { Prestation } from 'app/models/rdv.model';
import { Observable } from 'rxjs';
import { HttpApiService } from '../http-api/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class PrestationService {
  constructor(private readonly httpApiService: HttpApiService) {}

  createPrestation(prestation: Prestation, idRdv: string): Observable<Prestation> {
    return this.httpApiService.post<Prestation>(`api/back_office/prestation/classique/${idRdv}`, prestation);
  }

  countPrestationsForAnimal(idAnimal: string): Observable<number> {
    return this.httpApiService.get<number>(`api/back_office/prestation/count/animal/${idAnimal}`);
  }

  updatePrestation(prestation: Prestation): Observable<Prestation> {
    return this.httpApiService.post<Prestation>(`api/back_office/prestation/classique`, prestation);
  }

  updateCommentaireInterne(commentaireInterne: string, idPrestation: string): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/prestation/classique/${idPrestation}/updateCommentaireInterne`, commentaireInterne);
  }

  countPrestationsForClient(id: string) {
    return this.httpApiService.get<number>(`api/back_office/prestation/count/user/${id}`);
  }

  getPrestas(idAnimal: string) {
    return this.httpApiService.get<Prestation[]>(`api/back_office/prestation/${idAnimal}`);
  }
}
