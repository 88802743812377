import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private readonly toastCtrl: ToastController) {}

  async notify(notificationType: string, duration = 7000) {
    const toast = await this.toastCtrl.create({
      message: notificationType,
      duration: duration,
      position: 'bottom',
    });

    toast.present();
  }
}
