import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { DiscussionActions } from '@core/store/actions/discussion.actions';
import { ChatService } from '../../../chat/services/chat.service';
import { LoadingController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { selectAllActiveDiscussions, selectDiscussions, selectWaitingDiscussions } from '@core/store/selector/discussion.selectors';
import { InteractionService } from '@core/services/interaction.service';

@Injectable()
export class DiscussionEffects {
  loadDiscussion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiscussionActions.loadDiscussion),
      switchMap(() =>
        this.chatService.getMyDiscussions().pipe(
          map(discussions => DiscussionActions.loadDiscussionSucced({ discussions })),
          catchError((error: HttpErrorResponse) => {
            return of(DiscussionActions.loadDiscussionError({ error: error.error }));
          }),
        ),
      ),
    );
  });

  loadWaitingDiscussion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiscussionActions.loadWaitingDiscussion),
      switchMap(() =>
        this.chatService.getWaitingDiscussions().pipe(
          map(waitingDiscussions => DiscussionActions.loadWaitingDiscussionSucced({ waitingDiscussions })),
          catchError((error: HttpErrorResponse) => {
            return of(DiscussionActions.loadWaitingDiscussionError({ error: error.error }));
          }),
        ),
      ),
    );
  });

  majLectureDiscussion = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiscussionActions.majLectureDiscussion),
      switchMap(action => {
        return this.chatService.majLectureDiscussion(action.idDiscussion, this.store.select(selectDiscussions).pipe(distinctUntilChanged())).pipe(
          map(discussions => {
            return DiscussionActions.loadDiscussionSucced({ discussions });
          }),
        );
      }),
    );
  });

  majLectureAllActiveDiscussion = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiscussionActions.majLectureDiscussion),
      switchMap(action => {
        return this.chatService.majLectureDiscussion(action.idDiscussion, this.store.select(selectAllActiveDiscussions).pipe(distinctUntilChanged())).pipe(
          map(activeDiscussions => {
            return DiscussionActions.loadAllActiveDiscussionSucced({ allActiveDiscussions: activeDiscussions });
          }),
        );
      }),
    );
  });

  majLectureWaitingDiscussion = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiscussionActions.majLectureDiscussion),
      switchMap(action => {
        return this.chatService.majLectureDiscussion(action.idDiscussion, this.store.select(selectWaitingDiscussions).pipe(distinctUntilChanged())).pipe(
          map(waitingDiscussions => {
            return DiscussionActions.loadWaitingDiscussionSucced({ waitingDiscussions });
          }),
        );
      }),
    );
  });

  loadAllActiveDiscussion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiscussionActions.loadAllActiveDiscussion),
      switchMap(() => {
        return this.chatService.getAllActiveDiscussions().pipe(
          map(activeDiscussions => {
            return DiscussionActions.loadAllActiveDiscussionSucced({ allActiveDiscussions: activeDiscussions });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(DiscussionActions.loadAllActiveDiscussionError({ error: error.error }));
          }),
        );
      }),
    );
  });

  clotureDiscussion = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiscussionActions.onClotureDiscussion),
      switchMap(action => {
        return this.chatService.cloturerDiscussion(action.idDiscussion).pipe(
          map(() => {
            // eslint-disable-next-line @ngrx/no-dispatch-in-effects
            // this.store.dispatch(DiscussionActions.loadDiscussion());
            // this.loadingCtrl.dismiss();

            return DiscussionActions.onClotureSuccess();
          }),
        );
      }),
    );
  });

  clotureSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiscussionActions.onClotureSuccess),
      map(() => {
        this.loadingCtrl.dismiss();

        return DiscussionActions.loadDiscussion();
      }),
    );
  });

  getunreadmessagescount = createEffect(() => {
    return this.actions$.pipe(
      ofType(DiscussionActions.getunreadmessagescount),
      switchMap(() => {
        return this.interactionService.countMessagesNonLus().pipe(map(count => DiscussionActions.getunreadmessagescountsuccess({ count })));
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly interactionService: InteractionService,
    private readonly chatService: ChatService,
    private readonly loadingCtrl: LoadingController,
  ) {}
}
