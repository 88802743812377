import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChatDiscussion } from '../../../../models/chat.model';

export const DiscussionActions = createActionGroup({
  source: 'Discussion',
  events: {
    'Load Discussion': emptyProps(),
    'Load Waiting Discussion': emptyProps(),
    'Load All Active Discussion': emptyProps(),
    'Load Discussion Succed': props<{ discussions: ChatDiscussion[] }>(),
    'Maj Lecture Discussion': props<{ idDiscussion: string }>(),
    'Maj Lecture Waiting Discussion': props<{ idDiscussion: string }>(),
    'Maj Lecture All Active Discussion': props<{ idDiscussion: string }>(),
    'Load Waiting Discussion Succed': props<{ waitingDiscussions: ChatDiscussion[] }>(),
    'Load All Active Discussion Succed': props<{ allActiveDiscussions: ChatDiscussion[] }>(),
    'Load Discussion Error': props<{ error: string }>(),
    'Load Waiting Discussion Error': props<{ error: string }>(),
    'Load All Active Discussion Error': props<{ error: string }>(),
    'Add Discussion': props<{ libelle: string }>(),
    'Add Discussion Succed': props<{ newDiscussion: ChatDiscussion }>(),
    'Set active discussion': props<{ activeDiscussion: ChatDiscussion }>(),
    'On Cloture discussion': props<{ idDiscussion: string }>(),
    'On Cloture success': emptyProps(),
    getUnreadMessagesCount: emptyProps(),
    getUnreadMessagesCountSuccess: props<{ count: number }>(),
    readDiscussion: emptyProps(),
  },
});
