import { Action, createReducer, on } from '@ngrx/store';
import { EtatFacturationEnum, EtatFactureEnum, Prestation } from '../../../../models/rdv.model';
import { PrestationAction } from '@core/store/actions/prestation.action';
import { ProduitActions } from '@core/store/actions/produit.actions';
import { isNil } from 'lodash';
import { RdvDomicileAction } from '@core/store/actions/rdv-domicile.action';

export interface PrestationState {
  readonly activePrestation: Prestation | null;
}

export const initialState: PrestationState = {
  activePrestation: null,
};

function addOrSave(savedObj: any, objList?: any[], listIdx?: number) {
  if (isNil(objList)) {
    return [savedObj];
  }
  if (!objList.map(existingObj => existingObj.id).includes(savedObj.id)) {
    if (listIdx === undefined) {
      return [...objList, savedObj];
    } else {
      const newObjList = [...objList];
      newObjList[listIdx] = savedObj;

      return newObjList;
    }
  } else {
    return objList.map(existingObj => (existingObj.id === savedObj.id ? savedObj : existingObj));
  }
}

export const prestationReducer = createReducer(
  initialState,

  on(
    PrestationAction.accessPrestationSuccess,
    (state, { prestation }): PrestationState => ({
      ...state,
      activePrestation: prestation,
    }),
  ),
  on(
    PrestationAction.resetactiveprestation,
    (state): PrestationState => ({
      ...state,
      activePrestation: null,
    }),
  ),
  on(
    PrestationAction.saveprestationsuccess,
    (state, { prestation: updatedPrestation }): PrestationState => ({
      ...state,
      activePrestation: updatedPrestation,
    }),
  ),
  on(
    PrestationAction.createcrsuccess,
    (state, { compteRendu }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        compteRendu,
      },
    }),
  ),
  on(
    PrestationAction.markfacturepayeesuccess,
    (state, { id }): PrestationState => ({
      ...state,
      activePrestation: state.activePrestation
        ? {
            ...state.activePrestation!,
            factures: state.activePrestation!.factures!.map(f => (f.id === id ? { ...f, etat: EtatFactureEnum.PAYEE } : f)),
            facturations: state.activePrestation!.facturations!.map(f => (f.idFacture === id ? { ...f, etat: EtatFacturationEnum.PAYEE } : f)),
          }
        : null,
    }),
  ),
  on(
    ProduitActions.saveordonnancessuccess,
    (state, { ordonnances }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        ordonnances,
      },
    }),
  ),
  on(
    RdvDomicileAction.updateAnimalSuccess,
    (state, { updatedAnimal }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        animal: updatedAnimal,
      },
    }),
  ),
  on(
    ProduitActions.saveordonnancesuccess,
    (state, { ordonnance }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        ordonnances: addOrSave(ordonnance, state.activePrestation!.ordonnances),
      },
    }),
  ),
  on(
    ProduitActions.deleteordonnancesuccess,
    (state, { idOrdonnance }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        ordonnances: state.activePrestation!.ordonnances!.filter(ordo => ordo.id !== idOrdonnance),
      },
    }),
  ),
  on(
    ProduitActions.deletefacturationsuccess,
    (state, { id }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        facturations: state.activePrestation!.facturations!.filter(facturation => facturation.id !== id),
      },
    }),
  ),
  on(
    ProduitActions.mergefacturationsuccess,
    (state, { facturations }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        facturations: facturations,
      },
    }),
  ),
  on(
    ProduitActions.savefacturationsuccess,
    (state, { facturation, idxFacturation }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        facturations: addOrSave(facturation, state.activePrestation!.facturations, idxFacturation),
      },
    }),
  ),
  on(
    ProduitActions.updatefacturationstatesuccess,
    (state, { facturation, idxFacturation }): PrestationState => ({
      ...state,
      activePrestation: state.activePrestation
        ? {
            ...state.activePrestation,
            facturations: addOrSave(facturation, state.activePrestation.facturations, idxFacturation),
          }
        : null,
    }),
  ),
  on(
    ProduitActions.savefacturationssuccess,
    (state, { facturations }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        facturations,
      },
    }),
  ),
  on(
    PrestationAction.updatecommentaireinternesuccess,
    (state, { commentaireInterne }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        commentaireInterne,
      },
    }),
  ),
  on(
    PrestationAction.demandeconsentementsuccess,
    (state, { consentement }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        consentements: addOrSave(consentement, state.activePrestation!.consentements),
      },
    }),
  ),
);

export function reducer(state: PrestationState, action: Action): PrestationState {
  return prestationReducer(state, action);
}
