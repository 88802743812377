<ion-accordion>
  <ion-item slot="header" color="blue200" lines="none" class="standalone-cr" [tooltip]="'Complément'" [position]="position.LEFT">
    <ion-label color="blue600" class="ion-text-wrap">
      <h2>{{ cr.dateCompteRendu ? (cr.dateCompteRendu | date: 'mediumDate') : 'Pas de date' }} {{ cr.titre }}</h2>
      <p *ngIf="showNomAnimal && cr.nomAnimal">{{ cr.nomAnimal | titlecase }}</p>
      <p *ngIf="cr.auteur">
        {{
          'Auteur : ' +
            cr.auteur.prenom +
            ' ' +
            cr.auteur.nom +
            (!!cr.lastModifUser && cr.lastModifUser.id !== cr.auteur.id ? ' (modifié par ' + cr.lastModifUser.prenom + ' ' + cr.lastModifUser.nom + ')' : '')
        }}
      </p>
    </ion-label>
  </ion-item>
  <div slot="content">
    <ng-container *ngIf="cr.files?.length">
      <ion-list class="file-list">
        <ion-item *ngFor="let file of cr.files; let i = index; let last = last" [lines]="last ? '' : 'none'">
          <ng-container [ngSwitch]="file.type">
            <ion-icon slot="start" name="camera-outline" *ngSwitchCase="FileCRTypeEnum.PHOTO" title="Photo"></ion-icon>
            <ion-icon slot="start" name="chatbox-ellipses-outline" *ngSwitchCase="FileCRTypeEnum.RETOUR_REFERE" title="Retour référé"></ion-icon>
            <ion-icon slot="start" name="document-text-outline" *ngSwitchCase="FileCRTypeEnum.CERTIFICAT" title="Certificat"></ion-icon>
            <ion-icon slot="start" name="flask-outline" *ngSwitchCase="FileCRTypeEnum.RESULTAT_ANALYSE" title="Résultat d'analyse"></ion-icon>
            <ion-icon slot="start" name="document-outline" *ngSwitchCase="FileCRTypeEnum.AUTRE" title="Autre"></ion-icon>
            <ion-icon slot="start" name="document-text-outline" *ngSwitchCase="FileCRTypeEnum.INCONNU" title="Inconnu"></ion-icon>
          </ng-container>
          <ion-label class="ion-text-wrap">{{ file.fileName }}</ion-label>
          <ion-button (click)="downloadFile(file)" fill="outline" color="blue400" *ngIf="file.mimeType !== 'application/pdf'">
            <ion-icon name="download-outline" color="blue600"></ion-icon>
          </ion-button>
          <ion-button (click)="viewFile(file)" fill="outline" color="blue400" *ngIf="file.mimeType === 'application/pdf'">
            <ion-icon name="eye-outline" color="blue600"></ion-icon>
          </ion-button>
          <ion-button (click)="deleteFile(file.id!)" fill="outline" color="suppr" *ngIf="canModifie()">
            <ion-icon name="close-outline" color="wait"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
    </ng-container>
    <ion-item lines="none" *ngIf="cr.textCr">
      <ion-text class="large" [innerHTML]="cr.textCr"></ion-text>
    </ion-item>
    <ng-container *ngIf="canModifie()">
      <div class="ion-text-center">
        <ion-button (click)="edit()" shape="round">
          <ion-icon name="create-outline" color="white" slot="start"></ion-icon>
          Modifier
        </ion-button>
        <ion-button (click)="delete()" shape="round" color="danger">
          <ion-icon name="close-outline" color="white" slot="start"></ion-icon>
          Supprimer
        </ion-button>
      </div>
      <br />
    </ng-container>
  </div>
</ion-accordion>
