import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { notifAction } from '../actions/notifications.action';

@Injectable()
export class notifEffects {
  constructor(private readonly actions$: Actions) {}

  NotifReceived$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(notifAction.notifReceived),
        tap(action => {
          if (action.data) {
            return action.data, action.notification;
          }

          return false;
        }),
      );
    },
    { dispatch: false },
  );

  resetNotifState$ = createEffect(
    () => {
      console.log('reset notif effect');

      return this.actions$.pipe(
        ofType(notifAction.resetNotifState),
        tap(() => {
          console.log('Reset Notif State');

          return;
        }),
      );
    },
    { dispatch: false },
  );
}
