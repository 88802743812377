import { Action, createReducer, on } from '@ngrx/store';
import { Animal, Espece, Race } from 'app/models/animal.model';
import { Client, EtatFacturationEnum, EtatFactureEnum, RdvDomicile, RdvPlanning } from '../../../../models/rdv.model';
import { RdvDomicileAction } from '@core/store/actions/rdv-domicile.action';
import { PrestationAction } from '@core/store/actions/prestation.action';
import { isNil } from 'lodash';
import { RdvService } from '@core/services/consultations/rdv.service';

export interface RdvDomicileState {
  readonly rdvList: RdvPlanning[];
  readonly activeRdv: RdvDomicile | null;
  readonly activeRdvEditable: boolean | null;
  readonly activeFactuEditable: boolean | null;
  readonly activeClient: Client | null;
  readonly activeAnimal: Animal | null;
  readonly races?: Race[];
  readonly especes?: Espece[];
  readonly animalClientList?: Animal[];
}

export const initialState: RdvDomicileState = {
  rdvList: [],
  activeRdv: null,
  activeRdvEditable: null,
  activeFactuEditable: null,
  activeClient: null,
  activeAnimal: null,
};

function addOrSave(savedObj: any, objList: any[] | undefined) {
  if (isNil(objList)) {
    return [savedObj];
  }
  if (!objList.map(existingObj => existingObj.id).includes(savedObj.id)) {
    return [...objList, savedObj];
  } else {
    return objList.map(existingObj => (existingObj.id === savedObj.id ? savedObj : existingObj));
  }
}

export const rdvDomicileReducer = createReducer(
  initialState,
  // rendez vous
  on(RdvDomicileAction.loadAllRendezvousSuccess, (state, { allRdv }): RdvDomicileState => ({ ...state, rdvList: allRdv })),
  on(RdvDomicileAction.checkrdveditablesuccess, (state, { editable }): RdvDomicileState => ({ ...state, activeRdvEditable: editable })),
  on(RdvDomicileAction.checkfactueditablesuccess, (state, { editable }): RdvDomicileState => ({ ...state, activeFactuEditable: editable })),
  on(
    RdvDomicileAction.updaterdvstatesuccess,
    (state, { id, rdvState }): RdvDomicileState => ({
      ...state,
      activeRdv: state.activeRdv ? { ...state.activeRdv, rdvStep: state.activeRdv.id === id ? rdvState : state.activeRdv.rdvStep } : null,
      rdvList: state!.rdvList.map(rdv => {
        if (rdv.id === id) {
          return { ...rdv, rdvStep: rdvState };
        } else {
          return rdv;
        }
      }),
    }),
  ),
  on(
    RdvDomicileAction.updaterdvsuccess,
    (state, { rdv }): RdvDomicileState => ({
      ...state,
      activeRdv: rdv,
      rdvList: state!.rdvList.map(r => {
        if (rdv.id === r.id) {
          return RdvService.rdvToRdvPlanning(rdv)!;
        } else {
          return r;
        }
      }),
    }),
  ),

  on(
    RdvDomicileAction.accessRendezvousSuccess,
    (state, { activeRendezvous }): RdvDomicileState => ({
      ...state,
      activeRdv: activeRendezvous,
      // activeAnimal: activeRendezvous.animaux[0],
      activeClient: activeRendezvous?.client,
    }),
  ),
  on(
    RdvDomicileAction.resetActiveRendezvous,
    (state): RdvDomicileState => ({
      ...state,
      activeRdv: initialState.activeRdv,
      activeClient: initialState.activeClient,
      activeAnimal: initialState.activeAnimal,
    }),
  ),

  on(
    PrestationAction.saveprestationsuccess,
    (state, { prestation: updatedPrestation }): RdvDomicileState => ({
      ...state,
      activeRdv: {
        ...state.activeRdv!,
        prestations: addOrSave(updatedPrestation, state.activeRdv!.prestations),
      },
    }),
  ),
  //client
  on(
    RdvDomicileAction.editClientSuccess,
    (state, { updatedClient }): RdvDomicileState => ({ ...state, activeRdv: { ...state.activeRdv!, client: updatedClient }, activeClient: updatedClient }),
  ),

  //animals
  on(
    RdvDomicileAction.updateAnimalSuccess,
    (state, { updatedAnimal }): RdvDomicileState => ({
      ...state,
      activeRdv: {
        ...state.activeRdv!,
        animaux: addOrSave(updatedAnimal, state.activeRdv!.animaux),
      },
      activeAnimal: updatedAnimal,
    }),
  ),
  on(
    RdvDomicileAction.removeanimalfromrdvsuccess,
    (state, { idAnimal }): RdvDomicileState => ({
      ...state,
      activeRdv: state.activeRdv
        ? {
            ...state.activeRdv!,
            animaux: state.activeRdv!.animaux.filter(a => a.id !== idAnimal),
          }
        : null,
    }),
  ),

  on(RdvDomicileAction.getEspeceSuccess, (state, { especes }): RdvDomicileState => ({ ...state, especes })),
  on(RdvDomicileAction.getAnimalRaceSuccess, (state, { races }): RdvDomicileState => ({ ...state, races })),

  on(RdvDomicileAction.getAnimalListClientSuccess, (state, { animalClientList }): RdvDomicileState => ({ ...state, animalClientList: animalClientList })),
  on(RdvDomicileAction.changeActiveAnimal, (state, { changedAnimal }): RdvDomicileState => ({ ...state, activeAnimal: changedAnimal })),

  on(
    RdvDomicileAction.changeacteconsultationsuccess,
    (state, { acte, idRdv }): RdvDomicileState => ({
      ...state,
      rdvList: state.rdvList.map(rdv => {
        if (rdv.id === idRdv) {
          return {
            ...rdv,
            acte,
          };
        } else {
          return rdv;
        }
      }),
      activeRdv: { ...state.activeRdv!, acte },
    }),
  ),

  on(RdvDomicileAction.setclientstepsuccess, (state): RdvDomicileState => ({ ...state, activeRdv: { ...state.activeRdv!, infosClientRenseignees: true } })),
  on(
    PrestationAction.markfacturepayee,
    (state, { id, idPrestation }): RdvDomicileState => ({
      ...state,
      activeRdv: {
        ...state.activeRdv!, // on cherche la bonne presta puis la bonne facture dans celle-ci pour màj son état
        prestations: state.activeRdv!.prestations!.map(p =>
          p.id === idPrestation ? { ...p, factures: p.factures!.map(f => (f.id === id ? { ...f, etat: EtatFactureEnum.PAYEE } : f)) } : p,
        ),
      },
    }),
  ),
  on(
    RdvDomicileAction.ajoutpaiementsuccess,
    (state, { paiement }): RdvDomicileState => ({
      ...state,
      activeRdv: { ...state.activeRdv!, paiements: paiement ? [...state.activeRdv!.paiements, paiement] : state.activeRdv!.paiements },
    }),
  ),
  on(
    RdvDomicileAction.reloadpaiementssuccess,
    (state, { paiements }): RdvDomicileState => ({
      ...state,
      activeRdv: { ...state.activeRdv!, paiements },
    }),
  ),
  on(
    RdvDomicileAction.deletepaiementsuccess,
    (state, { idPaiement }): RdvDomicileState => ({
      ...state,
      activeRdv: { ...state.activeRdv!, paiements: state.activeRdv!.paiements.filter(paiement => paiement.id !== idPaiement) },
    }),
  ),
  on(
    PrestationAction.markfacturepayeesuccess,
    (state, { id, idPrestation }): RdvDomicileState => ({
      ...state,
      activeRdv: {
        ...state.activeRdv!,
        prestations: [
          ...state.activeRdv!.prestations.map(p =>
            p.id === idPrestation
              ? {
                  ...p,
                  factures: p.factures!.map(f => (f.id === id ? { ...f, etat: EtatFactureEnum.PAYEE } : f)),
                  facturations: p.facturations!.map(f => (f.idFacture === id ? { ...f, etat: EtatFacturationEnum.PAYEE } : f)),
                }
              : p,
          ),
        ],
      },
    }),
  ),
);

export function reducer(state: RdvDomicileState, action: Action): RdvDomicileState {
  return rdvDomicileReducer(state, action);
}
