import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultValue',
  pure: true,
})
export class DefaultValuePipe implements PipeTransform {
  constructor() {}

  transform(value: any, defaultValue?: string): string {
    if (value !== undefined && value !== null && value !== '') {
      return value;
    } else {
      return defaultValue ? defaultValue : '-';
    }
  }
}
