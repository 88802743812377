import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromClients from '../reducers/clients.reducer';

export const selectClientState = createFeatureSelector<fromClients.clientsState>('clients');

export const selectClientSearchList = createSelector(selectClientState, (state: fromClients.clientsState) => state.clientScroll);

export const selectClientSearchParams = createSelector(selectClientState, (state: fromClients.clientsState) => state.clientList);

export const selectActiveClientSearch = createSelector(selectClientState, (state: fromClients.clientsState) => state.activeClientSearch);

export const selectAnimalClientSearch = createSelector(selectClientState, (state: fromClients.clientsState) => state.animalClientList);

export const selectActiveAnimalFiche = createSelector(selectClientState, (state: fromClients.clientsState) => state.activeAnimal);

export const selectClientFactures = createSelector(selectClientState, (state: fromClients.clientsState) => {
  return state.historiqueList?.flatMap(h => h.prestations.flatMap(p => p.factures));
});

export const selectHistoriqueClient = createSelector(selectClientState, (state: fromClients.clientsState) => state.historiqueList);
export const selectHorsRdvClient = createSelector(selectClientState, (state: fromClients.clientsState) => state.horsRdvClient);

export const selectListPoids = createSelector(selectClientState, (state: fromClients.clientsState) => state.listPoids);
