<ion-header>
  <ion-toolbar color="blue100" class="ion-text-center">
    <ion-buttons slot="start">
      <ion-button color="blue600" (click)="cancel()">Fermer</ion-button>
    </ion-buttons>
    <ion-title color="grey900">{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button color="blue100">Fermer</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="blue100" class="envoi-dossier-medical">
  <ion-grid fixed>
    <ion-row class="ion-text-center">
      <ion-col>
        <ion-label>Sélectionnez le destinataire</ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-segment [(ngModel)]="destinaire" class="button-segment" (click)="changeDestinaire()">
          <ion-segment-button value="CLIENT">
            <ion-label>CLIENT</ion-label>
          </ion-segment-button>
          <ion-segment-button value="RETOUR_CLINIQUE">
            <ion-label>Retour clinique</ion-label>
          </ion-segment-button>
          <ion-segment-button value="ENVOI_CLINIQUE">
            <ion-label>Envoi clinique</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>
    <br />
    <ion-row class="ion-text-center">
      <ion-col>
        <ion-label>Sélectionnez les pièces à envoyer</ion-label>
      </ion-col>
    </ion-row>
    <div *ngFor="let crWrapped of crs" class="cr-item">
      <ion-row>
        <ion-col>
          <ion-item lines="none">
            <input type="checkbox" slot="start" [(ngModel)]="crWrapped.selected" (click)="crCheck($event, crWrapped.cr); $event.stopPropagation()" />
            <ion-label class="ion-text-wrap" (click)="crCheck($event, crWrapped.cr); $event.stopPropagation()"
              >{{ crWrapped.cr.titre }} du {{ crWrapped.cr.dateCompteRendu | date }}
            </ion-label>
            <ion-icon name="information-circle-outline" slot="end" (click)="openModal(crWrapped.cr.textCr)" class="larger"></ion-icon>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row *ngFor="let fileWrapped of crWrapped.filesWrapped" class="file-item">
        <ion-col>
          <div class="file-item-center">
            <ion-item class="check-file-item" lines="none">
              <input type="checkbox" slot="start" [(ngModel)]="fileWrapped.selected" (click)="fileCheck($event, crWrapped.cr)" />
              <ion-label class="ion-text-wrap" color="blue600">{{ fileWrapped.file.fileName }}</ion-label>
              <ng-container [ngSwitch]="fileWrapped.file.type">
                <ion-icon name="camera-outline" *ngSwitchCase="FileCRTypeEnum.PHOTO" title="Photo"></ion-icon>
                <ion-icon name="chatbox-ellipses-outline" *ngSwitchCase="FileCRTypeEnum.RETOUR_REFERE" title="Retour référé"></ion-icon>
                <ion-icon name="document-text-outline" *ngSwitchCase="FileCRTypeEnum.CERTIFICAT" title="Certificat"></ion-icon>
                <ion-icon name="flask-outline" *ngSwitchCase="FileCRTypeEnum.RESULTAT_ANALYSE" title="Résultat d'analyse"></ion-icon>
                <ion-icon name="document-outline" *ngSwitchCase="FileCRTypeEnum.AUTRE" title="Autre"></ion-icon>
              </ng-container>
            </ion-item>
          </div>
        </ion-col>
      </ion-row>
    </div>
    <ion-row>
      <ion-col>
        <ion-button class="btn primary-btn" expand="block" color="secondary" (click)="download()">
          <ion-icon name="download-outline"></ion-icon>
          <ion-label>Télécharger le dossier médical</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item lines="none" shape="round" color="blue100">
          <ion-label color="blue700">Email :</ion-label>
          <ion-input [(ngModel)]="clientEmail"></ion-input>
        </ion-item>
        <ion-button class="btn secondary-btn" expand="block" color="secondary" [disabled]="loadingSend" (click)="send()">
          <ion-spinner color="blue600" *ngIf="loadingSend"></ion-spinner>
          <ion-icon name="send-outline" *ngIf="!loadingSend"></ion-icon>

          <ion-label>Envoyer le dossier médical</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
