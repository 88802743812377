import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sexe',
  pure: true,
})
export class SexePipe implements PipeTransform {
  constructor() {}

  transform(value?: string): string {
    if (!value) {
      return 'Non renseigné';
    } else if (value === 'MALE') {
      return 'Mâle';
    } else {
      return 'Femelle';
    }
  }
}
