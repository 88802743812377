import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Acte } from '../../../../models/rdv.model';
import { EMPTY, map, Observable } from 'rxjs';
import { Espece, Race } from '../../../../models/animal.model';
import { Produit, ProduitPrescrit, ProduitRenouvelable, SearchProduitRequest } from '../../../../models/produit.model';
import { Page } from '../../../../models/page.model';
import { JourFerie } from '../../../../models/planning.model';

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  constructor(private readonly httpApiService: HttpApiService) {}

  findActesByIdStructure(idStructure: string): Observable<Acte[]> {
    return this.httpApiService.get(`api/back_office/reference/actes/${idStructure}`);
  }

  getEspeces(): Observable<Espece[]> {
    return this.httpApiService.get<Espece[]>('api/especes').pipe(map(data => data));
  }

  getJoursFeriesBetweenDates(minDate: string, maxDate: string): Observable<JourFerie[]> {
    return this.httpApiService.get<JourFerie[]>(`api/jours-feries/${minDate}/${maxDate}`);
  }

  getAnimalRaces(espece: string | undefined): Observable<Race[]> {
    return this.httpApiService.get<Race[]>(`api-particuliers/animal/${espece}/races`).pipe(map(raceData => raceData));
  }

  public getAllProduits(): Observable<Produit[]> {
    return this.httpApiService.get(`api/back_office/produit/`);
  }

  public getRenouvelablesByAnimal(idAnimal: string): Observable<ProduitRenouvelable[]> {
    return this.httpApiService.get(`api/back_office/produit/renouvelables/${idAnimal}`);
  }

  public saveRenouvelables(produitRenouvelables: ProduitRenouvelable[]): Observable<ProduitRenouvelable[]> {
    return this.httpApiService.post(`api/back_office/produit/renouvelables`, produitRenouvelables);
  }

  public searchProduits(request: SearchProduitRequest): Observable<Page<Produit>> {
    if (!request) {
      return EMPTY;
    }
    if (request.page === undefined) {
      request.page = 0;
    }
    if (request.offset === undefined) {
      request.offset = 0;
    }
    if (request.size === undefined) {
      request.size = 99999;
    }

    return this.httpApiService.post(`api/back_office/produit/search`, request);
  }

  deleteRenouvelable(id: string) {
    return this.httpApiService.delete(`api/back_office/produit/renouvelable/${id}`);
  }

  findProduitsPrescritsByAnimal(idAnimal: string) {
    return this.httpApiService.get<ProduitPrescrit[]>(`api/back_office/produit/prescrits/animal/${idAnimal}`);
  }
}
