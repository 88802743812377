import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { AnimalDoublon, AnimalWithNbPrestations, UserDoublon, UserWithStats } from '../../../../../models/admin.model';
import { Animal } from '../../../../../models/animal.model';
import { PrestationService } from '@core/services/consultations/prestation.service';
import { User } from '../../../../../models/user.model';
import { UserService } from '@core/services/user/user.service';
import { AlertController } from '@ionic/angular';
import { set } from 'lodash';
import { AdminService } from 'app/modules/admin/services/admin.service';
import { AnimalService } from '../../../../rdv/wizard/services/animal.service';

@Component({
  selector: 'detail-tarif-page',
  templateUrl: 'detail-doublon.page.html',
  styleUrls: ['detail-doublon.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailDoublonPage {
  detailProps!: AnimalDoublon | UserDoublon;
  type!: 'animal' | 'user';
  private hasUpdated?: boolean;

  checkedList: string[] = [];

  constructor(
    private readonly adminService: AdminService,
    private readonly prestationService: PrestationService,
    private readonly userService: UserService,
    private readonly animalService: AnimalService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly alertCtrl: AlertController,
  ) {}

  closeModal(data?: AnimalDoublon | UserDoublon) {
    this.adminService.closeModal(data ? data : this.hasUpdated);
  }

  ionViewWillEnter() {
    if (this.isAnimal(this.detailProps)) {
      this.checkedList = this.detailProps.animalSource.map(a => a.id!);
      this.prestationService.countPrestationsForAnimal(this.detailProps.id!).subscribe(count => {
        (this.detailProps as AnimalDoublon).nbPrestations = count;
        this.changeDetectorRef.markForCheck();
      });
      this.detailProps.animalSource.forEach(animal =>
        this.prestationService.countPrestationsForAnimal(animal.id!).subscribe(count => {
          animal.nbPrestations = count;
          this.changeDetectorRef.markForCheck();
        }),
      );
    } else {
      this.checkedList = this.detailProps.userSource.map(a => a.id);
      this.prestationService.countPrestationsForClient(this.detailProps.id).subscribe(count => {
        (this.detailProps as UserDoublon).nbPrestations = count;
        this.changeDetectorRef.markForCheck();
      });
      this.detailProps.userSource.forEach(user =>
        this.prestationService.countPrestationsForClient(user.id).subscribe(count => {
          user.nbPrestations = count;
          this.changeDetectorRef.markForCheck();
        }),
      );
      this.animalService.countAnimauxForClient(this.detailProps.id).subscribe(count => {
        (this.detailProps as UserDoublon).nbAnimaux = count;
        this.changeDetectorRef.markForCheck();
      });
      this.detailProps.userSource.forEach(user =>
        this.animalService.countAnimauxForClient(user.id).subscribe(count => {
          user.nbAnimaux = count;
          this.changeDetectorRef.markForCheck();
        }),
      );
    }
  }

  setAnimalAsCible(animalSource: Animal, idx: number) {
    const currentCible = { ...(this.detailProps as AnimalWithNbPrestations) };
    const animalSourceCopy = [...(this.detailProps as AnimalDoublon).animalSource];
    this.detailProps = { ...animalSource, animalSource: [] };
    animalSourceCopy.splice(idx, 1);
    animalSourceCopy.push(currentCible);
    this.detailProps.animalSource = animalSourceCopy;
    this.checkedList = [...this.checkedList.filter(id => id !== animalSource.id), currentCible.id!];
    this.changeDetectorRef.markForCheck();
  }

  setUserAsCible(userSource: User, idx: number) {
    const currentCible = { ...(this.detailProps as UserWithStats) };
    const userSourceCopy = [...(this.detailProps as UserDoublon).userSource];
    this.detailProps = { ...userSource, userSource: [] };
    userSourceCopy.splice(idx, 1);
    userSourceCopy.push(currentCible);
    this.detailProps.userSource = userSourceCopy;
    this.checkedList = [...this.checkedList.filter(id => id !== userSource.id), currentCible.id];
    this.changeDetectorRef.markForCheck();
  }

  isAnimal(detailProps: AnimalDoublon | UserDoublon): detailProps is AnimalDoublon {
    return 'animalSource' in detailProps;
  }

  isUser(detailProps: AnimalDoublon | UserDoublon): detailProps is UserDoublon {
    return 'userSource' in detailProps;
  }

  fusionner() {
    if (this.isAnimal(this.detailProps)) {
      const animalDoublon: AnimalDoublon = { ...this.detailProps };
      animalDoublon.animalSource = animalDoublon.animalSource.filter(a => this.checkedList.includes(a.id!));
      this.adminService.mergeAnimaux([animalDoublon]).subscribe(() => this.closeModal(this.detailProps));
    } else {
      const userDoublon: UserDoublon = { ...this.detailProps };
      userDoublon.userSource = userDoublon.userSource.filter(a => this.checkedList.includes(a.id));
      this.adminService.mergeUsers([userDoublon]).subscribe(() => this.closeModal(this.detailProps));
    }
  }

  updateCheckedList($event: any, id: string) {
    if ($event.detail.checked) {
      this.checkedList.push(id);
    } else {
      this.checkedList = this.checkedList.filter(c => c !== id);
    }
    this.changeDetectorRef.markForCheck();
  }

  updateUserField(fieldName: string, value: any) {
    if (['telephone', 'nom', 'prenom', 'mail'].includes(fieldName)) {
      const alert = this.alertCtrl.create({
        message: `Voulez vous écraser le ${fieldName} ou utiliser cette valeur comme ${fieldName}2 ?`,
        buttons: [
          {
            role: 'confirm',
            text: `Ecraser ${fieldName}`,
            handler: () => this.updateUser(fieldName, value),
          },
          {
            role: 'confirm',
            text: `Utiliser comme ${fieldName}2`,
            handler: () => this.updateUser((fieldName + '2') as keyof User, value),
          },
        ],
      });
      alert.then(a => a.present());
    } else {
      const alert = this.alertCtrl.create({
        message: `Voulez-vous vraiment écraser le ${fieldName} ?`,
        buttons: [
          {
            role: 'confirm',
            text: `Oui`,
            handler: () => this.updateUser(fieldName, value),
          },
          {
            role: 'cancel',
            text: `Non`,
            handler: () => {},
          },
        ],
      });
      alert.then(a => a.present());
    }
  }

  private updateUser(fieldName: string, value: any) {
    const userProps = { ...(this.detailProps as User) };
    set(userProps, fieldName, value);
    if (fieldName === 'mail') {
      set(userProps, 'username', value);
    }

    this.userService.updateUserForce(userProps).subscribe(user => {
      (this.detailProps as UserDoublon) = {
        ...user,
        userSource: (this.detailProps as UserDoublon).userSource,
        nbAnimaux: (this.detailProps as UserDoublon).nbAnimaux,
        nbPrestations: (this.detailProps as UserDoublon).nbPrestations,
      };
      this.hasUpdated = true;

      this.changeDetectorRef.markForCheck();
    });
  }

  updateAnimalField(fieldName: string, value: any) {
    const alert = this.alertCtrl.create({
      message: `Voulez-vous vraiment écraser le ${fieldName} ?`,
      buttons: [
        {
          role: 'confirm',
          text: `Oui`,
          handler: () => this.updateAnimal(fieldName, value),
        },
        {
          role: 'cancel',
          text: `Non`,
          handler: () => {},
        },
      ],
    });
    alert.then(a => a.present());
  }

  private updateAnimal(fieldName: string, value: any) {
    const animalProps = { ...(this.detailProps as Animal) };
    set(animalProps, fieldName, value);

    this.animalService.editAnimal(animalProps).subscribe(animal => {
      (this.detailProps as AnimalDoublon) = {
        ...animal,
        animalSource: (this.detailProps as AnimalDoublon).animalSource,
        nbPrestations: (this.detailProps as AnimalDoublon).nbPrestations,
      };
      this.hasUpdated = true;
      this.changeDetectorRef.markForCheck();
    });
  }
}
