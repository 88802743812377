import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { User, UserRole } from '../../../../../../models/user.model';
import { TooltipPosition } from '../../../tooltip/tooltip.model';
import { CompteRendu, FileCR, FileCRTypeEnum } from '../../../../../../models/compte-rendu.model';
import { take } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { PdfViewerComponent } from '../../../pdf-viewer/pdf-viewer.component';
import { AlertController, ModalController } from '@ionic/angular';
import { CompteRenduService } from '../../../../../rdv/wizard/services/compte-rendu.service';
import { StandaloneCrComponent } from '../../standalone-cr.component';

@Component({
  selector: 'app-standalone-cr-view',
  templateUrl: './standalone-cr-view.component.html',
  styleUrls: ['./standalone-cr-view.component.scss'],
})
export class StandaloneCrViewComponent {
  protected readonly UserRole = UserRole;
  protected readonly position = TooltipPosition;
  protected readonly FileCRTypeEnum = FileCRTypeEnum;

  @Input() cr!: CompteRendu;
  @Input() user!: User;
  @Input() lastVeto?: User;
  @Input() showNomAnimal = false;
  @Output() readonly deleted: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly alertController: AlertController,
    private readonly compteRenduService: CompteRenduService,
    private readonly modalCtrl: ModalController,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  downloadFile(file: FileCR) {
    this.compteRenduService.downloadFile(file);
  }

  downloadCr(id: string) {
    this.compteRenduService.findPdfById(id).subscribe(pdf => {
      const blob: Blob = new Blob([pdf], { type: 'application/pdf' });
      this.openModalPdf(blob, 'cr', id);
    });
  }

  protected canModifie(): boolean {
    if (this.user.role === UserRole.ROLE_BACKOFFICE && this.cr.lastModifUser?.role === UserRole.ROLE_VETERINAIRE) {
      return false;
    }

    return (
      this.user.role === UserRole.ROLE_ADMIN ||
      this.cr.auteur?.id === this.user.id ||
      this.cr.lastModifUser?.id === this.user.id ||
      (this.lastVeto?.id === this.user.id && this.cr.auteur?.role === UserRole.ROLE_BACKOFFICE)
    );
  }

  async openModalPdf(blob: Blob, type: string, id: string) {
    const modal = await this.modalCtrl.create({
      component: PdfViewerComponent,
      cssClass: 'pdf-modal-size',
      componentProps: {
        blobProps: blob,
        typeProps: type,
        idProps: id,
      },
    });

    await modal.present();
  }

  async viewFile(file: FileCR) {
    let blobProps: Blob | undefined;
    if (file.mimeType === 'application/pdf' || file.fileName?.endsWith('.pdf')) {
      blobProps = await firstValueFrom(this.compteRenduService.getFileBlob(file.id!)).then(blob => new Blob([blob]));
    } else {
      this.downloadFile(file);

      return;
    }
    const modal = await this.modalCtrl.create({
      component: PdfViewerComponent,
      cssClass: 'pdf-modal-size',
      componentProps: {
        typeProps: 'fichier',
        fileProps: file,
        blobProps,
      },
    });

    await modal.present();
  }

  deleteFile(id: string) {
    const alert = this.alertController.create({
      header: `Voulez-vous vraiment supprimer ce fichier ?`,
      buttons: [
        {
          role: 'cancel',
          text: `Non`,
          handler: () => {},
        },
        {
          role: 'confirm',
          text: `Oui`,
          handler: () =>
            this.compteRenduService
              .deleteFile(id)
              .pipe(take(1))
              .subscribe(() => {
                this.cr.files = this.cr.files!.filter((file: FileCR) => file.id !== id);
                this.changeDetectorRef.markForCheck();
              }),
        },
      ],
    });
    alert.then(a => a.present());
  }

  async edit() {
    const modal = await this.modalCtrl.create({
      component: StandaloneCrComponent,
      backdropDismiss: false,
      cssClass: 'standalone-cr-modal-size',
      componentProps: {
        idAnimal: this.cr.idAnimal,
        nomAnimal: this.cr.nomAnimal,
        cr: this.cr,
        user: this.user,
      },
    });
    modal.onDidDismiss().then(data => {
      if (data.data) {
        this.cr = data.data;
        this.changeDetectorRef.markForCheck();
      }
    });
    await modal.present();
  }

  async delete() {
    const alert = await this.alertController.create({
      header: 'Êtes-vous sûr(e) de vouloir supprimer ce complément ?',
      buttons: [
        {
          role: 'cancel',
          text: `Non`,
          handler: () => {},
        },
        {
          role: 'confirm',
          text: `Oui`,
          handler: () => this.compteRenduService.deleteCr(this.cr.id!).subscribe(() => this.deleted.emit()),
        },
      ],
    });
    await alert.present();
  }
}
