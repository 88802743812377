import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { first, Observable, switchMap } from 'rxjs';

import { selectCredentials } from '@core/store/selector/session.selectors';
import { JwtToken } from 'app/models/jwt-token.model';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('mat-icons') || request.url.includes('/login')) {
      return next.handle(request);
    }

    return this.store.select(selectCredentials).pipe(
      first(),
      switchMap((credentials: JwtToken | undefined) => {
        if (!isEmpty(credentials)) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${credentials.access_token}`,
              Windowname: window.name,
            },
          });
        }

        return next.handle(request);
      }),
    );
  }
}
