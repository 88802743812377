import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const notifAction = createActionGroup({
  source: 'Notifications',
  events: {
    'Notif received': props<{ data: any; notification: any }>(),
    'Update available': emptyProps(),
    'Structure Update available': emptyProps(),
    'Reset Notif State': emptyProps(),
  },
});
