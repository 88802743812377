import { Pipe, PipeTransform } from '@angular/core';
import differenceInYears from 'date-fns/differenceInYears';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

@Pipe({
  name: 'age',
  pure: true,
})
export class AgePipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (value) {
      const today = new Date();
      const month = differenceInCalendarMonths(today, new Date(value)) % 12;
      const year = differenceInYears(today, new Date(value));
      const annee = year > 1 ? 'ans' : 'an';
      const mois = month > 0 ? 'mois' : '';
      const weeks = differenceInCalendarWeeks(today, new Date(value));
      const semaine = weeks > 1 ? 'semaines' : 'semaine';
      const days = differenceInCalendarDays(today, new Date(value));
      const jour = days > 1 ? 'jours' : 'jour';

      if (year === 0 && month > 0) {
        return month.toString() + ' ' + mois;
      }

      if (month === 0 && year > 0) {
        return year.toString() + ' ' + annee;
      }

      if (month === 0 && year == 0) {
        if (weeks > 1) {
          return weeks.toString() + ' ' + semaine;
        }

        return days.toString() + ' ' + jour;
      }

      return year.toString() + ' ' + annee + ' ' + month.toString() + ' ' + mois;
    }

    return '';
  }
}
