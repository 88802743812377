<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar color="blue200">
    <ion-button shape="round" slot="start" fill="clear" (click)="cancel()" color="danger">
      <ion-icon slot="start" name="close-outline"></ion-icon>
      <ion-label>Fermer</ion-label>
    </ion-button>

    <ion-button slot="end" shape="round" [disabled]="form.invalid" (click)="save()" color="done">
      <ion-icon slot="start" name="save-outline"></ion-icon>

      <ion-label>Enregistrer</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-header>
<ion-content color="white">
  <ion-grid>
    <ion-row>
      <ion-col [formGroup]="form" class="formulaire">
        <ion-item lines="none">
          <ion-label>Adresse</ion-label>
          <ion-input formControlName="adresse"></ion-input>
        </ion-item>

        <ion-item lines="none">
          <ion-label>Code postal</ion-label>
          <ion-input placeholder="" formControlName="codePostal" (ionChange)="getCityCode($event)"></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-label>Ville</ion-label>
          <ion-select formControlName="ville" interface="popover">
            <div *ngIf="villes">
              <div *ngFor="let city of villes">
                <ion-select-option [value]="city">{{ city.nom }}</ion-select-option>
              </div>
            </div>
          </ion-select>
        </ion-item>
        <ion-item lines="none">
          <ion-label class="ion-text-wrap">Complément adresse</ion-label>
          <ion-textarea placeholder="" formControlName="detail" [autoGrow]="true" [rows]="1"></ion-textarea>
        </ion-item>
        <ion-item lines="none" *ngIf="displayCodeCentravet">
          <ion-label>Code Centravet</ion-label>
          <ion-input formControlName="codeCentravet" [maxlength]="3"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
