<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar color="blue200">
    <ion-title class="welcome stats ion-text-center">Rappel de vaccin</ion-title>
    <ion-button shape="round" slot="start" fill="clear" (click)="annuler()" color="danger">
      <ion-icon slot="start" name="close-outline"></ion-icon>
      <ion-label>Annuler</ion-label>
    </ion-button>
    <ion-button slot="end" shape="round" (click)="confirm()" [disabled]="!vaccinForm || vaccinForm.invalid" color="done">
      <ion-icon slot="start" name="save-outline"></ion-icon>
      <ion-label>Valider</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-header>
<ng-template #loading>
  <ion-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <div class="ion-text-center">
            <ion-spinner name="crescent"></ion-spinner>
            <ion-text color="medium">
              <p>Chargement des informations</p>
            </ion-text>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-template>
<ion-content class="formulaire" color="blue100" *ngIf="animal; else loading">
  <form *ngIf="vaccinForm" [formGroup]="vaccinForm">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-item lines="none">
            <fieldset>
              <legend><b>Type de vaccin</b></legend>
              <ion-select style="max-width: 100%" placeholder="Choisir un type de vaccin" formControlName="typeVaccin" interface="popover">
                <ion-select-option *ngFor="let typeVaccin of typesVaccin" [value]="typeVaccin"> {{ typeVaccin }} </ion-select-option>
              </ion-select>
            </fieldset>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-radio-group formControlName="delais" (ionChange)="changeDelais()">
        <ion-row>
          <ion-col>
            <ion-item lines="none">
              <ion-label>1 mois</ion-label>
              <ion-radio slot="start" [value]="1"></ion-radio>
            </ion-item>
          </ion-col>
          <ion-col>
            <ion-item lines="none">
              <ion-label>1 an</ion-label>
              <ion-radio slot="start" [value]="12"></ion-radio>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-radio-group>
      <ion-row>
        <ion-col>
          <ion-item lines="none">
            <fieldset>
              <legend><b>Date du rappel</b></legend>
              <ion-input type="date" formControlName="dateRappel"></ion-input>
            </fieldset>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button class="center" (click)="pasDeRappel()">
            <ion-label>Pas de rappel (cas exceptionnel)</ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
