import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Observable } from 'rxjs';
import { Consentement } from '../../../../models/consentement.model';

@Injectable({
  providedIn: 'root',
})
export class ConsentementService {
  constructor(private readonly httpApiService: HttpApiService) {}

  demandeConsentement(consentementRequest: Consentement, idPrestation: string): Observable<Consentement> {
    return this.httpApiService.post<Consentement>(`api/back_office/consentement/${idPrestation}`, consentementRequest);
  }
}
