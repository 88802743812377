import { createActionGroup, props } from '@ngrx/store';
import { Ordonnance } from '../../../../models/ordonnance.model';
import { Devis, EtatDevisEnum, EtatFacturationEnum, Facturation } from '../../../../models/rdv.model';

export const ProduitActions = createActionGroup({
  source: 'Produit API',
  events: {
    saveordonnances: props<{ ordonnances: Ordonnance[]; idPrestation: string }>(),
    saveordonnancessuccess: props<{ ordonnances: Ordonnance[]; idPrestation: string }>(),
    saveordonnance: props<{ ordonnance: Ordonnance; idPrestation: string }>(),
    saveordonnancesuccess: props<{ ordonnance: Ordonnance; idPrestation: string }>(),
    deleteordonnance: props<{ idOrdonnance: string }>(),
    deleteordonnancesuccess: props<{ idOrdonnance: string }>(),
    generatefacturation: props<{ ordonnances: Ordonnance[] }>(),
    generatefacturationsuccess: props<{ facturations: Facturation[] }>(),
    mergefacturations: props<{ existingFacturations: Facturation[]; ordoAsFactu: Facturation[] }>(),
    mergefacturationsuccess: props<{ facturations: Facturation[]; hasModified: boolean }>(),
    deletefacturation: props<{ id: string }>(),
    deletefacturationsuccess: props<{ id: string }>(),
    savefacturation: props<{ facturation: Facturation; idPrestation: string; idxFacturation: number }>(),
    savefacturationsuccess: props<{ facturation: Facturation; idPrestation: string; idxFacturation: number }>(),
    updatefacturationstate: props<{ idPrestation: string; idFacturation: string; etat: EtatFacturationEnum; idxFacturation: number }>(),
    updatefacturationstatesuccess: props<{ idxFacturation: number; facturation: Facturation }>(),
    savefacturations: props<{ facturations: Facturation[]; idPrestation: string }>(),
    savefacturationssuccess: props<{ facturations: Facturation[]; idPrestation: string }>(),
    deletedevis: props<{ id: string }>(),
    deletedevissuccess: props<{ id: string }>(),
    savedevis: props<{ devis: Devis }>(),
    savedevissuccess: props<{ devis: Devis }>(),
    updatedevisstate: props<{ idPrestation: string; idDevis: string; etat: EtatDevisEnum; idxDevis: number }>(),
    updatedevisstatesuccess: props<{ idxDevis: number; devis: Devis }>(),
    savedevislist: props<{ devisList: Devis[] }>(),
    savedevislistsuccess: props<{ devisList: Devis[] }>(),
  },
});
