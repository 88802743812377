import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { selectActiveStructure, selectActiveVeto, selectStructuresList } from '@core/store/selector/admin.selector';
import { Store } from '@ngrx/store';
import { combineLatestWith, distinctUntilChanged, skipWhile, Subject, takeUntil } from 'rxjs';
import { AdminService } from '../../../services/admin.service';
import { Acte, StructureVeterinaire } from 'app/models/rdv.model';
import { User, UserRole } from '../../../../../models/user.model';
import { adminActions } from '@core/store/actions/admin.action';
import { LogService } from '@core/services/log/log.service';
import { Action, Context, TypeLog } from '../../../../../models/log.model';
import { NavController } from '@ionic/angular';
import { selectUser } from '@core/store/selector/session.selectors';
import { Veterinaire } from '../../../../../models/admin.model';

@Component({
  selector: 'tarif-actes-page',
  templateUrl: 'tarif-actes.page.html',
  styleUrls: ['tarif-actes.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TarifActesPage implements OnInit, OnDestroy {
  selectedCategory: { code: string; libelle: string } | null = null;
  selectedStructure?: StructureVeterinaire;
  showActesToutesStructures: boolean = true;
  searchFiler: string = '';
  protected readonly UserRole = UserRole;

  categories: { code: string; libelle: string }[] = [
    { code: 'MEDICAL', libelle: 'Consultation médicale' },
    { code: 'CONSULTATION VACCINALE', libelle: 'Consultation vaccinale' },
    { code: 'CONSULTATION FIN DE VIE', libelle: 'Consultation fin de vie' },
    { code: 'ACTES COMPLEMENTAIRES', libelle: 'Actes complémentaires' },
  ];

  actes: Acte[] = [];
  private readonly destroy$: Subject<void>;
  structures: StructureVeterinaire[] = [];
  user?: User;
  private structuresFull: StructureVeterinaire[] = [];
  veto?: Veterinaire;

  constructor(
    private readonly store: Store,
    private readonly adminService: AdminService,
    private readonly logService: LogService,
    private readonly navCtrl: NavController,
    private readonly cd: ChangeDetectorRef,
  ) {
    this.destroy$ = new Subject<void>();

    this.store
      .select(selectActiveVeto)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(veto => {
        this.veto = veto;
        if (veto && this.structuresFull) {
          this.structures = this.structuresFull.filter(s => s.id === veto.structureVeterinaire.id || s.id === veto.structureVeterinaireAlternative?.id);
        }
      });
    this.store
      .select(selectStructuresList)
      .pipe(
        distinctUntilChanged(),
        combineLatestWith(
          this.store.select(selectUser).pipe(
            skipWhile(user => user === null),
            distinctUntilChanged(),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(structuresAndUser => {
        this.user = structuresAndUser[1];
        if (this.user?.role !== UserRole.ROLE_VETERINAIRE) {
          this.structures = structuresAndUser[0];
        } else {
          this.store.dispatch(adminActions.getSelectedVeto({ idVeto: undefined }));
          this.structuresFull = structuresAndUser[0];
        }
      });
    this.store
      .select(selectActiveStructure)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(structure => {
        this.selectedStructure = structure;
      });
  }

  ngOnInit() {
    console.log('init tarif');

    this.refreshActes();
  }

  private refreshActes() {
    this.adminService.getAllActes().subscribe(actes => {
      this.actes = actes;
      this.cd.markForCheck();
    });
  }

  openDetail(acte: Acte) {
    this.logService.log(Context.ADMIN_TARIF_ACTES, Action.CONSULTATION, TypeLog.INFO, acte);
    this.adminService.openDetailTarif(acte, 'acte', 'view', this.user!).then(data => {
      if (data.data) {
        this.refreshActes();
      }
    });
  }

  openEdit(acte: Acte) {
    this.logService.log(Context.ADMIN_TARIF_ACTES, Action.ASK_MODIFICATION, TypeLog.INFO, acte);
    this.adminService.openDetailTarif(acte, 'acte', 'edit', this.user!).then(data => {
      if (data.data) {
        this.refreshActes();
      }
    });
  }

  backTarif() {
    if (this.user?.role === UserRole.ROLE_VETERINAIRE) {
      this.navCtrl.navigateBack(['tarifs'], { queryParamsHandling: 'merge' });
    } else {
      this.navCtrl.navigateBack(['zone-admin', 'tarifs'], { queryParamsHandling: 'merge' });
    }
  }

  createActe() {
    this.logService.log(Context.ADMIN_TARIF_PRODUITS, Action.ASK_CREATION, TypeLog.INFO, {});
    this.adminService.openDetailTarif({}, 'acte', 'edit', this.user!);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  searchActes(ev: any) {
    this.searchFiler = ev.target!.value;
  }

  getStructureActe(acte: Acte) {
    return acte.idStructure ? this.structures.find(s => s.id === acte.idStructure)?.nomStructure : 'Toutes structures';
  }

  filterValue(libelle: string): boolean {
    if (this.searchFiler === '') {
      return true;
    } else {
      return this.unaccent(libelle.toLowerCase()).includes(this.unaccent(this.searchFiler.toLowerCase()));
    }
  }

  private unaccent(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
