<ion-grid>
  <ion-row>
    <ion-col>
      <div class="ion-text-center poids-courbe">
        <div class="poids-actuel" *ngIf="lastPoids">
          <p>
            Dernier poids : <br />
            {{ lastPoids.datePesee | date: 'mediumDate' }}
          </p>
          {{ lastPoids.poids }}<span>kg</span>
        </div>
        <div [ngClass]="editPoids ? 'ion-text-center poids-btn' : 'ion-text-center poids-btn-readonly'">
          <ion-button id="open-modal" color="blue100" class="secondary-btn" *ngIf="editPoids">
            <ion-label color="blue600" class="mobile">Nv. Poids</ion-label>
            <ion-label color="blue600" class="desktop">Gestion du poids</ion-label>
          </ion-button>
          <ion-button id="seeCourbe" color="blue100" class="secondary-btn" (click)="seeCourbe()">
            <ion-label color="blue600">Courbe</ion-label>
            <ion-icon slot="end" name="analytics-outline" color="blue600"></ion-icon>
          </ion-button>
        </div>
      </div>
      <div echarts [options]="chartOption" [autoResize]="true" [loading]="loadingChart" class="poids-chart" *ngIf="showCourbe"></div>
    </ion-col>

    <ion-modal trigger="open-modal" *ngIf="editPoids">
      <ng-template>
        <ion-header>
          <ion-toolbar color="blue100">
            <ion-title color="blue600">Gestion du poids</ion-title>
            <ion-buttons slot="end">
              <ion-button (click)="cancel()" fill="clear" color="blue600" [strong]="true">Fermer</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
          <ion-grid>
            <div class="edit-poids">
              <ion-text color="grey900">
                <h2>Nouveau poids (kg)</h2>
              </ion-text>
              <form [formGroup]="form" (submit)="addPoids()">
                <ion-grid>
                  <ion-row>
                    <ion-col>
                      <ion-item lines="none">
                        <ion-input type="number" placeholder="" formControlName="poids"></ion-input>
                      </ion-item>
                    </ion-col>
                    <ion-col>
                      <ion-item lines="none">
                        <ion-input type="date" formControlName="dateMesure"></ion-input>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
                <div class="ion-text-center">
                  <ion-button color="blue600" slot="end" type="submit" [disabled]="form.get('poids')?.value === 0 || form.get('poids')?.value === null">
                    <ion-icon name="add-circle-outline" color="white" slot="end"></ion-icon>
                    <ion-label color="white">Ajouter</ion-label>
                  </ion-button>
                </div>
              </form>
            </div>
            <div class="list-poids" *ngIf="listPoids$ | async as listPoids">
              <ion-text>
                <h2>Historique</h2>
              </ion-text>
              <ion-item *ngFor="let poids of listPoids">
                <ion-icon slot="start" name="calendar-outline"></ion-icon>
                <ion-label color="grey900"
                  >{{ poids.datePesee | date: 'mediumDate' }} : <b>{{ poids.poids }}kg</b>
                </ion-label>
                <ion-icon [style.cursor]="'pointer'" slot="end" name="trash-outline" (click)="delete(poids)"></ion-icon>
              </ion-item>
            </div>
          </ion-grid>
        </ion-content>
      </ng-template>
    </ion-modal>
  </ion-row>
</ion-grid>
