import { Action, ActionReducer } from '@ngrx/store';
import { SessionActions } from '@core/store/actions/session.actions';

export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
  return function clearStateFn(state: State | undefined, action: Action) {
    if (action.type === SessionActions.resetState.type) {
      state = undefined; // ==> Emptying state here
    }

    return reducer(state, action);
  };
}
