import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpApiService {
  private readonly apiUrl = environment.API_URL;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly zone: NgZone,
  ) {}

  get<T>(endpoint: string, headers = new HttpHeaders(), params = {}): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrl}/${endpoint}`, { headers, ...params });
  }

  getAsStream<T>(endpoint: string): Observable<T> {
    return new Observable<T>(obs => {
      const source = new EventSource(endpoint);

      source.onmessage = (ev): void => this.zone.run(() => obs.next(JSON.parse(ev.data)));
      source.onerror = (err): void => {
        this.zone.run(() => {
          if ((<EventSource>err.target).readyState != EventSource.CLOSED) {
            source.close();
          }
          obs.complete();
        });
      };
      source.addEventListener('COMPLETE', () => {
        source.close();
        obs.complete();
      });
    });
  }

  post<T>(endpoint: string, body?: unknown, headers = new HttpHeaders()): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrl}/${endpoint}`, body, { headers });
  }

  postWithParams<T>(endpoint: string, body?: unknown, headers = new HttpHeaders(), params = {}): Observable<T> {
    return this.httpClient.post<T>(`${this.apiUrl}/${endpoint}`, body, { headers, ...params });
  }

  delete<T>(endpoint: string, headers = new HttpHeaders(), params = {}): Observable<T> {
    return this.httpClient.delete<T>(`${this.apiUrl}/${endpoint}`, { headers, ...params });
  }
}
