import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Interaction } from '../../../../models/interaction.model';

@Component({
  selector: 'interaction',
  templateUrl: 'interaction.component.html',
  styleUrls: ['interaction.component.scss'],
})
export class InteractionComponent {
  @Input() interactions!: Interaction[];

  constructor(private readonly modalCtrl: ModalController) {}

  cancel() {
    this.modalCtrl.dismiss();
  }
}
