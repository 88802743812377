import { ComponentRef, Directive, ElementRef, EmbeddedViewRef, HostListener, Injector, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { TooltipPosition } from './tooltip.model';
import { Browser } from 'leaflet';
import mobile = Browser.mobile;

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input() tooltip?: string;
  @Input() mobileDisplay = true;
  @Input() position: TooltipPosition = TooltipPosition.DEFAULT;
  private componentRef: ComponentRef<TooltipComponent> | null = null;

  private mobile: boolean;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly injector: Injector,
  ) {
    this.mobile = mobile;
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.componentRef === null && this.tooltip && (!this.mobile || this.mobileDisplay)) {
      const componentViewContainerRef = this.viewContainerRef.createComponent(TooltipComponent, { injector: this.injector });
      this.componentRef = componentViewContainerRef;
      this.viewContainerRef.insert(this.componentRef.hostView);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
      this.setTooltipComponentProperties();
    }
    if (this.componentRef) {
      this.componentRef.instance.visible = true;
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.componentRef) {
      this.componentRef.instance.visible = false;
      this.destroy();
    }
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltip;
      this.componentRef.instance.position = this.position;

      const { left, right, top, bottom } = this.elementRef.nativeElement.getBoundingClientRect();

      switch (this.position) {
        case TooltipPosition.BELOW: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(bottom);
          break;
        }
        case TooltipPosition.ABOVE: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(top);
          break;
        }
        case TooltipPosition.RIGHT: {
          this.componentRef.instance.left = Math.round(right);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        case TooltipPosition.LEFT: {
          this.componentRef.instance.left = Math.round(left);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef !== null) {
      this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.componentRef.hostView));
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
