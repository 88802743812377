import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Devis, EtatDevisEnum } from '../../../../models/rdv.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DevisService {
  constructor(private readonly httpApiService: HttpApiService) {}

  deleteDevis(id: string): Observable<void> {
    return this.httpApiService.delete(`api/back_office/devis/${id}`);
  }

  findByIdClient(idClient: string): Observable<Devis[]> {
    return this.httpApiService.get(`api/back_office/devis/client/${idClient}`);
  }

  saveDevis(devis: Devis): Observable<Devis> {
    return this.httpApiService.post<Devis>(`api/back_office/devis/`, devis);
  }

  send(idDevis: string): Observable<Devis> {
    return this.httpApiService.post<Devis>(`api/back_office/devis/${idDevis}/envoyer`);
  }

  saveDevisList(devisList: Devis[]) {
    return this.httpApiService.post<Devis[]>(`api/back_office/devis/batch`, devisList);
  }

  updateDevisState(idDevis: string, etat: EtatDevisEnum) {
    return this.httpApiService.post<Devis>(`api/back_office/devis/${idDevis}/etat/${etat}`);
  }

  findByIdRdv(idRdv: string) {
    return this.httpApiService.get<Devis[]>(`api/back_office/devis/rdv/${idRdv}`);
  }

  sortDevis(listDevis: Devis[], idRdv?: string, idAnimalPrestation?: string) {
    return listDevis.sort((d1, d2) => {
      let poidsD1 = 0;
      let poidsD2 = 0;
      if (d1.idFacturation) {
        poidsD1 = -999;
      }
      if (d2.idFacturation) {
        poidsD2 = -999;
      }
      if (d1.idAnimal === idAnimalPrestation) {
        poidsD1++;
      }
      if (d2.idAnimal === idAnimalPrestation) {
        poidsD2++;
      }
      if (d1.idRdvDomicile === idRdv) {
        poidsD1++;
      }
      if (d2.idRdvDomicile === idRdv) {
        poidsD2++;
      }
      if (d1.etat === EtatDevisEnum.FINALISE) {
        poidsD1++;
      }
      if (d2.etat === EtatDevisEnum.FINALISE) {
        poidsD2++;
      }
      if (d1.dateDevis! > d2.dateDevis!) {
        poidsD1++;
      } else {
        poidsD2++;
      }

      return poidsD1 > poidsD2 ? -1 : 1;
    });
  }

  envoyerDevisParMail(idDevis: string, email: string) {
    return this.httpApiService.post<Devis>(`api/back_office/devis/${idDevis}/envoyer`, email);
  }

  getDevisPDF(idDevis: string): Observable<Blob> {
    return this.httpApiService.get(`api/back_office/devis/${idDevis}/pdf`, undefined, { responseType: 'arraybuffer' });
  }
}
