import { MetaReducer } from '@ngrx/store';
import * as fromChat from './chat.reducer';
import * as fromSession from './session.reducer';
import * as fromDiscussion from './discussion.reducer';
import * as fromRendezvous from './rdv-domicile.reducer';
import * as fromPrestation from './prestation.reducer';
import * as fromClients from './clients.reducer';
import * as fromAdmin from './admin.reducer';
import { environment } from 'environments/environment';
import { clearStateMetaReducer } from '@core/store/reducers/clear-state.meta.reducer';

export interface AppState {
  readonly session: fromSession.SessionState;
  readonly discussion: fromDiscussion.DiscussionState;
  readonly Chat: fromChat.ChatState;
  readonly rendezvous: fromRendezvous.RdvDomicileState;
  readonly prestation: fromPrestation.PrestationState;
  readonly clients: fromClients.clientsState;
  readonly admin: fromAdmin.AdminState;
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [clearStateMetaReducer] : [clearStateMetaReducer];
