import { Pipe, PipeTransform } from '@angular/core';
import { EtatDevisEnum } from '../../../../models/rdv.model';

@Pipe({
  name: 'etatDevis',
  pure: true,
})
export class EtatDevisPipe implements PipeTransform {
  constructor() {}

  transform(value: EtatDevisEnum | string): string {
    switch (value) {
      case EtatDevisEnum.REFUSE:
        return 'Refusé';
      case EtatDevisEnum.FINALISE:
        return 'Finalisé';
      case EtatDevisEnum.TERMINE:
        return 'Terminé';
      case EtatDevisEnum.FACTURE:
        return 'Facturé';
      case EtatDevisEnum.EN_COURS:
        return 'En cours';
      default:
        return '-';
    }
  }
}
