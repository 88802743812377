import { TooltipPosition } from './tooltip.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tooltip-component',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  tooltip?: string;
  left: number = 0;
  top: number = 0;

  @Input() visible = false;
  position: TooltipPosition = TooltipPosition.DEFAULT;

  constructor() {}

  ngOnInit(): void {
    console.log('on init tooltip');
  }
}
