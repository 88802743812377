import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Facture, Rdv } from '../../../../models/rdv.model';
import { Observable } from 'rxjs';
import { Commune, Veterinaire, VeterinaireStats } from '../../../../models/admin.model';
import { Adresse, User } from '../../../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class VeterinaireService {
  constructor(private readonly httpApiService: HttpApiService) {}

  getVeto(): Observable<Veterinaire[]> {
    return this.httpApiService.get<Veterinaire[]>('api/veterinaire/full');
  }

  getStatsVeto(idVeto: string, from: Date, until: Date): Observable<VeterinaireStats> {
    return this.httpApiService.post<VeterinaireStats>(`api/veterinaire/stats`, { idVeto, from, until });
  }

  getProfilVeto(): Observable<Veterinaire> {
    return this.httpApiService.get<Veterinaire>('api/veterinaire/me');
  }

  updateVeto(veterinaire: Veterinaire): Observable<Veterinaire> {
    return this.httpApiService.post<Veterinaire>('api/veterinaire/update', veterinaire);
  }

  createVeto(veterinaire: Veterinaire): Observable<Veterinaire> {
    return this.httpApiService.post<Veterinaire>('api/veterinaire/', veterinaire);
  }

  getVeterinairesIdsByCodeInsee(codeInsee: string): Observable<string[]> {
    return this.httpApiService.get(`api/veterinaire/code-insee/${codeInsee}`);
  }

  getHistoriqueConsultVeto(idVeto?: string): Observable<Rdv[]> {
    if (idVeto) {
      return this.httpApiService.get<Rdv[]>(`api/veterinaire/${idVeto}/consultations`);
    }

    return this.httpApiService.get<Rdv[]>(`api/veterinaire/consultations`);
  }

  getFacturesVeto(idVeto?: string) {
    if (idVeto) {
      return this.httpApiService.get<Facture[]>(`api/veterinaire/${idVeto}/factures`);
    }

    return this.httpApiService.get<Facture[]>(`api/veterinaire/factures`);
  }

  updatePassword(idUser: string, password: string) {
    return this.httpApiService.post<void>(`api/veterinaire/updatePassword/${idUser}`, password);
  }

  updateMail(idUser: string, mail: string) {
    return this.httpApiService.post<void>(`api/veterinaire/updateMail/${idUser}`, mail);
  }

  updateTelephone(idUser: string, telephone: string) {
    return this.httpApiService.post<void>(`api/veterinaire/updateTelephone/${idUser}`, telephone);
  }

  createVetoUser(user: User) {
    return this.httpApiService.post<User>(`api/veterinaire/createVetoUser`, user);
  }

  changeStatut(id: string, actif: boolean) {
    return this.httpApiService.get(`api/veterinaire/changestatut/${id}/${actif}`);
  }

  getVeterinaire(idVeto?: string) {
    if (idVeto) {
      return this.httpApiService.get<Veterinaire>(`api/veterinaire/${idVeto}/full`);
    } else {
      return this.getProfilVeto();
    }
  }

  getCommunesVeto(idVeto?: string) {
    if (idVeto) {
      return this.httpApiService.get<Commune[]>(`api/veterinaire/${idVeto}/communes`);
    }

    return this.httpApiService.get<Commune[]>(`api/veterinaire/communes`);
  }

  removeCommuneFromVeto(idVeto: string, idCommune: string) {
    return this.httpApiService.post<void>(`api/veterinaire/${idVeto}/deleteCommune/${idCommune}`);
  }

  setCommunes(idVeto: string, replace: boolean, communesCsv: Commune[]) {
    return this.httpApiService.post<void>(`api/veterinaire/${idVeto}/${replace}/setCommunes`, communesCsv);
  }

  addCommune(idVeto: string, commune: Commune) {
    return this.httpApiService.post<void>(`api/veterinaire/${idVeto}/addCommune`, commune);
  }

  exportCommunes(idVeto: string): Observable<Blob> {
    return this.httpApiService.get(`api/veterinaire/${idVeto}/exportCommunes`, undefined, { responseType: 'arraybuffer' });
  }

  searchCommunesNonDesserviesParVeto(idVeto: string, searchTxt: string) {
    return this.httpApiService.get<Commune[]>(`api/veterinaire/${idVeto}/communesNonDesservies/${searchTxt}`);
  }

  updateAdresse(idUser: string, adresse: Adresse) {
    return this.httpApiService.post<void>(`api/veterinaire/updateAdresse/${idUser}`, adresse);
  }

  updateMailWorkplace(idUser: string, mail: string) {
    return this.httpApiService.post<void>(`api/veterinaire/updateMailWorkplace/${idUser}`, mail);
  }

  getVetoWithVacation() {
    return this.httpApiService.get<Veterinaire[]>('api/veterinaire/withVacation');
  }
}
