import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Animal } from '../../../../models/animal.model';

@Injectable({ providedIn: 'root' })
export class BotService {
  constructor(private httpApiService: HttpApiService) {}

  ask(question: string, animal: Animal | null, idStructure?: string) {
    const chatRequest = {
      question: question,
      animal: animal,
      idStructure,
    };

    return this.httpApiService.postWithParams<string>('api/back_office/bot/ask', chatRequest, undefined, { responseType: 'text' });
  }
}
