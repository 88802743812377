import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';

@Pipe({
  name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string | Promise<string> {
    return marked(value);
  }
}
