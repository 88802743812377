<ion-tabs class="home">
  <!-- Tab bar -->
  <ng-container *ngIf="user$ | async as user">
    <ion-tab-bar slot="bottom" id="app-tab-bar" class="switch">
      <img ngSrc="/assets/logo.svg" class="img-desktop" height="49" width="174" />
      <!-- obligé de dupliquer pour l'alignement du texte en desktop !-->
      <ion-tab-button tab="dashboard" class="desktop">
        <ion-label *ngIf="user.role !== UserRole.ROLE_VETERINAIRE">Accueil</ion-label>
        <ion-label *ngIf="user.role === UserRole.ROLE_VETERINAIRE">Ma journée</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="dashboard" class="mobile">
        <ion-icon name="home" size="small" *ngIf="user.role !== UserRole.ROLE_VETERINAIRE"></ion-icon>
        <ion-icon name="today-outline" size="small" *ngIf="user.role === UserRole.ROLE_VETERINAIRE"></ion-icon>
        <ion-label *ngIf="user.role !== UserRole.ROLE_VETERINAIRE">Accueil</ion-label>
        <ion-label *ngIf="user.role === UserRole.ROLE_VETERINAIRE">Ma journée</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="dashboard-veto" class="desktop" *ngIf="user.role === UserRole.ROLE_VETERINAIRE">
        <ion-label>Dashboard</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="dashboard-veto" class="mobile" *ngIf="user.role === UserRole.ROLE_VETERINAIRE">
        <ion-icon src="./assets/user-doctor.svg" size="small"></ion-icon>
        <ion-label>Dashboard</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="planning" class="desktop" *ngIf="user.role === UserRole.ROLE_BACKOFFICE || user.role === UserRole.ROLE_ADMIN">
        <ion-label>Planning</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="planning" class="mobile" *ngIf="user.role === UserRole.ROLE_BACKOFFICE || user.role === UserRole.ROLE_ADMIN">
        <ion-icon name="calendar-outline" size="small"></ion-icon>
        <ion-label>Planning</ion-label>
      </ion-tab-button>
      <!--    <ion-tab-button tab="consultations" class="desktop" disabled="true">
                            <ion-label>Consultations</ion-label>
                          </ion-tab-button>
                          <ion-tab-button tab="consultations" class="mobile" disabled="true">
                            <ion-icon src="/assets/stetho.svg" size="small"></ion-icon>
                            <ion-label>Consultations</ion-label>
                          </ion-tab-button>-->
      <ion-tab-button tab="fiche-client" class="desktop" disabled="false" (click)="clients()">
        <ion-label>Clients</ion-label>
      </ion-tab-button>
      <ion-tab-button tab="fiche-client" class="mobile" disabled="false" (click)="clients()">
        <ion-icon src="/assets/clients.svg" size="small"></ion-icon>
        <ion-label>Clients</ion-label>
      </ion-tab-button>
      <div class="accordion">
        <ion-accordion-group>
          <ion-accordion>
            <ion-item slot="header" id="click-trigger-messagerie">
              <ion-label color="grey600">Messageries</ion-label>
              <ion-badge *ngIf="nbMessagesNonLus > 0">{{ nbMessagesNonLus }}</ion-badge>
            </ion-item>
          </ion-accordion>
        </ion-accordion-group>
        <ion-popover trigger="click-trigger-messagerie" triggerAction="click">
          <ng-template>
            <div class="ion-text-center">
              <ion-button routerLink="/messagerie" (click)="removePopover()" expand="block" fill="clear" color="grey900">
                <ion-label>MIM</ion-label>
                <ion-badge *ngIf="nbMessagesNonLus > 0">{{ nbMessagesNonLus }}</ion-badge>
              </ion-button>
            </div>
            <div class="ion-tex-center">
              <ion-button routerLink="/chat" (click)="removePopover()" expand="block" fill="clear" color="grey900">
                <ion-label>Discussions</ion-label>
              </ion-button>
            </div>
          </ng-template>
        </ion-popover>
      </div>

      <ion-tab-button tab="tarifs" class="desktop" *ngIf="user?.role === UserRole.ROLE_VETERINAIRE">
        <ion-label>Tarifs</ion-label>
      </ion-tab-button>

      <ion-tab-button tab="tarifs" class="mobile" *ngIf="user?.role === UserRole.ROLE_VETERINAIRE">
        <ion-icon name="pricetags-outline" size="small"></ion-icon>
        <ion-label>Tarifs</ion-label>
      </ion-tab-button>
      <ion-tab-button
        tab="zone-admin"
        class="desktop"
        *ngIf="environment.enableAdmin && (user?.role === UserRole.ROLE_ADMIN || user?.role === UserRole.ROLE_BACKOFFICE)"
      >
        <ion-label>Admin</ion-label>
      </ion-tab-button>

      <ion-tab-button
        tab="zone-admin"
        class="mobile"
        *ngIf="environment.enableAdmin && (user?.role === UserRole.ROLE_ADMIN || user?.role === UserRole.ROLE_BACKOFFICE)"
      >
        <ion-icon name="document-lock-outline" size="small"></ion-icon>
        <ion-label>Admin</ion-label>
      </ion-tab-button>
      <ion-tab-button class="mobile" (click)="presentPopover($event)">
        <ion-icon src="/assets/account.svg" size="small"></ion-icon>
        <ion-label>Compte</ion-label>
        <ion-badge *ngIf="nbMessagesNonLus > 0">{{ nbMessagesNonLus }}</ion-badge>
      </ion-tab-button>
      <ion-popover #popover [isOpen]="isOpen" (didDismiss)="isOpen = false" class="mobile">
        <ng-template>
          <div class="ion-tex-center">
            <ion-button routerLink="/messagerie" (click)="removePopover()" expand="block" fill="clear" color="grey900" class="mobile">
              <ion-icon name="mail-outline" slot="start"></ion-icon>
              <ion-label>MIM</ion-label>
              <ion-badge *ngIf="nbMessagesNonLus > 0">{{ nbMessagesNonLus }}</ion-badge>
            </ion-button>
            <ion-button routerLink="/profil" (click)="removePopover()" expand="block" fill="clear" color="grey900">
              <ion-icon name="person-circle-outline" slot="start"></ion-icon>
              <ion-label>Mon profil</ion-label>
            </ion-button>
            <ion-button (click)="logout()" expand="block" fill="clear" color="danger">
              <ion-icon name="log-out-outline" slot="start"></ion-icon>
              <ion-label>Me déconnecter</ion-label>
            </ion-button>
          </div>
        </ng-template>
      </ion-popover>
      <div class="notifications">
        <ion-button slot="icon-only" fill="clear" class="desktop" id="click-notif" *ngIf="notif | async as notif">
          <ion-icon name="notifications-outline" color="grey900"></ion-icon>
          <ion-badge *ngIf="notif.notifReceived">{{ notif.notifications.length }}</ion-badge>
        </ion-button>
        <ion-popover trigger="click-notif" triggerAction="click" (didDismiss)="resetNotif()">
          <ng-template>
            <div class="ion-text-center" *ngIf="notif | async as notifs">
              <ion-item *ngFor="let notif of notifs.notifications" (click)="openUrl(notif)" [button]="notif.data?.url">
                <ion-icon name="information-circle-outline" slot="start" color="blue600"></ion-icon>
                <ion-label class="ion-text-wrap">{{ notif.notification.body }}</ion-label>
              </ion-item>
              <ion-item class="no-item">
                <ion-label class="ion-text-wrap">Aucune notification</ion-label>
              </ion-item>
            </div>
          </ng-template>
        </ion-popover>
      </div>
      <div class="accordion">
        <ion-accordion-group>
          <ion-accordion *ngIf="user$ | async as user">
            <ion-item slot="header" id="click-trigger" [tooltip]="user.role" [position]="position.BELOW" *ngIf="user">
              <ion-label color="grey600">{{ user.nom | titlecase }} {{ user.prenom | titlecase }}</ion-label>
            </ion-item>
          </ion-accordion>
        </ion-accordion-group>
        <ion-popover trigger="click-trigger" triggerAction="click">
          <ng-template>
            <div class="ion-text-center">
              <ion-button routerLink="/profil" (click)="removePopover()" expand="block" fill="clear" color="grey900">
                <ion-icon name="person-circle-outline" slot="start"></ion-icon>
                <ion-label>Mon profil</ion-label>
              </ion-button>
            </div>
            <div class="ion-tex-center">
              <ion-button (click)="logout()" expand="block" fill="clear" color="danger">
                <ion-icon name="log-out-outline" slot="start"></ion-icon>
                <ion-label>Me déconnecter</ion-label>
              </ion-button>
            </div>
          </ng-template>
        </ion-popover>
      </div>
    </ion-tab-bar>
    <app-chatbot class="chatbot"></app-chatbot>
  </ng-container>
</ion-tabs>
