// PRESTATION
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, map, skipWhile, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { CompteRenduService } from '../../../rdv/wizard/services/compte-rendu.service';
import { PrestationAction } from '@core/store/actions/prestation.action';
import { PrestationService } from '@core/services/consultations/prestation.service';
import { Store } from '@ngrx/store';
import { selectActiveRendezvous } from '@core/store/selector/rendezvous.selector';
import { Prestation, RdvDomicile } from '../../../../models/rdv.model';
import { catchError, filter, take } from 'rxjs/operators';
import { FactureService } from '../../../rdv/wizard/services/facture.service';
import { ConsentementService } from '../../../rdv/wizard/services/consentement.service';
import { SavingService } from '@core/services/saving/saving.service';

@Injectable()
export class PrestationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly prestationService: PrestationService,
    private readonly consentementService: ConsentementService,
    private readonly store: Store,
    private readonly compteRenduService: CompteRenduService,
    private readonly factureService: FactureService,
    private readonly savingService: SavingService,
  ) {}

  accessPrestation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrestationAction.accessPrestation),
      switchMap(action =>
        this.store.select(selectActiveRendezvous).pipe(
          skipWhile(rdv => !rdv?.id),
          take(1),
          map((rdv: RdvDomicile | null) => rdv?.prestations),
          map((prestas: Prestation[] | undefined) => prestas?.find(presta => presta.id === action.idPrestation)),
          filter(prestation => !!prestation),
          map(prestation => {
            return PrestationAction.accessPrestationSuccess({ prestation: prestation! });
          }),
        ),
      ),
    );
  });

  resetPrestation$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PrestationAction.resetactiveprestation),
        tap(() => console.log('resetactiveprestation')),
      );
    },
    {
      dispatch: false,
    },
  );

  createPrestation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrestationAction.createprestation),
      switchMap(action => {
        return this.prestationService.createPrestation(action.prestation, action.idRdv).pipe(
          map(createdPrestation => {
            return PrestationAction.saveprestationsuccess({ prestation: createdPrestation });
          }),
        );
      }),
    );
  });
  updatePrestation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrestationAction.updateprestation),
      switchMap(action => {
        return this.prestationService.updatePrestation(action.prestation).pipe(
          map(updatedPrestation => {
            return PrestationAction.saveprestationsuccess({ prestation: updatedPrestation });
          }),
        );
      }),
    );
  });
  savePrestationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrestationAction.saveprestationsuccess),
      map(action => PrestationAction.accessPrestation({ idPrestation: action.prestation.id! })),
      tap(() => {
        console.log('Edit Prestation Success');
      }),
    );
  });

  // CR
  createCR$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrestationAction.createcr),
      tap(() => this.savingService.increment()),
      switchMap(action =>
        this.compteRenduService.saveCompteRendu(action.idPrestation, action.compteRendu, action.finalize).pipe(
          map(cr => PrestationAction.createcrsuccess({ idPrestation: action.idPrestation, compteRendu: cr })),
          catchError(() => {
            this.savingService.decrement();

            return EMPTY;
          }),
        ),
      ),
    );
  });
  createCRSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PrestationAction.createcrsuccess),
        tap(() => {
          console.log('create cr success');
          this.savingService.decrement();
        }),
      );
    },
    {
      dispatch: false,
    },
  );
  // Facture
  markfacturepayee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrestationAction.markfacturepayee),
      switchMap(action =>
        this.factureService
          .markFacturePayee(action.id)
          .pipe(map(() => PrestationAction.markfacturepayeesuccess({ id: action.id, idPrestation: action.idPrestation }))),
      ),
    );
  });
  markfacturepayeesuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PrestationAction.markfacturepayeesuccess),
        tap(() => {
          console.log('mark facture payee success');
        }),
      );
    },
    { dispatch: false },
  );
  // com
  updatecommentaireinterne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrestationAction.updatecommentaireinterne),
      tap(() => this.savingService.increment()),
      switchMap(action =>
        this.prestationService.updateCommentaireInterne(action.commentaireInterne, action.idPrestation).pipe(
          map(() => PrestationAction.updatecommentaireinternesuccess({ commentaireInterne: action.commentaireInterne, idPrestation: action.idPrestation })),
          catchError(() => {
            this.savingService.decrement();

            return EMPTY;
          }),
        ),
      ),
    );
  });
  updatecommentaireinternesuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PrestationAction.updatecommentaireinternesuccess),
        tap(() => {
          console.log('updatecommentaireinternesuccess');
          this.savingService.decrement();
        }),
      );
    },
    {
      dispatch: false,
    },
  );
  // consentement

  demandeconsentement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PrestationAction.demandeconsentement),
      switchMap(action =>
        this.consentementService
          .demandeConsentement(action.consentement, action.idPrestation)
          .pipe(map(consentement => PrestationAction.demandeconsentementsuccess({ consentement, idPrestation: action.idPrestation }))),
      ),
    );
  });
  demandeconsentementsuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PrestationAction.demandeconsentementsuccess),
        tap(() => {
          console.log('demandeconsentementsucess');
        }),
      );
    },
    {
      dispatch: false,
    },
  );
}
