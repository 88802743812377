<ng-container *ngIf="horsRdv.prestations?.length; else noPresta">
  <ng-container *ngFor="let prestation of horsRdv.prestations">
    <ng-container *ngIf="idAnimalFilter ? prestation.animal?.id === idAnimalFilter : true">
      <ion-accordion class="hors-rdv">
        <ion-item
          slot="header"
          color="blue200"
          lines="none"
          class="consultation-header"
          [ngClass]="horsRdv.step"
          [tooltip]="horsRdv.step | horsRdvStep"
          [position]="position.LEFT"
        >
          <ion-label color="blue600" class="ion-text-wrap" (click)="doNothing()"
            ><h2>
              {{ horsRdv.createDateTime | date: 'mediumDate' }}
              {{ horsRdv.type === TypeHorsRdv.ENVOI_MEDICAMENTS ? 'Envoi de médicaments' : 'Autre' }}
            </h2>
            <div *ngIf="displayClient || !idAnimalFilter" class="animal-client">
              <p *ngIf="!idAnimalFilter && prestation.animal?.nom" class="animal"><b>Animal :&nbsp;</b>{{ prestation.animal?.nom | titlecase }}</p>
              <p *ngIf="displayClient" class="client">
                <b>Client :&nbsp;</b>{{ (horsRdv.client.prenom ? horsRdv.client.prenom + ' ' : '') + horsRdv.client.nom | titlecase }}
                <ion-icon name="open-outline" (click)="$event.stopPropagation(); showClient(horsRdv.client.id)" [style.cursor]="'pointer'"></ion-icon>
              </p>
            </div>
          </ion-label>
          <ion-select
            slot="end"
            interface="popover"
            placeholder="{{ horsRdv.step | horsRdvStep }}"
            [value]="horsRdv.step"
            class="ion-text-wrap"
            (click)="$event.stopPropagation()"
            *ngIf="!mobile && rdvStepSelectionnables.length > 1"
            (ionChange)="updateRdvState($event)"
          >
            <ion-select-option *ngFor="let step of rdvStepSelectionnables" [value]="step">{{ step | horsRdvStep }} </ion-select-option>
          </ion-select>
        </ion-item>
        <div slot="content">
          <div class="factu-paiements">
            <app-prestation-content *ngIf="user" [user]="user" [presta]="prestation" [mail]="horsRdv.client.mail"></app-prestation-content>
            <div class="paiements">
              <ion-item lines="none" *ngFor="let paiement of horsRdv.paiements | orderBy: 'updatedAt' : 'desc'">
                <ion-icon *ngIf="paiement.status === PaiementStatus.OK" name="checkmark-circle-outline" slot="start" color="success"></ion-icon>
                <ion-icon *ngIf="paiement.status === PaiementStatus.EN_COURS" name="time-outline" slot="start" color="warning"></ion-icon>
                <ion-label>
                  <h3>
                    <b>{{ paiement.montant | number: '1.0-2' }}€ </b>par
                    {{
                      paiement.type === ModePaiementEnum.CARD
                        ? 'carte bancaire'
                        : paiement.type === ModePaiementEnum.DR_MILOU
                        ? 'paiement en ligne'
                        : paiement.type === ModePaiementEnum.CHEQUE
                        ? 'chèque'
                        : paiement.type === ModePaiementEnum.VIREMENT
                        ? 'virement'
                        : paiement.type === 'ESPECE'
                        ? 'espèce'
                        : 'paiement en ligne'
                    }}
                  </h3>
                  <p>{{ (paiement.updatedAt | date) || 'Erreur de date' }}</p>
                </ion-label>
              </ion-item>
              <ion-item *ngIf="!horsRdv.paiements?.length" class="ion-text-center" lines="none">
                <ion-label color="grey900">Aucun paiement effectué.</ion-label>
              </ion-item>
            </div>
          </div>
          <ion-item button detail lines="none" (click)="openPreview()">
            <ion-label class="ion-text-wrap">
              <ion-text color="grey900">
                <p *ngIf="horsRdv.description"><b>Description :</b> {{ horsRdv.description }}</p>
                <p>
                  <b>Adresse :</b> {{ horsRdv.adresse?.adresse + ' ' + horsRdv.adresse?.codePostal + ' ' + horsRdv.adresse?.ville }}
                  <b *ngIf="horsRdv.adresse?.detail">Détail adresse :</b> {{ horsRdv.adresse?.detail }}
                </p>
                <p>
                  <b>Responsable :</b> {{ horsRdv.responsable ? horsRdv.responsable.prenom + ' ' + horsRdv.responsable.nom : 'Aucun responsable enregistré' }}
                </p>
                <ng-container *ngIf="prestation.facturations && prestation.facturations.length > 0">
                  <p><b>Produits :</b></p>
                  <ul>
                    <ng-container *ngFor="let factu of prestation.facturations">
                      <li *ngFor="let pd of factu.produitsDelivres">
                        <p>{{ pd.nom + ' : ' + pd.quantiteUvc + (pd.produit?.unite ? ' ' + pd.produit?.unite + '(s)' : '') }}</p>
                      </li>
                    </ng-container>
                  </ul>
                </ng-container>
              </ion-text>
            </ion-label>
          </ion-item>
          <div class="step-select" *ngIf="mobile && rdvStepSelectionnables.length > 1">
            <ion-select
              slot="end"
              interface="popover"
              placeholder="{{ horsRdv.step | horsRdvStep }}"
              [value]="horsRdv.step"
              class="ion-text-wrap"
              (click)="$event.stopPropagation()"
              (ionChange)="updateRdvState($event)"
            >
              <ion-select-option *ngFor="let step of rdvStepSelectionnables" [value]="step">{{ step | horsRdvStep }} </ion-select-option>
            </ion-select>
          </div>
        </div>
      </ion-accordion>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #noPresta>
  <ion-accordion class="hors-rdv">
    <ion-item
      slot="header"
      color="blue200"
      lines="none"
      class="consultation-header"
      [ngClass]="horsRdv.step"
      [tooltip]="horsRdv.step | horsRdvStep"
      [position]="position.LEFT"
    >
      <ion-label color="blue600" class="ion-text-wrap"
        ><h2>
          {{ horsRdv.createDateTime | date: 'mediumDate' }}
          {{ horsRdv.type === TypeHorsRdv.ENVOI_MEDICAMENTS ? 'Envoi de médicaments' : 'Autre' }}
        </h2>
        <p *ngFor="let animal of horsRdv.animaux">{{ animal?.nom | titlecase }}</p>
        <p *ngIf="horsRdv.responsable"><b>Avec :</b> {{ horsRdv.responsable.prenom + ' ' + horsRdv.responsable.nom }}</p>
      </ion-label>
    </ion-item>
  </ion-accordion>
</ng-template>
