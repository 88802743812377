import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogService } from '@core/services/log/log.service';
import { adminActions } from '@core/store/actions/admin.action';
import { selectActiveStructure, selectStructuresList } from '@core/store/selector/admin.selector';
import { selectUser } from '@core/store/selector/session.selectors';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Veterinaire } from 'app/models/admin.model';
import { Action, Context, TypeLog } from 'app/models/log.model';
import { FindRdvRequest, RdvState, StructureVeterinaire } from 'app/models/rdv.model';
import { User, UserRole } from 'app/models/user.model';
import { StructureService } from 'app/modules/rdv/wizard/services/structure.service';
import { VeterinaireService } from 'app/modules/rdv/wizard/services/veterinaire.service';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'export-rdv',
  templateUrl: 'export-rdv.component.html',
  styleUrls: ['export-rdv.component.scss'],
})
export class ExportRdvComponent implements OnInit, OnDestroy {
  isAdmin!: boolean;
  structures$: Observable<StructureVeterinaire[]>;
  activeStructure$: Observable<StructureVeterinaire | undefined>;
  user$: Observable<User | undefined>;
  user?: User;
  destroy$: Subject<void>;
  selectedStructures?: StructureVeterinaire[];
  veto!: Veterinaire | undefined;
  veterinaireList!: Veterinaire[];
  dateInterval: FormGroup;
  protected UserRole = UserRole;
  filteredVeto: Veterinaire[] = [];

  constructor(
    private readonly modalController: ModalController,
    private readonly store: Store,
    private readonly logService: LogService,
    private readonly structureService: StructureService,
    private readonly vetoService: VeterinaireService,
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
  ) {
    this.destroy$ = new Subject<void>();
    this.user$ = this.store.select(selectUser).pipe(distinctUntilChanged(), takeUntil(this.destroy$));
    this.structures$ = this.store.select(selectStructuresList).pipe(distinctUntilChanged(), takeUntil(this.destroy$));
    this.activeStructure$ = this.store.select(selectActiveStructure).pipe(distinctUntilChanged(), takeUntil(this.destroy$));
    this.dateInterval = this.fb.group({
      dateDebut: [null, [Validators.required]],
      dateFin: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.user$.subscribe(user => {
      this.user = user;
    });

    this.logService.log(Context.EXPORT_RDV, Action.CONSULTATION, TypeLog.INFO, {});
    if (this.isAdmin) {
      this.vetoService.getVeto().subscribe(vetos => (this.veterinaireList = vetos));
      this.resetFilter();
    } else {
      this.resetFilterVeto();
    }
  }

  close() {
    this.modalController.dismiss();
  }

  changeStructure(event: any) {
    this.selectedStructures = event.detail.value;
    this.filteredVeto = this.filterVetoList(this.veterinaireList);
    this.veto = undefined;
  }

  resetFilter() {
    this.store.dispatch(adminActions.resetActiveStructure());
    this.selectedStructures = undefined;
    this.veto = undefined;
    this.dateInterval.reset();
  }

  resetFilterVeto() {
    this.dateInterval.reset();
  }

  filterVetoList(veterinaires: Veterinaire[]) {
    if (this.selectedStructures?.length) {
      let ids = this.selectedStructures!.map(s => s.id);

      return veterinaires.filter(
        veto => ids.includes(veto.structureVeterinaire.id) || (veto.structureVeterinaireAlternative && ids.includes(veto.structureVeterinaireAlternative.id)),
      );
    }

    return veterinaires;
  }

  changeVeto(veto: any) {
    this.veto = veto;
    this.store.dispatch(adminActions.setActiveStructure({ activeStructure: this.veto?.structureVeterinaire! }));
    if (this.veto) {
      this.selectedStructures = this.veto?.structureVeterinaireAlternative
        ? [this.veto.structureVeterinaire, this.veto.structureVeterinaireAlternative]
        : [this.veto.structureVeterinaire];
    } else {
      this.selectedStructures = undefined;
    }
  }

  downloadRdvCsv() {
    this.structureService.exportStats(this.buildRdvRequest()).subscribe(csv => {
      const fileURL = window.URL.createObjectURL(new Blob([csv], { type: 'application/csv' }));
      const link: HTMLAnchorElement = document.createElement('a');
      link.download = 'rdv-du-' + this.dateInterval.value.dateDebut + '-au-' + this.dateInterval.value.dateFin + '.csv';
      link.href = fileURL;
      link.click();
    });
  }

  private buildRdvRequest(): FindRdvRequest {
    const request: FindRdvRequest = {
      withPrestations: true,
      notRdvStep: [RdvState.INIT, RdvState.CONFIRM],
      minDate: this.datePipe.transform(this.dateInterval.value.dateDebut, 'yyyy-MM-ddT00:00:00Z')!,
      maxDate: this.datePipe.transform(this.dateInterval.value.dateFin, 'yyyy-MM-ddT23:59:59Z')!,
      structureIds: this.selectedStructures ? this.selectedStructures.map(s => s.id!) : undefined,
      consultantId: this.veto ? this.veto?.utilisateur?.id : undefined,
    };

    return request;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
