<div class="reglement-wrapper">
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="6" class="rg-left">
        <div class="reglement" *ngIf="isEditable">
          <ion-text color="grey900" class="ion-text-center">
            <h4>Règlement</h4>
          </ion-text>
          <ion-row>
            <ion-col size="12" class="reglement-inputs">
              <ion-item class="montant" lines="none">
                <fieldset>
                  <legend>Montant</legend>
                  <ion-input [(ngModel)]="montant" (ionChange)="enablePaiement()" type="number" placeholder="Entrez le montant payé"></ion-input>
                </fieldset>
              </ion-item>

              <ion-item lines="none">
                <fieldset>
                  <legend>Mode</legend>

                  <ion-select
                    [(ngModel)]="modePaiement"
                    (ionChange)="enablePaiement()"
                    placeholder="Choisir un mode de paiement"
                    interface="popover"
                    class="mode-paiement-select"
                  >
                    <ion-select-option [value]="'CARD'">Sum up</ion-select-option>
                    <ion-select-option [value]="'ESPECE'">Espèce</ion-select-option>
                    <ion-select-option [value]="'CHEQUE'">Chèque</ion-select-option>
                    <ion-select-option [value]="'DR_MILOU'">Paiement en ligne</ion-select-option>
                    <ion-select-option [value]="'VIREMENT'">Virement</ion-select-option>
                    <ion-select-option [value]="'ALMA'">Alma</ion-select-option>
                  </ion-select>
                </fieldset>
              </ion-item>
            </ion-col>
          </ion-row>
          <div class="ion-text-center btn-div">
            <ion-button
              type="submit"
              color="primary"
              [disabled]="!montant || isPaying || !modePaiement || (modePaiement === ModePaiementEnum.DR_MILOU && montant <= 0)"
              (click)="validerPaiement()"
            >
              <ion-label>Valider le paiement</ion-label>
              <ion-icon slot="end" name="card-outline"></ion-icon>
            </ion-button>
          </div>
        </div>
      </ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="6">
        <div class="reglement-done" *ngIf="isEditable">
          <div class="reglement-done-btn">
            <ion-text color="grey900" class="ion-text-center">
              <h4>Règlements effectués</h4>
            </ion-text>

            <ion-button fill="clear" slot="end" color="blue200" size="small" (click)="refreshPayments()" *ngIf="displayRefreshBtn">
              <ion-icon name="sync-outline" color="blue600" slot="icon-only"></ion-icon>
            </ion-button>
          </div>
          <ion-item lines="none" *ngFor="let paiement of paiements | orderBy: 'updatedAt' : 'desc'; let idx = index">
            <ion-icon *ngIf="paiement.status === PaiementStatus.OK" name="checkmark-circle-outline" slot="start" color="success"></ion-icon>
            <ion-icon *ngIf="paiement.status === PaiementStatus.EN_COURS" name="time-outline" slot="start" color="warning"></ion-icon>
            <ion-label>
              <h3>
                <b>{{ paiement.montant | number: '1.0-2' }}€ </b>par
                {{
                  paiement.type === ModePaiementEnum.CARD
                    ? 'carte bancaire'
                    : paiement.type === ModePaiementEnum.DR_MILOU
                    ? 'paiement en ligne'
                    : paiement.type === ModePaiementEnum.CHEQUE
                    ? 'chèque'
                    : paiement.type === 'ESPECE'
                    ? 'espèce'
                    : paiement.type === 'VIREMENT'
                    ? 'virement'
                    : 'paiement en ligne'
                }}
              </h3>
              <p>{{ (paiement.updatedAt | date) || 'Date inconnue' }}</p>
            </ion-label>
            <ion-button
              color="blue200"
              class="reglement-btn"
              size="small"
              fill="clear"
              *ngIf="paiement.type === ModePaiementEnum.DR_MILOU"
              [disabled]="(paiement.updatedAt | dateCompare: MIN_WAIT_TIME_RELANCE_PAIEMENT_SECONDES | async) === false"
              (click)="relancePaiement(paiement.id)"
              slot="end"
            >
              <ion-icon name="arrow-redo-outline" slot="icon-only" color="blue600" size="small"></ion-icon>
            </ion-button>
            <ion-button
              color="blue200"
              class="reglement-btn"
              size="small"
              fill="clear"
              [disabled]="paiement.type === ModePaiementEnum.DR_MILOU || !!paiement.idFacture"
              (click)="deletePaiement(idx)"
              slot="end"
            >
              <ion-icon name="close-outline" slot="icon-only" color="blue600" size="small"></ion-icon>
            </ion-button>
          </ion-item>
          <ion-item *ngIf="!paiements?.length" class="ion-text-center" lines="none">
            <ion-label color="grey900">Aucun paiement effectué.</ion-label>
          </ion-item>
        </div>
      </ion-col>
      <ion-col *ngIf="!isEditable">
        <div class="reglement-done">
          <div class="reglement-done-btn">
            <ion-text color="grey900" class="ion-text-center">
              <h4>Règlements effectués</h4>
            </ion-text>
          </div>
          <ion-item lines="none" *ngFor="let paiement of paiements | orderBy: 'updatedAt' : 'desc'">
            <ion-icon *ngIf="paiement.status === PaiementStatus.OK" name="checkmark-circle-outline" slot="start" color="success"></ion-icon>
            <ion-icon *ngIf="paiement.status === PaiementStatus.EN_COURS" name="time-outline" slot="start" color="warning"></ion-icon>
            <ion-label>
              <h3>
                <b>{{ paiement.montant | number: '1.0-2' }}€ </b>par
                {{
                  paiement.type === ModePaiementEnum.CARD
                    ? 'carte bancaire'
                    : paiement.type === ModePaiementEnum.DR_MILOU
                    ? 'paiement en ligne'
                    : paiement.type === ModePaiementEnum.CHEQUE
                    ? 'chèque'
                    : paiement.type === ModePaiementEnum.VIREMENT
                    ? 'virement'
                    : paiement.type === 'ESPECE'
                    ? 'espèce'
                    : 'paiement en ligne'
                }}
              </h3>
              <p>{{ (paiement.updatedAt | date) || 'Erreur de date' }}</p>
            </ion-label>
          </ion-item>
          <ion-item *ngIf="!paiements?.length" class="ion-text-center" lines="none">
            <ion-label color="grey900">Aucun paiement effectué.</ion-label>
          </ion-item>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
