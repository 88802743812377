import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Observable } from 'rxjs';
import { Ordonnance } from '../../../../models/ordonnance.model';

@Injectable({
  providedIn: 'root',
})
export class OrdonnanceService {
  constructor(private readonly httpApiService: HttpApiService) {}

  public saveOrdonnances(ordonnances: Ordonnance[], idPrestation: string): Observable<Ordonnance[]> {
    return this.httpApiService.post<Ordonnance[]>(`api/back_office/ordonnance/${idPrestation}/batch`, ordonnances);
  }

  saveOrdonnance(ordonnance: Ordonnance, idPrestation: string) {
    return this.httpApiService.post<Ordonnance>(`api/back_office/ordonnance/${idPrestation}`, ordonnance);
  }

  deleteOrdonnance(idOrdonnance: string) {
    return this.httpApiService.delete(`api/back_office/ordonnance/${idOrdonnance}`);
  }

  sendOrdonnance(idOrdonnance: string, email: string): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/ordonnance/${idOrdonnance}/send/${email}`);
  }

  getOrdonnancePdf(idOrdonnance: string): Observable<Blob> {
    return this.httpApiService.get(`api/back_office/ordonnance/${idOrdonnance}/pdf`, undefined, { responseType: 'arraybuffer' });
  }
}
