import { Pipe, PipeTransform } from '@angular/core';
import { TypeHorsRdv } from '../../../../models/interaction.model';

@Pipe({
  name: 'typeHorsRdvStr',
  pure: true,
})
export class TypeHorsRdvStrPipe implements PipeTransform {
  constructor() {}

  transform(value: TypeHorsRdv | string): string {
    switch (value) {
      case TypeHorsRdv.AUTRE:
        return 'Autre';
      case TypeHorsRdv.ENVOI_MEDICAMENTS:
        return 'Envoi de médicaments';
      default:
        return '-';
    }
  }
}
