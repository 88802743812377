import { Injectable } from '@angular/core';
import { CreationRdvInternePayloadType, FindRdvRequest, RdvInterne, RdvState } from 'app/models/rdv.model';
import { map, Observable } from 'rxjs';
import { HttpApiService } from '../http-api/http-api.service';
import { Page } from 'app/models/page.model';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class RdvInterneService {
  constructor(private readonly httpApiService: HttpApiService) {}

  findAllRdv(findRdvRequest: FindRdvRequest): Observable<Page<RdvInterne>> {
    if (isEmpty(findRdvRequest)) {
      throw new Error('données vides');
    }

    return this.httpApiService.post<Page<RdvInterne>>('api/back_office/rdvInterne/find', findRdvRequest).pipe(map(data => data));
  }

  findGroupedRdv(findRdvRequest: FindRdvRequest): Observable<{ [key: string]: RdvInterne[] }> {
    if (isEmpty(findRdvRequest)) {
      throw new Error('données vides');
    }

    return this.httpApiService.post<{ [key: string]: RdvInterne[] }>('api/back_office/rdvInterne/find-grouped', findRdvRequest).pipe(map(data => data));
  }

  accessRdv(id: string): Observable<RdvInterne | null> {
    return this.httpApiService.get<RdvInterne | null>(`api/back_office/rdvInterne/${id}`).pipe(map(data => data));
  }

  creationRdv(rdvPayload: CreationRdvInternePayloadType): Observable<RdvInterne> {
    return this.httpApiService.post<RdvInterne>(`api/back_office/rdvInterne/`, rdvPayload);
  }

  updateRdv(rdv: RdvInterne): Observable<RdvInterne> {
    return this.httpApiService.post<RdvInterne>(`api/back_office/rdvInterne/`, rdv);
  }

  moveRdvInterne(idRdv: string, idConsultant: string | null, dateConsultation: Date) {
    return this.httpApiService.post(`api/back_office/rdvInterne/${idRdv}/moveRdv${idConsultant ? '/' + idConsultant : ''}`, dateConsultation);
  }

  updateDateRdv(idRdv: string, date: Date | null): Observable<void> {
    return this.httpApiService.post(`api/back_office/rdvInterne/${idRdv}/updateDateRdv`, date);
  }

  updateConsultant(idRdv: string, idConsultant: string): Observable<void> {
    return this.httpApiService.post(`api/back_office/rdvInterne/${idRdv}/updateConsultant/${idConsultant}`);
  }

  deleteRdv(id: string) {
    return this.httpApiService.delete(`api/back_office/rdvInterne/${id}`);
  }

  updateRdvStep(idRdv: string, rdvStep: RdvState): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/rdvInterne/${idRdv}/updateRdvStep/${rdvStep}`);
  }
}
