import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stockSource',
})
export class StockSourcePipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    switch (value) {
      case 'VETERINAIRE':
        return 'ma voiture';
      case 'STRUCTURE':
        return 'mon local';
      case 'CENTRALE':
        return 'la centrale';
    }

    return '';
  }
}
