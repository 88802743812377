<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar color="blue100">
    <ion-title>Exporter les rendez-vous</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()" color="blue600"> Fermer</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="blue100">
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <ion-text color="blue600" class="ion-text-center">
          <h1>Choisir les paramètres de l'export</h1>
        </ion-text>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="isAdmin">
      <ion-col>
        <ng-container *ngIf="structures$ | async as structures">
          <div class="popover-wrapper">
            <div class="popover-button" (click)="select.open()">
              <ng-container *ngIf="selectedStructures?.length; else NoStructure">
                <h4 [style.cursor]="'pointer'">
                  <b>Structure(s) :</b>
                  <ng-container *ngFor="let structure of selectedStructures; let last = last"
                    >{{ structure.nomStructure.slice(8) + (!last ? ', ' : '') }}
                  </ng-container>
                </h4>
              </ng-container>
              <ng-template #NoStructure>
                <h4 [style.cursor]="'pointer'"><b>Structures :</b> Toutes</h4>
              </ng-template>
            </div>

            <div class="no-display">
              <ion-select (ionChange)="changeStructure($event)" [multiple]="true" #select placeholder="Choisir une/des structure(s)">
                <ng-container *ngFor="let structure of structures">
                  <ion-select-option *ngIf="structure.typeStructure === 'CONSULTATION'" [value]="structure"
                    >{{ structure.nomStructure.slice(8) }}
                  </ion-select-option>
                </ng-container>
              </ion-select>
            </div>
          </div>
        </ng-container>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="isAdmin">
      <ion-col>
        <ng-container *ngIf="veterinaireList as veterinaires">
          <div class="popover-wrapper">
            <div class="popover-button" (click)="select.open()">
              <ng-container *ngIf="veto; else NoVeto">
                <h4 [style.cursor]="'pointer'"><b>Vétérinaire :</b> {{ veto.prenom + ' ' + veto.nom }}</h4>
              </ng-container>
              <ng-template #NoVeto>
                <h4 [style.cursor]="'pointer'"><b>Vétérinaires :</b> Tous</h4>
              </ng-template>
            </div>

            <div class="no-display">
              <app-searchable-select
                [data]="filteredVeto"
                title="Choisir un vétérinaire"
                itemTextField="nom"
                (selectedChanged)="changeVeto($event)"
                #select
              ></app-searchable-select>
            </div>
          </div>
        </ng-container>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <form [formGroup]="dateInterval">
          <div class="date-interval">
            <ion-item class="ion-text-center" lines="none">
              <fieldset>
                <legend><b>Date de début</b></legend>
                <ion-input
                  formControlName="dateDebut"
                  type="date"
                  placeholder="Définir"
                  [ngClass]="!dateInterval.value.dateDebut ? 'custom-invalid' : ''"
                ></ion-input>
              </fieldset>
            </ion-item>
            <ion-item class="ion-text-center" lines="none">
              <fieldset>
                <legend><b>Date de fin</b></legend>
                <ion-input
                  formControlName="dateFin"
                  type="date"
                  placeholder="Définir"
                  [ngClass]="!dateInterval.value.dateFin ? 'custom-invalid' : ''"
                ></ion-input>
              </fieldset>
            </ion-item>
          </div>
        </form>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="ion-text-center" *ngIf="isAdmin">
          <ion-button (click)="resetFilter()" fill="clear" shape="round" color="danger"> Réinitialiser les paramètres </ion-button>
        </div>
        <div class="ion-text-center" *ngIf="!isAdmin">
          <ion-button (click)="resetFilterVeto()" fill="clear" shape="round" color="danger"> Réinitialiser les paramètres </ion-button>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="ion-text-center">
          <ion-button (click)="downloadRdvCsv()" shape="round" color="grey900" [disabled]="!dateInterval.value.dateFin || !dateInterval.value.dateDebut">
            Télécharger
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
