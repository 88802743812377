import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  setToStorage(name: string, value: string) {
    localStorage.setItem(name, value);
  }

  getFromStorage(name: string): string | null {
    return localStorage.getItem(name);
  }

  removeFromStorage(name: string) {
    localStorage.removeItem(name);
  }
}
