import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromDiscussion from '../reducers/discussion.reducer';

export const selectDiscussionState = createFeatureSelector<fromDiscussion.DiscussionState>('discussion');

export const selectDiscussions = createSelector(selectDiscussionState, (state: fromDiscussion.DiscussionState) => state.discussions);
export const selectWaitingDiscussions = createSelector(selectDiscussionState, (state: fromDiscussion.DiscussionState) => state.waitingDiscussions);
export const selectActiveDiscussion = createSelector(selectDiscussionState, (state: fromDiscussion.DiscussionState) => state.activeDiscussion);
export const selectAllActiveDiscussions = createSelector(selectDiscussionState, (state: fromDiscussion.DiscussionState) => state.allActiveDiscussions);
export const selectMessagesNonLus = createSelector(selectDiscussionState, (state: fromDiscussion.DiscussionState) => state.nbUnreadMessagerie);
