import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Pipe({
  name: 'dateRdv',
  pure: true,
})
export class DateRdvPipe implements PipeTransform {
  constructor(private readonly sharedService: SharedService) {}

  transform(value: string | Date): string {
    return this.sharedService.formatDateRdv(value);
  }
}
