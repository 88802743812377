import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Adresse, VilleForm } from '../../../../models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RdvDomicileService } from '@core/services/consultations/rdv-domicile.service';

@Component({
  selector: 'edit-adresse',
  templateUrl: 'edit-adresse.component.html',
  styleUrls: ['edit-adresse.component.scss'],
})
export class EditAdresseComponent implements OnInit, OnDestroy {
  @Input() displayCodeCentravet = false;
  adresse!: Adresse;
  form: FormGroup;
  villes?: VilleForm[];
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly rdvService: RdvDomicileService,
    private readonly store: Store,
    private readonly fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      adresse: [null, [Validators.required]],
      codePostal: [null, [Validators.required]],
      codeInsee: [null],
      ville: [null, [Validators.required]],
      detail: [null],
      codeCentravet: [null, [Validators.maxLength(3)]],
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    if (this.adresse?.codePostal) {
      this.loadVilles(this.adresse.codePostal);
    }
    this.form.patchValue({
      adresse: this.adresse?.adresse,
      codePostal: this.adresse?.codePostal,
      codeInsee: this.adresse?.codeInsee,
      ville: this.adresse?.ville,
      detail: this.adresse?.detail,
      codeCentravet: this.adresse?.codeCentravet,
    });
  }

  private loadVilles(codePostal: string) {
    this.rdvService.getPostalCode(codePostal).subscribe(villes => {
      this.villes = villes;
      if (villes?.length === 1) {
        this.form.get('ville')!.setValue(villes[0]);
        this.form.get('codeInsee')!.setValue(villes[0].code);
      } else if (villes && villes.length > 1 && this.adresse?.ville) {
        const ville = villes.find(city => city.code === this.adresse?.codeInsee || city.nom.toLowerCase() === this.adresse!.ville!.toLowerCase());
        this.form.get('ville')!.setValue(ville);
        this.form.get('codeInsee')!.setValue(ville?.code);
      } else {
        this.form.get('ville')!.setValue(null);
        this.form.get('codeInsee')!.setValue(null);
      }
    });
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  save() {
    this.modalCtrl.dismiss({
      adresse: this.form.value.adresse,
      codePostal: this.form.value.codePostal,
      ville: this.form.value.ville.nom,
      codeInsee: this.form.value.ville.code,
      detail: this.form.value.detail,
      codeCentravet: this.form.value.codeCentravet,
    } as Adresse);
  }

  getCityCode(event: any) {
    const codePostal = event.target.value;
    if (codePostal.length === 5) {
      this.loadVilles(codePostal);
    }
  }
}
