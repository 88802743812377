import { Injectable, OnDestroy } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { selectDeviceTokenWithCredentials, selectUser } from '@core/store/selector/session.selectors';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, EMPTY, first, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ChatAttribution, ChatDiscussion } from '../../../models/chat.model';
import { ChatMessage } from '../../../models/message.model';
import { User, UserRole } from '../../../models/user.model';
import { PartenaireMilou, RdvStructure } from 'app/models/client.model';

@Injectable({
  providedIn: 'root',
})
export class ChatService implements OnDestroy {
  private readonly apiUrl = environment.API_URL;
  private readonly destroy$: Subject<void>;

  constructor(
    private readonly httpApiService: HttpApiService,
    private readonly store: Store,
  ) {
    this.destroy$ = new Subject<void>();
  }

  getMyDiscussions(): Observable<ChatDiscussion[]> {
    return this.store.select(selectUser).pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$),
      switchMap(user => {
        if (user) {
          switch (user.role) {
            case UserRole.ROLE_VETERINAIRE: {
              let discussions = this.httpApiService.get<ChatDiscussion[]>('api/chat/discussion/actives/consultant/' + user?.id);

              return this.calculateUnreadMessage(discussions, user);
            }
            // pas de backoffice car rôle déjà rempli par getWaitingDiscussions()
            default: {
              let discussionsOthers = this.httpApiService.get<ChatDiscussion[]>('api/chat/discussion/utilisateur/' + user?.id);

              return this.calculateUnreadMessage(discussionsOthers, user);
            }
          }
        } else {
          return of([]);
        }
      }),
    );
  }

  getDiscussionsCloturee(): Observable<ChatDiscussion[]> {
    return this.store.select(selectUser).pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$),
      switchMap(user => {
        if (user) {
          return this.httpApiService.get<ChatDiscussion[]>('api/chat/discussion/cloturee/' + user?.id);
        } else {
          return of([]);
        }
      }),
    );
  }

  getAllDiscussionsByClientId(id: string): Observable<ChatDiscussion[]> {
    return this.httpApiService.get<ChatDiscussion[]>('api/chat/discussion/utilisateur/' + id);
  }

  majLectureDiscussion(idDiscussion: string, discussions$: Observable<ChatDiscussion[]>): Observable<ChatDiscussion[]> {
    return this.store.select(selectUser).pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$),
      switchMap(user => {
        if (user) {
          return discussions$.pipe(
            first(),
            map(discussions => {
              return discussions.map(discussion => {
                const newDiscussion = JSON.parse(JSON.stringify(discussion));
                if (idDiscussion === discussion.id) {
                  newDiscussion.unreadMessage = false;
                }

                return newDiscussion;
              });
            }),
          );
        } else {
          return of([]);
        }
      }),
    );
  }

  getWaitingDiscussions(): Observable<ChatDiscussion[]> {
    return this.store.select(selectUser).pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$),
      switchMap(user => {
        if (user) {
          let discussions = this.httpApiService.get<ChatDiscussion[]>('api/chat/discussion/actives/withoutAttribution');

          this.calculateUnreadMessage(discussions, user);

          return discussions;
        } else {
          return of([]);
        }
      }),
    );
  }

  getAllActiveDiscussions(): Observable<ChatDiscussion[]> {
    return this.store.select(selectUser).pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$),
      switchMap(user => {
        if (user) {
          return this.httpApiService.get<ChatDiscussion[]>('api/chat/discussion/actives/all');
        } else {
          return of([]);
        }
      }),
    );
  }

  public calculateUnreadMessage(discussions$: Observable<ChatDiscussion[]>, user: User | undefined): Observable<ChatDiscussion[]> {
    if (user) {
      return discussions$.pipe(
        first(),
        map(discussions => {
          return discussions.map(discussion => {
            const attribution = this.getUserAttribution(discussion, user);
            if (attribution && attribution.dateLecture && discussion.dateDernierMessage) {
              discussion.unreadMessage = discussion.dateDernierMessage > attribution.dateLecture;
            } else {
              discussion.unreadMessage = true;
            }

            return discussion;
          });
        }),
      );
    }

    return of([]);
  }

  private getUserAttribution(discussion: ChatDiscussion, user: User): ChatAttribution | undefined {
    return discussion.attributions?.filter(attribution => attribution.utilisateur.id == user.id)[0];
  }

  majLecture(discussion: ChatDiscussion | null): Observable<void> {
    if (discussion != null) {
      return this.httpApiService.post<void>('api/chat/discussion/lecture', discussion);
    } else {
      return of();
    }
  }

  cloturerDiscussion(idDiscussion: string): Observable<void> {
    return this.httpApiService.delete<void>('api/chat/discussion/' + idDiscussion);
  }

  subscribChannel(idDiscussion: string): Observable<ChatMessage> {
    return this.store.select(selectDeviceTokenWithCredentials).pipe(
      take(1),
      switchMap(({ credentials, deviceId }) => {
        if (credentials?.access_token) {
          const endpoint = this.apiUrl + '/api/chat/sseDemande/' + idDiscussion + '/' + credentials?.access_token + '/' + deviceId;

          return this.httpApiService.getAsStream<ChatMessage>(endpoint);
        } else {
          return EMPTY;
        }
      }),
    );
  }

  unsubscribe(idDiscussion: string): Observable<void> {
    return this.store.select(selectDeviceTokenWithCredentials).pipe(
      take(1),
      switchMap(({ deviceId }) => this.httpApiService.get<void>('api/chat/sseUnsubscribe/' + idDiscussion + '/' + deviceId)),
    );
  }

  getMessages(idDiscussion: string | null | undefined): Observable<ChatMessage[]> {
    return this.httpApiService.get<ChatMessage[]>('api/chat/messages/' + idDiscussion);
  }

  sendMessage(message: string, idDiscussion: string): Observable<ChatMessage> {
    return this.store.select(selectUser).pipe(
      distinctUntilChanged(),
      switchMap(user => {
        const chatMessage: ChatMessage = {
          emetteur: user,
          message: message,
          idDiscussion: idDiscussion,
        };

        return this.httpApiService.post<ChatMessage>('api/chat/message/', chatMessage);
      }),
    );
  }

  loadVeto(): Observable<User[]> {
    return this.httpApiService.get<User[]>('api/user/veterinaires');
  }

  onAttribution(idDiscussion: string, idVeto: string | undefined): Observable<ChatDiscussion> {
    return this.httpApiService.post(`api/chat/attributions/change/${idDiscussion}/${idVeto}`);
  }

  // structure et dispo

  sendChatDispo(dateDebut: string, dateFin: string, idStructure: string, idVeto: string | undefined): Observable<void> {
    const dataSent = {
      dateDebut: dateDebut,
      dateFin: dateFin,
    };

    return this.httpApiService.post<void>(`api/back_office/planning/creneau/${idStructure}/${idVeto}`, dataSent);
  }

  isVetoDispo(idStructure: string, idVeto: string | undefined): Observable<boolean> {
    return this.httpApiService.get<boolean>(`api/back_office/planning/disponible/${idStructure}/${idVeto}`).pipe(map(data => data));
  }

  isVetoDispoList(idStructure: string = '0fb233b1-11a0-4da8-b2cd-4aaed0df9a91'): Observable<User[]> {
    return this.httpApiService.get<User[]>(`api/back_office/planning/disponible/${idStructure}`).pipe(map(data => data));
  }

  isMilouDispo(codeInsee: string | undefined): Observable<RdvStructure> {
    return this.httpApiService.get<RdvStructure>('api/checkCodeInsee/DRMILOU/' + codeInsee).pipe(map(data => data));
  }

  partenaireMilou(codeInsee: string | undefined): Observable<PartenaireMilou[]> {
    return this.httpApiService.get<PartenaireMilou[]>(`api/partenaire/findByCode/VAD/${codeInsee}`);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDiscussionById(id: string) {
    return this.httpApiService.get<ChatDiscussion>(`api/chat/discussion/${id}`);
  }
}
