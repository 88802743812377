import { ModalController, SearchbarCustomEvent } from '@ionic/angular';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Browser } from 'leaflet';
import mobile = Browser.mobile;

@Component({
  selector: 'app-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.scss'],
})
export class SearchableSelectComponent implements OnChanges {
  @Input() title = 'Rechercher';
  @Input() data!: any;
  @Input() itemTextField = 'nom';
  @Input() additionalField?: string;
  @Input() additionalFieldPosition?: 'fixed' | 'floating' | 'stacked';
  @Output() readonly selectedChanged: EventEmitter<any> = new EventEmitter();
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';

  mobile = mobile;
  isOpen = false;
  selected: Array<any> = [];
  filtered: Array<any> = [];

  constructor(private readonly mc: ModalController) {}

  ngOnChanges(): void {
    this.filtered = this.data;
  }

  open() {
    this.isOpen = true;
  }

  cancel() {
    this.isOpen = false;
    this.mc.dismiss();
  }

  reinit() {
    this.isOpen = false;
    this.selectedChanged.emit(null);
    this.mc.dismiss();
  }

  select() {
    const selected = this.data.filter((item: any) => item.selected);
    this.selected = selected;

    this.selectedChanged.emit(selected);

    this.isOpen = false;
    this.mc.dismiss();
  }

  itemSelect(item: any) {
    this.selected = [item];
    this.selectedChanged.emit(item);

    this.isOpen = false;
    this.mc.dismiss();
  }

  filter(event: SearchbarCustomEvent) {
    const filter = event.detail.value?.toLowerCase();
    this.filtered = this.data.filter((item: any) => this.leaf(item, this.itemTextField).toLowerCase().includes(filter));
  }

  leaf = (obj: any, field: string) => field.split('.').reduce((value, el) => value[el], obj);

  trackById = (item: any) => item.id;
}
