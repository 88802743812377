<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar color="blue100">
    <ion-button fill="clear" slot="end" (click)="cancel()">Fermer</ion-button>
  </ion-toolbar>
</ion-header>
<ion-content color="blue100">
  <ion-card class="card-rdv">
    <ion-item color="blue400" lines="none">
      <ion-icon name="paw-outline" slot="start" color="blue600"></ion-icon>
      <ion-label class="ion-text-wrap" color="blue900"><b>Sélectionner un devis à appliquer</b></ion-label>
    </ion-item>
    <ion-item
      button
      (click)="selectDevis(devis)"
      *ngFor="let devis of devisList; let last = last"
      [color]="devis.idFacturation ? 'blue200' : 'white'"
      [lines]="last ? 'none' : ''"
    >
      <ion-label class="ion-text-wrap" color="blue900">
        <b>{{ devis.etat | etatDevis }}</b
        >{{ devis.montant ? ' : ' + devis.montant + '€' : '' }} ({{ devis.dateDevis | date: 'mediumDate' }})
        <p *ngIf="devis.idAnimal && animalNameByIdMap && animalNameByIdMap.has(devis.idAnimal)">{{ 'Pour ' + animalNameByIdMap.get(devis.idAnimal) }}</p>
        <p *ngFor="let acte of devis.actesRealises">
          {{ acte.nom }}
        </p>
        <p *ngFor="let produit of devis.produitsDelivres">
          {{ produit.nom }}
        </p>
        <p *ngIf="devis.idFacturation"><b>Déjà utilisé</b></p>
      </ion-label>
      <ion-button slot="end" shape="round" fill="clear" (click)="deleteDevis(devis); $event.stopPropagation()">
        <ion-icon slot="icon-only" name="close-outline" color="danger"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-card>
</ion-content>
