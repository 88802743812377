import { Action, createReducer, on } from '@ngrx/store';
import { ChatDiscussion } from '../../../../models/chat.model';
import { DiscussionActions } from '@core/store/actions/discussion.actions';

export interface DiscussionState {
  readonly discussions: ChatDiscussion[];
  readonly waitingDiscussions: ChatDiscussion[];
  readonly activeDiscussion: ChatDiscussion | null;
  readonly allActiveDiscussions: ChatDiscussion[];
  readonly nbUnreadMessagerie: number;
}

export const initialState: DiscussionState = {
  discussions: [],
  waitingDiscussions: [],
  activeDiscussion: null,
  allActiveDiscussions: [],
  nbUnreadMessagerie: 0,
};

export const discussionReducer = createReducer(
  initialState,
  on(DiscussionActions.loadDiscussionSucced, (state, { discussions }): DiscussionState => ({ ...state, discussions })),
  on(DiscussionActions.loadWaitingDiscussionSucced, (state, { waitingDiscussions }): DiscussionState => ({ ...state, waitingDiscussions })),
  on(DiscussionActions.loadAllActiveDiscussionSucced, (state, { allActiveDiscussions }): DiscussionState => ({ ...state, allActiveDiscussions })),
  on(DiscussionActions.setActiveDiscussion, (state, { activeDiscussion }): DiscussionState => ({ ...state, activeDiscussion })),
  on(DiscussionActions.addDiscussionSucced, (state, { newDiscussion }): DiscussionState => ({ ...state, discussions: [newDiscussion, ...state.discussions] })),
  on(DiscussionActions.getunreadmessagescountsuccess, (state, { count }): DiscussionState => ({ ...state, nbUnreadMessagerie: count })),
  on(DiscussionActions.readdiscussion, (state): DiscussionState => ({ ...state, nbUnreadMessagerie: Math.min(0, state!.nbUnreadMessagerie - 1) })),
);

export function reducer(state: DiscussionState, action: Action): DiscussionState {
  return discussionReducer(state, action);
}
