import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModePaiementEnum, Paiement, PaiementStatus } from '../../../../models/paiement.model';
import { PaiementService } from '../../../rdv/wizard/services/paiement.service';
import { AlertController, ToastController } from '@ionic/angular';
import { TypeRdv } from '../../../../models/interaction.model';

@Component({
  selector: 'app-reglement',
  templateUrl: './reglement.component.html',
  styleUrls: ['./reglement.component.scss'],
})
export class ReglementComponent implements OnInit {
  protected readonly PaiementStatus = PaiementStatus;
  protected readonly ModePaiementEnum = ModePaiementEnum;
  readonly MIN_WAIT_TIME_RELANCE_PAIEMENT_SECONDES = 300; // 5mn

  @Input() isEditable?: boolean;
  @Input() paiements: Paiement[] = []; // doit être modifiable
  @Output() readonly paiementsChange: EventEmitter<Paiement[]> = new EventEmitter<Paiement[]>();
  @Input() typeRdv!: TypeRdv;
  @Input() idRdv?: string;
  @Input() montant?: number;
  @Input() displayRefreshBtn = true;
  @Output() readonly refresh: EventEmitter<void> = new EventEmitter<void>();

  modePaiement?: ModePaiementEnum;
  isPaying = false;
  private montantInitial?: number;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly paiementService: PaiementService,
    private readonly toastController: ToastController,
    private readonly alertCtrl: AlertController,
  ) {}

  ngOnInit() {
    this.montantInitial = this.montant;
  }

  validerPaiement() {
    this.isPaying = true;
    const status: PaiementStatus = this.modePaiement === ModePaiementEnum.DR_MILOU ? PaiementStatus.EN_COURS : PaiementStatus.OK;
    this.paiements.push({ updatedAt: new Date(), type: this.modePaiement, montant: +this.montant!.toFixed(2), status } as Paiement);
    this.paiementsChange.emit(this.paiements);
    this.montant = this.montantInitial !== undefined ? this.montantInitial - this.montant! : 0;
    this.cd.markForCheck();
  }

  enablePaiement() {
    this.isPaying = false;
  }

  deletePaiement(idx: number) {
    if (!this.paiements[idx].id) {
      const montantDelete = this.paiements[idx].montant;
      this.paiements.splice(idx, 1);
      this.paiementsChange.emit([...this.paiements]);
      this.montant = this.montant! + montantDelete;
    } else {
      this.paiementService.deletePaiement(this.idRdv!, this.typeRdv, this.paiements[idx].id).subscribe(() => {
        const montantDelete = this.paiements[idx].montant;

        this.paiements.splice(idx, 1);
        this.paiementsChange.emit(this.paiements);
        this.montant = this.montant! + montantDelete;
      });
    }
  }

  refreshPayments() {
    this.refresh.emit();
  }

  async relancePaiement(idPaiement: string) {
    const alert = await this.alertCtrl.create({
      header: 'Voulez-vous vraiment relancer le client ?',
      buttons: [
        {
          text: 'Non',
          role: 'cancel',
        },
        {
          text: 'Oui',
          role: 'confirm',
          handler: () => {
            this.paiementService.relancePaiement(idPaiement, this.idRdv!, this.typeRdv).subscribe(async () => {
              const toast = await this.toastController.create({
                header: 'Demande de paiement envoyée',
                duration: 5000,
                message: 'Un mail a été envoyé au client',
                position: 'bottom',
              });
              await toast.present();
            });
          },
        },
      ],
    });

    await alert.present();
  }
}
