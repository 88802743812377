import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, first, map } from 'rxjs';

import { selectIsUserAuthenticated, selectIsUserNotAuthenticated, selectUser } from '@core/store/selector/session.selectors';
import { environment } from 'environments/environment';
import { UserRole } from '../../../models/user.model';

export const isUserAuthenticatedGuard = () => {
  const router = inject(Router);

  return inject(Store)
    .select(selectIsUserAuthenticated)
    .pipe(
      first(),
      map(isUserAuthenticated => isUserAuthenticated || router.createUrlTree(['/login'])),
    );
};

export const isUserNotAuthenticatedGuard = () => {
  const router = inject(Router);

  return inject(Store)
    .select(selectIsUserNotAuthenticated)
    .pipe(
      first(),
      map(isUserAuthenticated => isUserAuthenticated || router.createUrlTree(['/'])),
    );
};

export const dashboardGuard = () => {
  const router = inject(Router);

  return environment.enableDashBoard || environment.enableDashBoardForAdmin || router.createUrlTree(['/chat']);
};
export const adminOrBackofficeGuard = () => {
  const router = inject(Router);
  const store = inject(Store);

  return store.select(selectUser).pipe(
    distinctUntilChanged(),
    map(user => user?.role),
    map(role => (environment.enableAdmin && (UserRole.ROLE_ADMIN === role || UserRole.ROLE_BACKOFFICE === role)) || router.createUrlTree(['/chat'])),
  );
};
export const adminGuard = () => {
  const router = inject(Router);
  const store = inject(Store);
  console.log('ADMIN GUARD');

  return store.select(selectUser).pipe(
    distinctUntilChanged(),
    map(user => user?.role),
    map(role => (environment.enableAdmin && UserRole.ROLE_ADMIN === role) || router.createUrlTree(['/chat'])),
  );
};
export const clientGuard = () => {
  const router = inject(Router);

  return environment.enableClient || environment.enableClientForAdmin || router.createUrlTree(['/chat']);
};
