import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frenchNumber',
})
export class FrenchNumberPipe implements PipeTransform {
  constructor() {}

  transform(val: number | null) {
    return this.formatToFrench(val);
  }

  private formatToFrench(value: number | null): string | null {
    if (value !== null && value !== undefined) {
      // Handle 0 and other numbers equally
      return new Intl.NumberFormat('fr-FR').format(value);
    }

    return null;
  }
}
