<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar color="blue200" mode="ios">
    <ion-title class="welcome stats ion-text-center">
      <h2>Historique des interactions</h2>
    </ion-title>
    <ion-button fill="clear" shape="round" color="danger" (click)="cancel()" slot="start">
      <ion-icon slot="start" name="close-outline"></ion-icon>
      <ion-label>Fermer</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-header>
<ion-content color="blue100">
  <ion-list>
    <ion-list-header *ngIf="interactions?.length">
      <ion-label class="interaction interaction-header ion-text-center">Canal</ion-label>
      <ion-label class="interaction interaction-header ion-text-center">Type</ion-label>
      <ion-label class="interaction ion-text-center">Auteur</ion-label>
      <ion-label class="interaction date-sent ion-text-center">Date d'envoi</ion-label>
      <ion-label class="interaction-contenu ion-text-center">Description</ion-label>
    </ion-list-header>

    <ion-item *ngFor="let interaction of interactions; let idx = index" class="rappel">
      <ion-label class="interaction ion-text-center">
        {{ interaction.canal }}
      </ion-label>
      <ion-label class="interaction ion-text-center">
        {{ interaction.type }}
      </ion-label>
      <ion-label class="interaction ion-text-center">
        {{ interaction.initiateur | trigramme }}
      </ion-label>
      <ion-label class="interaction ion-text-wrap date-sent ion-text-center">
        {{ interaction.dateEnvoi | dateRdv }}
      </ion-label>
      <ion-label class="interaction-contenu ion-text-wrap ion-text-center">
        {{ interaction.contenu }}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
