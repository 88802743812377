import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatestWith, distinctUntilChanged, filter, first, Observable } from 'rxjs';

import { SessionActions } from '@core/store/actions/session.actions';
import { selectIsAppInitialized } from '@core/store/selector/session.selectors';
import { ActivatedRoute } from '@angular/router';
import { selectStructuresList } from '@core/store/selector/admin.selector';
import { map, skipWhile } from 'rxjs/operators';
import { adminActions } from '@core/store/actions/admin.action';
import { HttpApiService } from '@core/services/http-api/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly httpApiService: HttpApiService,
  ) {}

  getLastBuildTimestamp() {
    return this.httpApiService.get<number>(`api/backoffice/buildTimestamp`);
  }

  getStructureUpdateTimestamp() {
    return this.httpApiService.get<Date>(`api/metadata/structure-update-timestamp`);
  }

  /** Load */
  load(): Observable<boolean> {
    this.store
      .select(selectStructuresList)
      .pipe(
        distinctUntilChanged(),
        combineLatestWith(
          this.route.queryParamMap.pipe(
            skipWhile(p => !p.has('idStruct')),
            map(p => p.get('idStruct')),
          ),
        ),
      )
      .subscribe(structuresEtIdStructure => {
        if (structuresEtIdStructure[1] && structuresEtIdStructure[0]?.length) {
          this.store.dispatch(adminActions.setActiveStructure({ activeStructure: structuresEtIdStructure[0].find(s => s.id === structuresEtIdStructure[1])! }));
        }
      });
    this.store.dispatch(SessionActions.startAppInitialization());

    return this.store.select(selectIsAppInitialized).pipe(
      filter(finishedInit => finishedInit),
      first(),
    );
  }
}
