import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'modal-cr-text',
  templateUrl: './modal-cr-text.component.html',
  styleUrls: ['./modal-cr-text.component.scss'],
})
export class ModalCrTextComponent {
  @Input() htmlContent!: string;

  constructor(private modalController: ModalController) {}

  dismissModal() {
    this.modalController.dismiss();
  }
}
