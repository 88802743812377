<div
  class="tooltip"
  [class]="'tooltip--' + position"
  [ngClass]="{ 'tooltip-visible': visible }"
  [style.left]="left + 'px'"
  [style.top]="top + 'px'"
  *ngIf="tooltip"
>
  {{ tooltip }}
</div>
