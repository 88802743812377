import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { initializeApp } from 'firebase/app';
import { environment } from '../environments/environment';

initializeApp(environment.firebase);

@Component({
  selector: 'app-root',
  template: ` <ion-app>
    <ion-router-outlet></ion-router-outlet>
  </ion-app>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
  ) {
    ['notification', 'action', 'alert', 'communication', 'device', 'file', 'image', 'social', 'toggle', 'navigation'].map(url =>
      matIconRegistry.addSvgIconSet(sanitizer.bypassSecurityTrustResourceUrl(`mat-icons/svg-sprite-${url}.svg`)),
    );
    if (!window.name || window.name === '') {
      window.name = self.crypto.randomUUID();
    }
  }
}
