import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { CompteRendu, FileCR, ModeleCR } from '../../../../models/compte-rendu.model';
import { Observable } from 'rxjs';
import { HorsRdv, Rdv, RdvDomicile } from '../../../../models/rdv.model';
import { DossierMedicalRequest } from '../../../../models/export-dossier-medical.model';

@Injectable({
  providedIn: 'root',
})
export class CompteRenduService {
  constructor(private readonly httpApiService: HttpApiService) {}

  public saveCompteRendu(idPrestation: string, compteRendu: CompteRendu, finalize: boolean): Observable<CompteRendu> {
    const formData: FormData = new FormData();
    if (compteRendu.files) {
      for (let file of compteRendu.files) {
        const uint8Array = new Uint8Array(file.file!);
        const blob = new Blob([uint8Array]);
        formData.append('files', blob, file.fileName);
      }
      compteRendu = { ...compteRendu, files: compteRendu.files.map(f => ({ ...f, file: [] })) };
    }

    formData.append('compteRendu', JSON.stringify(compteRendu));

    return this.httpApiService.post<CompteRendu>(`api/back_office/compte-rendu/buildCR/${idPrestation}/${finalize}`, formData);
  }

  public getModelesCR(categorie: string): Observable<ModeleCR[]> {
    return this.httpApiService.get(`api/back_office/compte-rendu/modelesCR/${categorie}`);
  }

  deleteFile(idFileCR: string): Observable<void> {
    return this.httpApiService.delete(`api/back_office/compte-rendu/file/${idFileCR}`);
  }

  findPdfById(id: string): Observable<Blob> {
    return this.httpApiService.get(`api/back_office/compte-rendu/${id}/pdf`, undefined, { responseType: 'arraybuffer' });
  }

  sendCR(rdvId: string, id: string, email: string): Observable<void> {
    return this.httpApiService.post(`api/back_office/compte-rendu/${rdvId}/${id}/send/${email}`);
  }

  getCrPDF(idCr: string): Observable<Blob> {
    return this.httpApiService.get(`api/back_office/compte-rendu/${idCr}/pdf`, undefined, { responseType: 'arraybuffer' });
  }

  sendDossierMedical(dossierMedicalRequest: DossierMedicalRequest): Observable<void> {
    return this.httpApiService.post(`api/back_office/compte-rendu/send-dossier-medical`, dossierMedicalRequest);
  }

  getDossierMedical(dossierMedicalRequest: DossierMedicalRequest): Observable<Blob> {
    return this.httpApiService.postWithParams(`api/back_office/compte-rendu/dossier-medical`, dossierMedicalRequest, undefined, {
      responseType: 'arraybuffer',
    });
  }

  getFileBlob(idFileCR: string): Observable<Blob> {
    return this.httpApiService.get(`api/back_office/compte-rendu/file/${idFileCR}`, undefined, { responseType: 'arraybuffer' });
  }

  uploadFiles(standaloneCR: CompteRendu, idAnimal: string, notifyClient: boolean): Observable<CompteRendu> {
    const formData: FormData = new FormData();
    if (standaloneCR.files) {
      for (let file of standaloneCR.files) {
        const uint8Array = new Uint8Array(file.file!);
        const blob = new Blob([uint8Array]);
        formData.append('files', blob, file.fileName);
      }
      standaloneCR = { ...standaloneCR, files: standaloneCR.files.map(f => ({ ...f, file: [] })) };
    }
    formData.append('compteRendu', JSON.stringify(standaloneCR));

    return this.httpApiService.post(`api/back_office/compte-rendu/standalone/animal/${idAnimal}/${notifyClient}`, formData);
  }

  addFiles(files: FileCR[], idCR: string): Observable<FileCR[]> {
    const formData: FormData = new FormData();
    for (let file of files) {
      const uint8Array = new Uint8Array(file.file!);
      const blob = new Blob([uint8Array]);
      formData.append('files', blob, file.fileName);
    }
    files.forEach(f => (f.file = []));
    formData.append('fileCRList', JSON.stringify(files));

    return this.httpApiService.post(`api/back_office/compte-rendu/files/${idCR}`, formData);
  }

  getStandaloneCRAnimal(idAnimal: string): Observable<CompteRendu[]> {
    return this.httpApiService.get(`api/back_office/compte-rendu/standalone/animal/${idAnimal}`);
  }

  getStandaloneCRClient(idClient: string): Observable<CompteRendu[]> {
    return this.httpApiService.get(`api/back_office/compte-rendu/standalone/client/${idClient}`);
  }

  downloadFile(file: FileCR) {
    if (file.id) {
      this.getFileBlob(file.id).subscribe(blob => {
        const fileURL = window.URL.createObjectURL(new Blob([blob], { type: file.mimeType }));
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = fileURL;
        link.download = file.fileName!;
        link.click();
      });
    } else {
      const fileURL = window.URL.createObjectURL(new Blob([new Uint8Array(file.file!)]));
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = fileURL;
      link.download = file.fileName!;
      link.click();
    }
  }

  isCompteRendu(item: CompteRendu | RdvDomicile | HorsRdv): item is CompteRendu {
    return (item as CompteRendu).dateCompteRendu !== undefined;
  }

  getDate = (entry: CompteRendu | Rdv | RdvDomicile | HorsRdv): Date | undefined => {
    if (this.isCompteRendu(entry) && entry.dateCompteRendu) {
      return new Date(entry.dateCompteRendu);
    } else if ('dateConsultation' in entry && entry.dateConsultation) {
      return new Date(entry.dateConsultation);
    } else if ('dateSouhaitee' in entry && entry.dateSouhaitee) {
      return new Date(entry.dateSouhaitee);
    } else if ('createDateTime' in entry && entry.createDateTime) {
      return new Date(entry.createDateTime);
    }

    return undefined;
  };

  groupCrAndRdvs(compteRendus: CompteRendu[], rdvs: (Rdv | RdvDomicile)[], horsRdvs: HorsRdv[]): (CompteRendu | Rdv | RdvDomicile | HorsRdv)[] {
    let allEntries: (CompteRendu | Rdv | RdvDomicile | HorsRdv)[] = [...compteRendus, ...rdvs, ...horsRdvs];

    return allEntries.sort((a, b) => {
      let dateA = this.getDate(a)?.getTime() ?? 0;
      let dateB = this.getDate(b)?.getTime() ?? 0;

      return dateB - dateA;
    });
  }

  deleteCr(idCr: string): Observable<void> {
    return this.httpApiService.delete(`api/back_office/compte-rendu/${idCr}`);
  }

  public resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob | null> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        let canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        let context = canvas.getContext('2d');
        context!.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }
}
