import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Poids } from 'app/models/compte-rendu.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PoidsService {
  constructor(private readonly httpApiService: HttpApiService) {}

  updatePoids(objPoids: Poids): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/animal/courbepoids`, objPoids);
  }

  getPoids(idAnimal: string | undefined): Observable<Poids[]> {
    return this.httpApiService.get<Poids[]>(`api/back_office/animal/courbepoids/${idAnimal}`);
  }

  deletePoids(objPoids: Poids): Observable<void> {
    return this.httpApiService.post(`api/back_office/animal/courbepoids/delete`, objPoids);
  }
}
