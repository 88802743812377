import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { JwtToken } from 'app/models/jwt-token.model';

export const SessionActions = createActionGroup({
  source: 'Session',
  events: {
    'Start App Initialization': emptyProps(),
    'Check User Authentication': emptyProps(),
    'Finish App Initialization': emptyProps(),
    'Reset State And Finish App Initialization': emptyProps(),
    'Set Credentials': props<{ credentials: JwtToken }>(),
    'Reset State': emptyProps(),
  },
});
