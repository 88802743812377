<ion-row>
  <ion-col size="12">
    <div class="download-div">
      <ion-button color="blue100" class="download-btn" id="upload" expand="block">
        <ion-icon src="/assets/attach.svg" slot="start"></ion-icon>
        <ion-label color="grey900">Ajouter une pièce jointe</ion-label>
      </ion-button>
    </div>
  </ion-col>
</ion-row>
<ion-modal trigger="upload" (didDismiss)="cancel()" class="standalone-cr-modal-size" [backdropDismiss]="false">
  <ng-template>
    <ion-header class="ion-no-border ion-no-shadow">
      <ion-toolbar color="blue200">
        <div class="header-btns">
          <ion-button fill="clear" shape="round" color="danger" (click)="cancel()" slot="start" class="button">
            <ion-icon slot="start" name="close-outline"></ion-icon>
            <ion-label>Fermer</ion-label>
          </ion-button>
          <div
            [tooltip]="sumFileSizeOverSizeLimit ? 'La somme des fichiers dépasse la taille maximale autorisée : ' + MAX_REQUEST_SIZE_MB + 'Mo' : ''"
            class="button"
          >
            <ion-button
              slot="end"
              color="primary"
              shape="round"
              class="infos-check"
              (click)="save()"
              [disabled]="fileForm.invalid || filesToSave.length === 0 || hasFileOverSizeLimit || sumFileSizeOverSizeLimit"
            >
              <ion-icon name="checkmark-circle" color="success" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">Enregistrer</ion-label>
            </ion-button>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <form [formGroup]="fileForm">
        <ion-row class="file-upload">
          <ion-col size="6">
            <ion-select placeholder="Type de fichier" interface="popover" formControlName="fileType" name="fileType">
              <ion-select-option [value]="FileCRTypeEnum.RESULTAT_ANALYSE">Résultat d'analyse</ion-select-option>
              <ion-select-option [value]="FileCRTypeEnum.CERTIFICAT">Certificat</ion-select-option>
              <ion-select-option [value]="FileCRTypeEnum.PHOTO">Photo</ion-select-option>
              <ion-select-option [value]="FileCRTypeEnum.RETOUR_REFERE">Retour référé</ion-select-option>
              <ion-select-option [value]="FileCRTypeEnum.AUTRE">Autre</ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col size="6">
            <ion-input formControlName="dateDoc" type="date" name="dateDoc" placeholder="Date du doc"></ion-input>
          </ion-col>
          <br />

          <ion-col>
            <div class="download-div">
              <ion-button expand="full" color="blue100" class="download-btn" (click)="upload.click()" [disabled]="fileForm.invalid">
                <input type="file" hidden="hidden" #upload multiple (change)="uploadFile($event)" accept="application/pdf, image/png, image/jpeg" />
                <ion-icon src="/assets/attach.svg" slot="start"></ion-icon>
                <ion-label color="grey900">Télécharger un fichier</ion-label>
              </ion-button>
              <ion-text color="grey400" *ngIf="displayedFiles.length === 0">
                <p>Aucun fichier téléchargé</p>
              </ion-text>
            </div>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="isUploadingFiles">
          <ion-col>
            <div class="ion-text-center">
              <ion-spinner name="crescent"></ion-spinner>
            </div>
          </ion-col>
        </ion-row>
      </form>

      <ion-row class="file-upload">
        <ion-col size="12" *ngIf="displayedFiles && displayedFiles.length > 0">
          <ion-list class="file-list">
            <ion-item *ngFor="let file of displayedFiles; let i = index">
              <ng-container [ngSwitch]="file.type">
                <ion-icon slot="start" name="camera-outline" *ngSwitchCase="FileCRTypeEnum.PHOTO" title="Photo"></ion-icon>
                <ion-icon slot="start" name="chatbox-ellipses-outline" *ngSwitchCase="FileCRTypeEnum.RETOUR_REFERE" title="Retour référé"></ion-icon>
                <ion-icon slot="start" name="document-text-outline" *ngSwitchCase="FileCRTypeEnum.CERTIFICAT" title="Certificat"></ion-icon>
                <ion-icon slot="start" name="flask-outline" *ngSwitchCase="FileCRTypeEnum.RESULTAT_ANALYSE" title="Résultat d'analyse"></ion-icon>
                <ion-icon slot="start" name="document-outline" *ngSwitchCase="FileCRTypeEnum.AUTRE" title="Autre"></ion-icon>
              </ng-container>
              <ion-label class="ion-text-wrap">{{ file.fileName || 'Sans titre' }}</ion-label>
              <ion-icon
                [tooltip]="'Ce fichier dépasse la taille maximale par fichier : ' + MAX_FILE_SIZE_MB + 'Mo'"
                slot="end"
                color="danger"
                name="warning-outline"
                *ngIf="file.file && file.file.length / 1024 / 1024 > MAX_FILE_SIZE_MB"
              ></ion-icon>
              <ion-label class="ion-text-wrap" *ngIf="file.file" slot="end"
                >{{ file.file.length / 1024 / 1024 | number: '1.0-2' }}
                Mo
              </ion-label>
              <ion-button (click)="downloadFile(file)" fill="outline" color="blue400" slot="end">
                <ion-icon name="download-outline" color="blue600"></ion-icon>
              </ion-button>
              <ion-button (click)="deleteDisplayedFile(i)" fill="outline" color="suppr" slot="end">
                <ion-icon name="close-outline" color="wait"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-content>
  </ng-template>
</ion-modal>
