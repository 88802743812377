import { Component, OnInit } from '@angular/core';
import { LogService } from '@core/services/log/log.service';
import { ModalController } from '@ionic/angular';
import { CompteRendu, FileCR } from 'app/models/compte-rendu.model';
import { Action, Context, TypeLog } from 'app/models/log.model';
import { Facture } from 'app/models/rdv.model';
import { Ordonnance } from '../../../../models/ordonnance.model';

@Component({
  selector: 'pdf-viewer',
  templateUrl: 'pdf-viewer.component.html',
  styleUrls: ['pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  crProps?: CompteRendu;
  factureProps?: Facture;
  ordoProps?: Ordonnance;
  fileProps?: FileCR;
  blobProps?: Blob;
  typeProps!: 'cr' | 'facture' | 'fichier' | 'ordo';
  idProps!: string;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly logService: LogService,
  ) {}

  ngOnInit(): void {
    if (this.blobProps && this.blobProps.size === 0 && this.typeProps === 'facture') {
      this.logService.log(Context.PDF_FACTURE, Action.CONSULTATION, TypeLog.ERROR, { idFacture: this.factureProps?.id, message: 'Erreur chargement pdf' });
    }
    if (this.blobProps && this.blobProps.size === 0 && this.typeProps === 'cr') {
      this.logService.log(Context.PDF_CR, Action.CONSULTATION, TypeLog.ERROR, {
        idCr: this.idProps,
        message: 'Erreur chargement pdf',
      });
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  download() {
    if (!this.blobProps) {
      return;
    }
    if (this.typeProps === 'cr') {
      const fileURL = window.URL.createObjectURL(this.blobProps);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `compte-rendu-${this.idProps}.pdf`;
      link.click();
    } else if (this.typeProps === 'facture') {
      const fileURL = window.URL.createObjectURL(this.blobProps);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = this.factureProps!.numFacture + '.pdf';
      link.click();
    } else if (this.typeProps === 'ordo') {
      const fileURL = window.URL.createObjectURL(this.blobProps);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = this.ordoProps!.numOrdonnance + '.pdf';
      link.click();
    } else {
      const fileURL = window.URL.createObjectURL(this.blobProps);
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = fileURL;
      link.download = this.fileProps!.fileName!;
      link.click();
    }
  }
}
