import { Observable } from 'rxjs';
import { Veterinaire } from './admin.model';
import { Produit } from './produit.model';
import { AdresseStructure, Prestation, StructureVeterinaire } from './rdv.model';
import { Adresse, Groupe, User } from './user.model';

export interface LigneStockVeterinaire extends LigneStock {}

export interface LigneStockStructure extends LigneStock {}

export interface LigneStock {
  id?: string;
  quantite: number;
  seuilMin: number;
  seuilMax: number;
  produit: Produit;
  stockId: string;
}

export interface SearchLigneStockRequest {
  idVeto?: string;
  idStructure?: string;
  idAdresse?: string;
  idStock?: string;
  idProduit?: string;
  stockType?: StockType[];
  quantiteInferieurSeuilMin?: boolean;
  quantiteMin?: number;
  quantiteMax?: number;
  seuilMinMin?: number;
  seuilMinMax?: number;
  seuilMaxMin?: number;
  seuilMaxMax?: number;
  codeGroupe?: string;
}

export enum StockType {
  LOCAL = 'LOCAL',
  VETERINAIRE = 'VETERINAIRE',
}

export interface MouvementProduit {
  id?: string;
  quantite: number;
  typeSource: TypeSourceMouvement;
  typeCible?: TypeCibleMouvement;
  nomCentral?: string;
  idStockSource?: string;
  idStockCible?: string;
  idProduit: string;
  prestation?: Prestation;
  dateMouvement: string;
  groupe?: Groupe;
  user?: { id: string };
  motifMouvement: MotifMouvement;
}

export interface CreateSourceCibleMotif {
  idStockSource: string;
  idStockCible?: string;
  motifMouvement: MotifMouvement;
}

export interface LigneSource {
  ligne: LigneStock;
  quantite: number;
}

export interface MouvementSearchPayload {
  dateMin: string;
  dateMax: string;
  typeCible: TypeCibleMouvement;
  typeSource: TypeSourceMouvement;
  quantiteMin: number;
  quantiteMax: number;
  idClient: string;
  idProduit: string;
  idVeto: string;
  codeGroupe: string;
  motifs: MotifMouvement[];
}

export interface Commande {
  createDateTime: string;
  updateDateTime: string;
  id: string;
  produits: ProduitCommande[];
  structure: StructureVeterinaire;
  veterinaire: Veterinaire;
  user: User;
  statutCommande: CommandeStatut;
  adresseLivraison: AdresseLivraison;
}

export interface ProduitCommande {
  id?: string;
  produit: Produit;
  quantite: number;
  quantiteRecue?: number;
  statutCommande?: CommandeStatut;
}

export interface CommandeSearchPayload {
  idVeto?: string;
  idStructure?: string;
  idUser?: string;
  from?: string;
  until?: string;
  codeGroupe?: string;
  statutToIncludes?: Array<CommandeStatut>;
  statutToExcludes?: Array<CommandeStatut>;
}

export interface CommandeCentrale {
  createDateTime: string;
  updateDateTime: string;
  id: string;
  produits: Produit[];
  structure: StructureVeterinaire;
  veterinaire: Veterinaire;
  user: User;
  statutCommande: string;
  adresseLivraison: AdresseLivraison;
}

export interface AdresseLivraison extends Adresse {
  nom: string;
}

export enum CommandeStatut {
  CREEE = 'CREEE',
  ENVOYEE = 'ENVOYEE',
  ANNULEE = 'ANNULEE',
  REFUSEE = 'REFUSEE',
  VALIDEE = 'VALIDEE',
  EN_ROUTE = 'EN_ROUTE',
  RECUE_PARTIELLEMENT = 'RECUE_PARTIELLEMENT',
  RECUE_TOTAL = 'RECUE_TOTAL',
  LIVREE = 'LIVREE',
}

export enum TypeSourceMouvement {
  CENTRAL = 'CENTRAL',
  STRUCTURE = 'STRUCTURE',
  VETERINAIRE = 'VETERINAIRE',
}

export enum TypeCibleMouvement {
  PRESTATION = 'PRESTATION',
  STRUCTURE = 'STRUCTURE',
  VETERINAIRE = 'VETERINAIRE',
}

export enum MotifMouvement {
  ACHAT_STOCK = 'ACHAT_STOCK',
  TRANSFERT_STRUCTURE_VETO = 'TRANSFERT_STRUCTURE_VETO',
  TRANSFERT_STRUCTURE_STRUCTURE = 'TRANSFERT_STRUCTURE_STRUCTURE',
  TRANSFERT_VETO_STRUCTURE = 'TRANSFERT_VETO_STRUCTURE',
  TRANSFERT_VETO_VETO = 'TRANSFERT_VETO_VETO',
  FACTURE_CLIENT = 'FACTURE_CLIENT',
  CASSE = 'CASSE',
  PEREMPTION = 'PEREMPTION',
  PERTE = 'PERTE',
}

export interface Stock {
  id: string;
  nom: string;
  veterinaire: Veterinaire | null;
  adresse: AdresseStructure | null;
  stockType: StockType;
}

export type ArrayWithLigneStock = {
  [key: string]: LigneStock[];
};

export type ArrayWithObservableLigneStock = {
  [key: string]: Observable<LigneStock[]>;
};
