import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { of, switchMap } from 'rxjs';
import { LogRequest, TypeLog } from '../../../../models/log.model';
import { selectUser } from '@core/store/selector/session.selectors';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(
    private readonly httpApiService: HttpApiService,
    private readonly store: Store,
  ) {}

  log(context: string, action: string, typeLog: TypeLog, payload: any): void {
    this.store
      .select(selectUser)
      .pipe(
        take(1),
        switchMap(user => {
          if (user) {
            const logRequest: LogRequest = {
              channel: 'BACKOFFICE',
              userName: user.username,
              role: user.role,
              dateLog: new Date(),
              payload: JSON.stringify(payload),
              context: context,
              action: action,
              typeLog: typeLog,
            };

            return this.httpApiService.post<any>('api/log', logRequest);
          } else {
            return of('');
          }
        }),
      )
      .subscribe();
  }
}
