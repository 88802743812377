import { Injectable } from '@angular/core';
import { exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { ChatService } from '../../../chat/services/chat.service';
import { selectActiveDiscussion } from '../selector/discussion.selectors';
import { Store } from '@ngrx/store';
import { ChatActions } from '../actions/chat.actions';
import { ChatMessage } from 'app/models/message.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ChatDiscussion } from 'app/models/chat.model';
import { distinctUntilChanged } from 'rxjs';

@Injectable()
export class ChatEffects {
  getMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.getmessages),
      exhaustMap(action => {
        return this.chatService.getMessages(action.idDiscussion).pipe(
          map((messages: ChatMessage[]) => {
            return ChatActions.getMessagesSuccess({ messages: messages });
          }),
        );
      }),
    );
  });

  subChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.subscribeChannel),
      switchMap(action => {
        console.log('Sub Channel Effect');

        return this.chatService.subscribChannel(action.idDiscussion).pipe(
          map((message: ChatMessage) => {
            console.log('New SSE message');

            if (message) {
              return ChatActions.subscribeChannelSuccess({ message: message });
            }
            console.log('Error sse new message');

            return ChatActions.getNewMessageError();
          }),
          tap(() => {
            console.log('get new message sse success');

            return ChatActions.getNewMessageSuccess();
          }),
        );
      }),
    );
  });

  unsubChannel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.unsubscribeChannel),
      switchMap(action => {
        return this.chatService.unsubscribe(action.idDiscussion).pipe(
          map(() => {
            return ChatActions.unsubscribeChannelSuccess();
          }),
          tap(() => {
            console.log('Unsubscribe SSE');
          }),
        );
      }),
    );
  });

  unsubChannelSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.unsubscribeChannelSuccess),
      map(() => {
        console.log('try reset state');

        return ChatActions.resetMessageState();
      }),
    );
  });

  sseChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.ssechange),
      switchMap(action => this.chatService.unsubscribe(action.oldId).pipe(map(() => action))),
      map(action => {
        return ChatActions.subscribeChannel({ idDiscussion: action.nextDiscussion.id });
      }),
    );
  });

  sendMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.sendMessage),
      switchMap(action => this.chatService.sendMessage(action.message, action.idDiscussion)),
      switchMap(() => this.store.select(selectActiveDiscussion).pipe(distinctUntilChanged())),
      map((discussion: ChatDiscussion | null) => ChatActions.majDateLecture({ discussion: discussion })),
    );
  });

  majDateLecture$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ChatActions.majDateLecture),
        switchMap(action => {
          return this.chatService.majLecture(action.discussion).pipe(tap(() => console.log('maj date lecture')));
        }),
      );
    },
    { dispatch: false },
  );

  loadVeterinaire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.loadVeterinaires),
      switchMap(() => {
        return this.chatService.loadVeto().pipe(
          map(action => {
            return ChatActions.loadVeterinairesSuccess({ listVeto: action });
          }),
        );
      }),
    );
  });

  onAttribution$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.attributionDiscussion),
      switchMap(action => {
        return this.chatService
          .onAttribution(action.idDiscussion, action.idVeto)
          .pipe(map(action => ChatActions.attributionDiscussionSuccess({ discussion: action })));
      }),
    );
  });

  sendChatDispo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.sendChatDispo),
      switchMap(action => {
        return this.chatService.sendChatDispo(action.dateDebut, action.dateFin, action.idStructure, action.idVeto).pipe(
          map(() => {
            return ChatActions.isVetoDispo({ idStructure: action.idStructure, idVeto: action.idVeto });
          }),
        );
      }),
    );
  });

  isVetoDispo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.isVetoDispo),
      switchMap(action => {
        return this.chatService.isVetoDispo(action.idStructure, action.idVeto).pipe(
          map(action => {
            return ChatActions.isVetoDispoSuccess({ isDispo: action });
          }),
        );
      }),
    );
  });

  isMilouDispo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.isMilouDispo),
      switchMap(action =>
        this.chatService.isMilouDispo(action.codeInsee).pipe(
          map(data => {
            if (data === null || data.indisponible) {
              return ChatActions.getPartenaireVad({
                codeInsee: action.codeInsee,
                indisponible: data === null ? '' : data.indisponible,
              });
            }

            return ChatActions.isDispoMilouSuccess({ rdvStructure: data });
          }),
        ),
      ),
    );
  });

  getPartenaireVad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.getPartenaireVad),
      switchMap(action =>
        this.chatService.partenaireMilou(action.codeInsee).pipe(
          map(listPartenaire =>
            ChatActions.getPartenaireVadSuccess({
              listPartenaire: listPartenaire,
              indisponible: action.indisponible,
            }),
          ),
        ),
      ),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly chatService: ChatService,
    private readonly store: Store,
  ) {}
}
