<div class="facturation-wrapper">
  <div class="title-devis-div" *ngIf="devisList.length && displayDevisBtn">
    <ion-button shape="round" color="secondary" (click)="appliquerDevis()">
      <ion-icon slot="start" name="document-outline"></ion-icon>
      <ion-label>Utiliser un devis</ion-label>
    </ion-button>
  </div>
  <ng-container *ngIf="facturations?.controls?.length; else noFactu">
    <form [formGroup]="form" *ngIf="acteList?.length">
      <div formArrayName="facturations">
        <ng-container *ngFor="let facturation of facturations.controls; let idxFacturation = index">
          <ion-card class="mobile-margin-padding facture-card" (click)="resetSearch()">
            <div class="delete ion-text-center">
              <ion-text color="blue600">
                <h2 *ngIf="!genererFactureParAnimal">{{ 'Facture n°' + (idxFacturation + 1) }}</h2>
                <h2 *ngIf="genererFactureParAnimal">{{ 'Facture pour ' + (animaux![idxFacturation].nom | titlecase) }}</h2>
              </ion-text>

              <ion-button
                color="suppr"
                size="small"
                (click)="deleteFacturation(idxFacturation)"
                *ngIf="
                  displayDeleteBtn &&
                  facturation.get('etat')!.value !== EtatFacturationEnum.PAYEE &&
                  facturation.get('etat')!.value !== EtatFacturationEnum.FINALISEE
                "
              >
                <ion-icon name="close-outline" slot="icon-only" color="wait" size="small"></ion-icon>
              </ion-button>
            </div>
            <div class="card-content">
              <div [formGroupName]="idxFacturation">
                <div formArrayName="actes" *ngIf="displayActes">
                  <ion-grid>
                    <div class="deplacement ion-text-center">
                      <ion-item lines="none">
                        <!-- <ion-input placeholder="Frais de déplacement inclus" disabled="true"></ion-input> -->
                        <ion-label color="grey900">
                          <b>Frais de déplacement inclus</b>
                          <ion-icon name="checkmark-circle" color="success"></ion-icon>
                        </ion-label>
                      </ion-item>
                    </div>
                    <ion-chip>
                      <ion-label>Actes</ion-label>
                    </ion-chip>

                    <div *ngFor="let acte of actes(idxFacturation).controls; let i = index; let last = last" class="actes-div">
                      <div [formGroupName]="i" class="prescription-line">
                        <ion-row>
                          <ion-col size="10" class="searchItem">
                            <ion-item lines="none">
                              <ion-label position="stacked">Nom</ion-label>
                              <ion-input
                                (click)="setActeSearchToIndex(idxFacturation, i)"
                                placeholder="Chercher un acte"
                                formControlName="acteSearch"
                                [clearInput]="true"
                                (ionInput)="searchActe($event, idxFacturation, i)"
                                type="text"
                                (keyup.enter)="addActe(idxFacturation)"
                              ></ion-input>
                            </ion-item>
                            <div
                              class="scrollable"
                              [hidden]="
                                currentFacturationForActeSearch !== idxFacturation ||
                                currentIndexForActeSearch !== i ||
                                !actesFiltres ||
                                actesFiltres.length <= 0 ||
                                !!acte.get('acte')!.value
                              "
                            >
                              <ion-list class="ion-padding search-list">
                                <ion-item
                                  *ngFor="let a of actesFiltres; let last = last"
                                  (click)="selectActe(a, idxFacturation, i)"
                                  [style.cursor]="'pointer'"
                                  [lines]="last ? 'none' : ''"
                                >
                                  <ion-label class="ion-text-wrap search-label">
                                    <h3>{{ a.libelle }}</h3>
                                    <p>Tarif: {{ a.tarif }} €{{ a.commentaire ? ' (' + a.commentaire + ')' : '' }}</p>
                                  </ion-label>
                                </ion-item>
                              </ion-list>

                              <ion-select hidden formControlName="acte"></ion-select>
                            </div>
                          </ion-col>
                          <ion-col size="2">
                            <ion-item lines="none">
                              <ion-label position="stacked">Qté</ion-label>
                              <french-number-input formControlName="quantity" (keyup.enter)="addActe(idxFacturation)"></french-number-input>
                            </ion-item>
                          </ion-col>
                          <ion-col size="3">
                            <ion-item lines="none">
                              <ion-label position="stacked">HT</ion-label>
                              <french-number-input [disabled]="true" formControlName="priceHT"></french-number-input>
                            </ion-item>
                          </ion-col>
                          <ion-col size="3">
                            <ion-item lines="none">
                              <ion-label position="stacked">% Remise</ion-label>
                              <french-number-input
                                formControlName="remise"
                                [disabled]="acte.get('priceTTC')!.value < 0"
                                (keyup.enter)="addActe(idxFacturation)"
                              ></french-number-input>

                              <ion-note slot="error">max. 20%</ion-note>
                            </ion-item>
                          </ion-col>
                          <ion-col size="3">
                            <ion-item lines="none">
                              <ion-label position="stacked">% TVA</ion-label>
                              <french-number-input formControlName="tva" [disabled]="true"></french-number-input>
                            </ion-item>
                          </ion-col>
                          <ion-col size="3">
                            <ion-item lines="none">
                              <ion-label position="stacked">TTC</ion-label>
                              <french-number-input formControlName="priceTTC" (keyup.enter)="addActe(idxFacturation)"></french-number-input>

                              <ion-note slot="error"
                                >{{
                                  acte.get('acte')!.value?.tarifMinHT
                                    ? tarifMinMax(
                                        acte.get('acte')!.value?.tarifMinHT * acte.get('quantity')!.value,
                                        acte.get('acte')!.value?.tarifMaxHT * acte.get('quantity')!.value
                                      )
                                    : 'Tarif à définir'
                                }}
                              </ion-note>
                            </ion-item>
                          </ion-col>

                          <ion-col
                            size-sm="4"
                            offset-sm="8"
                            size-xl="3"
                            offset-xl="9"
                            size-xs="6"
                            offset-xs="6"
                            *ngIf="
                              facturation.get('etat')!.value !== EtatFacturationEnum.PAYEE && facturation.get('etat')!.value !== EtatFacturationEnum.FINALISEE
                            "
                          >
                            <div class="mobile-delete-btn">
                              <ion-button fill="clear" color="danger" size="small" (click)="deleteActe(idxFacturation, i)">
                                <ion-icon name="close-outline" slot="start" color="danger" size="small"></ion-icon>
                                <ion-label color="danger">Supprimer</ion-label>
                              </ion-button>
                            </div>
                          </ion-col>
                          <div class="divider" *ngIf="!last"></div>
                        </ion-row>
                        <ion-row *ngIf="acte.invalid"><span></span></ion-row>
                      </div>
                    </div>
                    <ion-text class="ion-text-center" *ngIf="!actes(idxFacturation).length" color="grey600">
                      <p>Aucun acte</p>
                    </ion-text>
                  </ion-grid>
                  <ion-button
                    color="blue600"
                    expand="block"
                    class="add-btn mobile"
                    fill="clear"
                    slot="start"
                    (click)="addActe(idxFacturation)"
                    *ngIf="facturation.get('etat')!.value !== EtatFacturationEnum.PAYEE && facturation.get('etat')!.value !== EtatFacturationEnum.FINALISEE"
                  >
                    <ion-icon slot="start" name="add-circle-outline"></ion-icon>
                    <span> <ion-label slot="start">Ajouter un acte</ion-label></span>
                  </ion-button>

                  <ion-button
                    color="blue600"
                    class="add-btn-desktop desktop"
                    fill="clear"
                    slot="start"
                    (click)="addActe(idxFacturation)"
                    *ngIf="facturation.get('etat')!.value !== EtatFacturationEnum.PAYEE && facturation.get('etat')!.value !== EtatFacturationEnum.FINALISEE"
                  >
                    <ion-icon slot="start" name="add-circle-outline"></ion-icon>
                    <span> <ion-label slot="start">Ajouter un acte</ion-label></span>
                  </ion-button>
                </div>
                <div class="border-between"></div>
                <div formArrayName="produits" class="produits-div">
                  <ion-grid>
                    <ion-chip>
                      <ion-label>Produits</ion-label>
                    </ion-chip>
                    <ng-container *ngFor="let p of produits(idxFacturation).controls; let i = index; let last = last">
                      <div [formGroupName]="i" class="prescription-line">
                        <ion-row class="produit">
                          <ng-container *ngIf="!mobile">
                            <ion-col size="10" class="searchItem">
                              <ion-item lines="none">
                                <ion-label position="stacked" class="ion-text-wrap produit-legend">Nom</ion-label>

                                <ion-input
                                  placeholder="Chercher un produit"
                                  formControlName="produitSearch"
                                  [clearInput]="true"
                                  (ionInput)="searchProduit($event, idxFacturation, i)"
                                  (keyup.enter)="addProduit(idxFacturation)"
                                  type="text"
                                ></ion-input>
                              </ion-item>
                              <div
                                color="blue100"
                                class="scrollable"
                                [hidden]="
                                  currentFacturationForProduitSearch !== idxFacturation ||
                                  currentIndexForProduitSearch !== i ||
                                  produitsFiltres.length <= 0 ||
                                  !!produits(idxFacturation).at(i).get('produit')!.value
                                "
                              >
                                <ion-list class="ion-padding search-list">
                                  <ion-item
                                    *ngFor="let produit of produitsFiltres; let last = last"
                                    (click)="selectProduit(produit, idxFacturation, i)"
                                    [style.cursor]="'pointer'"
                                    [lines]="last ? 'none' : ''"
                                  >
                                    <ion-label class="ion-text-wrap search-label">
                                      <h3>{{ produit.nom }}</h3>
                                      <p *ngIf="produit.packaging">{{ produit.packaging }}</p>
                                    </ion-label>
                                  </ion-item>
                                </ion-list>

                                <ion-select hidden formControlName="produit"></ion-select>
                              </div>
                            </ion-col>
                            <ion-col size="2">
                              <ion-item lines="none">
                                <ion-label position="stacked" class="ion-text-wrap quantite" [class]="p.get('produit')!.value?.unite ? 'with-unit' : ''">
                                  <span
                                    >Qté
                                    {{ p.get('produit')!.value?.unite ? ' (' + (p.get('produit')!.value.unite | lowercase) + ')' : '' }}
                                  </span>
                                </ion-label>
                                <french-number-input formControlName="quantity" (keyup.enter)="addProduit(idxFacturation)"></french-number-input>
                              </ion-item>
                            </ion-col>
                            <ion-col
                              size="12"
                              class="ion-text-center"
                              *ngIf="p.enabled && p.get('produit')!.value?.unite && p.get('produit')!.value?.uvc > p.get('produit')!.value?.uva"
                            >
                              <ion-label class="text-more-visible">Attention, ce produit se facture par {{ p.get('produit')!.value?.unite }} </ion-label>
                            </ion-col>
                          </ng-container>
                          <ng-container *ngIf="mobile">
                            <ion-col size="12" class="searchItem">
                              <ion-item lines="none">
                                <ion-label position="stacked" class="ion-text-wrap produit-legend">Nom</ion-label>

                                <ion-input
                                  placeholder="Chercher un produit"
                                  formControlName="produitSearch"
                                  [clearInput]="true"
                                  (ionInput)="searchProduit($event, idxFacturation, i)"
                                  (keyup.enter)="addProduit(idxFacturation)"
                                  type="text"
                                ></ion-input>
                              </ion-item>
                              <div
                                color="blue100"
                                class="scrollable"
                                [hidden]="
                                  currentFacturationForProduitSearch !== idxFacturation ||
                                  currentIndexForProduitSearch !== i ||
                                  produitsFiltres.length <= 0 ||
                                  !!produits(idxFacturation).at(i).get('produit')!.value
                                "
                              >
                                <ion-list class="ion-padding">
                                  <ion-item
                                    *ngFor="let produit of produitsFiltres; let last = last"
                                    (click)="selectProduit(produit, idxFacturation, i)"
                                    [style.cursor]="'pointer'"
                                    [lines]="last ? 'none' : ''"
                                  >
                                    <ion-label class="ion-text-wrap">
                                      <h3>{{ produit.nom }}</h3>
                                      <p *ngIf="produit.packaging">{{ produit.packaging }}</p>
                                    </ion-label>
                                  </ion-item>
                                </ion-list>

                                <ion-select hidden formControlName="produit"></ion-select>
                              </div>
                            </ion-col>
                            <ion-col size="12">
                              <ion-item lines="none">
                                <ion-label position="stacked" class="ion-text-wrap quantite" [class]="p.get('produit')!.value?.unite ? 'with-unit' : ''">
                                  <span
                                    >Quantité
                                    {{ p.get('produit')!.value?.unite ? ' (par ' + (p.get('produit')!.value.unite | lowercase) + ')' : '' }}
                                  </span>
                                </ion-label>
                                <french-number-input formControlName="quantity" (keyup.enter)="addProduit(idxFacturation)"></french-number-input>
                              </ion-item>
                              <div class="ion-text-center">
                                <ion-label
                                  *ngIf="p.enabled && p.get('produit')!.value?.unite && p.get('produit')!.value?.uvc > p.get('produit')!.value?.uva"
                                  class="text-more-visible"
                                  >Attention, ce produit se facture par {{ p.get('produit')!.value?.unite }}
                                </ion-label>
                              </div>
                            </ion-col>
                          </ng-container>
                          <ion-col size="3">
                            <ion-item lines="none">
                              <ion-label position="stacked">HT unitaire</ion-label>
                              <french-number-input formControlName="priceHT" [disabled]="true"></french-number-input>
                            </ion-item>
                          </ion-col>
                          <ion-col size="3">
                            <ion-item lines="none">
                              <ion-label position="stacked">% Remise</ion-label>
                              <french-number-input
                                formControlName="remise"
                                [disabled]="p.get('priceTTC')!.value < 0"
                                [min]="0"
                                [max]="20"
                                (keyup.enter)="addProduit(idxFacturation)"
                              ></french-number-input>
                            </ion-item>
                          </ion-col>
                          <ion-col size="3">
                            <ion-item lines="none">
                              <ion-label position="stacked">% TVA</ion-label>
                              <french-number-input formControlName="tva" [disabled]="true"></french-number-input>
                            </ion-item>
                          </ion-col>
                          <ion-col size="3">
                            <ion-item lines="none">
                              <ion-label position="stacked">Total TTC</ion-label>
                              <french-number-input formControlName="priceTTC" (keyup.enter)="addProduit(idxFacturation)"></french-number-input>

                              <ion-note slot="error"
                                >{{
                                  p.get('produit')!.value?.tarifTotalMinHT
                                    ? 'Minimum ' +
                                      (+(
                                        p.get('produit')!.value.tarifTotalMinHT *
                                        ((100 + (p.get('produit')!.value.tva ? p.get('produit')!.value.tva : 20)) / 100)
                                      ).toFixed(2) | frenchNumber) +
                                      '€'
                                    : ''
                                }}
                              </ion-note>
                              <ion-note color="danger">
                                {{ isMinPriceSelected(p) ? 'Tarif minimum' : '' }}
                              </ion-note>
                            </ion-item>
                          </ion-col>

                          <ion-col
                            size-sm="4"
                            offset-sm="8"
                            size-xl="3"
                            offset-xl="9"
                            size-xs="6"
                            offset-xs="6"
                            *ngIf="
                              facturation.get('etat')!.value !== EtatFacturationEnum.PAYEE && facturation.get('etat')!.value !== EtatFacturationEnum.FINALISEE
                            "
                          >
                            <div class="mobile-delete-btn">
                              <ion-button fill="clear" color="danger" size="small" (click)="deleteProduit(idxFacturation, i)">
                                <ion-icon name="close-outline" slot="start" color="danger" size="small"></ion-icon>
                                <ion-label color="danger">Supprimer</ion-label>
                              </ion-button>
                            </div>
                          </ion-col>
                          <div class="divider" *ngIf="!last"></div>
                        </ion-row>
                      </div>
                    </ng-container>
                    <ion-text class="ion-text-center" *ngIf="!produits(idxFacturation).length" color="grey600">
                      <p>Aucun produit</p>
                    </ion-text>
                  </ion-grid>
                  <ion-button
                    expand="block"
                    color="blue600"
                    class="add-btn mobile"
                    fill="clear"
                    slot="start"
                    (click)="addProduit(idxFacturation)"
                    *ngIf="facturation.get('etat')!.value !== EtatFacturationEnum.PAYEE && facturation.get('etat')!.value !== EtatFacturationEnum.FINALISEE"
                  >
                    <ion-icon slot="start" name="add-circle-outline"></ion-icon>
                    <span> <ion-label slot="start">Ajouter un produit</ion-label></span>
                  </ion-button>
                  <ion-button
                    expand="block"
                    color="blue600"
                    class="add-btn-desktop desktop"
                    fill="clear"
                    slot="start"
                    (click)="addProduit(idxFacturation)"
                    *ngIf="facturation.get('etat')!.value !== EtatFacturationEnum.PAYEE && facturation.get('etat')!.value !== EtatFacturationEnum.FINALISEE"
                  >
                    <ion-icon slot="start" name="add-circle-outline"></ion-icon>
                    <span> <ion-label slot="start">Ajouter un produit</ion-label></span>
                  </ion-button>
                </div>
              </div>
            </div>

            <ion-row class="total-price">
              <ion-col>
                <ion-text class="ion-text-center" color="grey900">
                  <h3>
                    Total : <b>{{ facturation.get('total')!.value | number: '1.2-2' }}€</b>
                  </h3>
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="remise" *ngIf="remise">
              <ion-col>
                <ion-label>{{ remise }}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <div class="save-div" *ngIf="displaySaveBtns">
                  <ion-button
                    *ngIf="
                      facturationEditable &&
                      facturation.get('etat')!.value !== EtatFacturationEnum.PAYEE &&
                      facturation.get('etat')!.value !== EtatFacturationEnum.FINALISEE
                    "
                    color="secondary"
                    class="secondary-btn"
                    expand="block"
                    [disabled]="facturation.invalid || facturation.get('etat')!.value !== EtatFacturationEnum.EN_COURS"
                    (click)="saveFacturation(idxFacturation)"
                  >
                    <ion-label>Enregistrer</ion-label>
                  </ion-button>
                  <ion-button
                    *ngIf="
                      finalisable &&
                      facturation.get('etat')!.value !== EtatFacturationEnum.PAYEE &&
                      facturation.get('etat')!.value !== EtatFacturationEnum.FINALISEE
                    "
                    color="secondary"
                    class="secondary-btn"
                    expand="block"
                    [disabled]="facturation.invalid || facturation.get('etat')!.value !== EtatFacturationEnum.TERMINEE"
                    (click)="finaliserFacturation(idxFacturation)"
                  >
                    <ion-label>Finaliser</ion-label>
                  </ion-button>
                  <ion-button
                    color="secondary"
                    class="facture-non-soldee-btn secondary-btn"
                    expand="block"
                    [disabled]="
                      !facturation.get('id')!.value || facturation.get('etat')!.value === EtatFacturationEnum.EN_COURS || !facturation.get('idFacture')!.value
                    "
                    (click)="envoyerFactureParMail(idxFacturation)"
                  >
                    <ion-label
                      >{{ facturation.get('etat')!.value === EtatFacturationEnum.PAYEE ? 'Envoyer la facture' : 'Envoyer la facture non soldée' }}
                    </ion-label>
                  </ion-button>
                </div>
                <div class="error-div">
                  <ion-item class="ion-text-center" *ngIf="facturation.invalid && facturation.touched" color="warning" lines="none">
                    <ion-label class="ion-text-wrap">Attention, des informations de facturation sont manquantes ou invalides. </ion-label>
                    <ion-icon name="warning-outline" slot="end"></ion-icon>
                  </ion-item>
                </div>
              </ion-col>
            </ion-row>
            <br />
          </ion-card>
        </ng-container>

        <div class="add-facture" *ngIf="facturationEditable && displayAddBtn">
          <ion-button expand="block" (click)="addFacturation()" color="blue600" class="add-btn" fill="clear" slot="start">
            <ion-icon slot="start" name="add-circle-outline"></ion-icon>
            <span> <ion-label slot="start">Ajouter une facture</ion-label></span>
          </ion-button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-template #noFactu>
    <ion-text class="ion-text-center" color="grey600">
      <p>Aucune facturation</p>
    </ion-text>
  </ng-template>
</div>
