import { Pipe, PipeTransform } from '@angular/core';
import { CommandeStatut } from 'app/models/stock.model';

@Pipe({
  name: 'cmdStatut',
  pure: true,
})
export class CmdStatutPipe implements PipeTransform {
  constructor() {}

  transform(value: CommandeStatut | string): string {
    switch (value) {
      case CommandeStatut.ANNULEE:
        return 'Annulée';
      case CommandeStatut.CREEE:
        return 'Créée';
      case CommandeStatut.ENVOYEE:
        return 'Envoyée';
      case CommandeStatut.EN_ROUTE:
        return 'En route';
      case CommandeStatut.RECUE_PARTIELLEMENT:
        return 'Reçue partiellement';
      case CommandeStatut.RECUE_TOTAL:
        return 'Reçue total';
      case CommandeStatut.REFUSEE:
        return 'Refusée';
      case CommandeStatut.VALIDEE:
        return 'Validée';
      case CommandeStatut.LIVREE:
        return 'Livrée';
      default:
        return '-';
    }
  }
}
