import { Component, Input } from '@angular/core';
import { Animal } from '../../../../../models/animal.model';
import { AlertController, ModalController } from '@ionic/angular';
import { Devis, EtatDevisEnum } from '../../../../../models/rdv.model';
import { DevisService } from '../../../../rdv/wizard/services/devis.service';

@Component({
  selector: 'choix-devis',
  templateUrl: 'choix-devis.page.html',
  styleUrls: ['choix-devis.page.scss'],
})
export class ChoixDevisPage {
  @Input() devisList!: Devis[];
  @Input() animaux!: Animal[]; // animaux rdv

  animalNameByIdMap?: Map<string, string>;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly devisService: DevisService,
    private readonly alertCtrl: AlertController,
  ) {}

  ionViewWillEnter() {
    this.animalNameByIdMap = new Map<string, string>(this.animaux.map(a => [a.id!, a.nom]));
  }

  selectedAnimaux: Set<Animal> = new Set<Animal>();

  selectDevis(devis: Devis) {
    this.modalCtrl.dismiss(devis);
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  deleteDevis(devis: Devis) {
    const alert = this.alertCtrl.create({
      message: `Voulez-vous vraiment supprimer ce devis ?`,
      buttons: [
        {
          role: 'confirm',
          text: `Oui`,
          handler: () =>
            this.devisService.deleteDevis(devis.id!).subscribe(() => {
              this.devisList = this.devisList.filter(d => d.id !== devis.id!);
            }),
        },
        {
          role: 'cancel',
          text: `Non`,
          handler: () => {},
        },
      ],
    });
    alert.then(a => a.present());
  }

  protected readonly EtatDevisEnum = EtatDevisEnum;
}
