<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar color="blue100">
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="backTarif()">
        <ion-icon slot="icon-only" name="close-circle-outline" color="blue900"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="blue100" class="tarif-actes">
  <div id="main-content">
    <ion-grid fixed>
      <ion-row>
        <ion-col>
          <div class="welcome">
            <div class="center">
              <div class="with-sidebar-inverted">
                <div class="">
                  <h2>Liste des actes</h2>
                </div>
                <div class="line"></div>
                <!-- <ion-button class="reset" fill="clear" color="grey900" (click)="resetFilter()">
                  <ion-icon slot="icon-only" name="refresh"></ion-icon>
                </ion-button> -->
              </div>
            </div>
          </div>
          <div class="btn-list" *ngIf="user?.role === UserRole.ROLE_ADMIN">
            <ion-chip (click)="createActe()">
              <ion-label>Créer un acte</ion-label>
              <ion-icon name="add-circle-outline"></ion-icon>
            </ion-chip>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6">
          <ion-item lines="none" color="blue100" class="select-categorie">
            <!-- <ion-label position="stacked">Catégorie</ion-label> -->
            <ion-select placeholder="Catégorie" name="categorie" [(ngModel)]="selectedCategory" interface="popover" mode="md">
              <ion-select-option [value]="null">Toutes</ion-select-option>
              <ion-select-option *ngFor="let categorie of categories" [value]="categorie" class="ion-text-wrap"
                >{{ categorie.libelle | titlecase }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col size="6">
          <ion-item lines="none" color="blue100" class="select-structure">
            <!-- <ion-label position="stacked">Catégorie</ion-label> -->
            <ion-select placeholder="Structure" name="structure" [(ngModel)]="selectedStructure" interface="popover" mode="md">
              <ion-select-option [value]="null">Toutes</ion-select-option>
              <ion-select-option *ngFor="let structure of structures" [value]="structure" class="ion-text-wrap"
                >{{ structure.nomStructure | titlecase }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="desktop">
        <ion-col size="6">
          <div class="search-acte">
            <ion-item lines="none" color="blue100">
              <ion-searchbar placeholder="Chercher un acte" name="search" (ionInput)="searchActes($event)" mode="md"></ion-searchbar>
            </ion-item>
          </div>
        </ion-col>
        <ion-col size="6">
          <ion-item lines="none" color="blue100" shape="round" class="ion-justify-content-start">
            <ion-chip class="active-toggle">
              <ion-label class="ion-text-wrap" color="grey900">Afficher les actes toutes structures :</ion-label>
              <ion-checkbox [(ngModel)]="this.showActesToutesStructures" mode="md"></ion-checkbox>
            </ion-chip>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="mobile">
        <ion-col size="12">
          <div class="search-acte">
            <ion-item lines="none" color="blue100">
              <ion-searchbar placeholder="Chercher un acte" name="search" (ionInput)="searchActes($event)" mode="md"></ion-searchbar>
            </ion-item>
          </div>
        </ion-col>
        <ion-col size="12">
          <ion-item lines="none" color="blue100" shape="round" class="ion-justify-content-start">
            <ion-chip class="active-toggle">
              <ion-label class="ion-text-wrap" color="grey900">Afficher les actes toutes structures</ion-label>
              <ion-checkbox [(ngModel)]="this.showActesToutesStructures" mode="md"></ion-checkbox>
            </ion-chip>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ng-container *ngFor="let acte of actes | orderBy: 'libelle'">
            <ng-container
              *ngIf="
                filterValue(acte.libelle) &&
                (!selectedCategory || selectedCategory.code === acte.categorie) &&
                (user?.role !== UserRole.ROLE_VETERINAIRE ||
                  (!acte.idStructure && showActesToutesStructures) ||
                  (veto && [veto.structureVeterinaire.id, veto.structureVeterinaireAlternative?.id].includes(acte.idStructure))) &&
                (!selectedStructure || (!acte.idStructure && showActesToutesStructures) || selectedStructure.id === acte.idStructure)
              "
            >
              <ion-item color="blue200" lines="none" detail (click)="openDetail(acte)" class="acte-item">
                <ion-button slot="start" color="white" (click)="openEdit(acte); $event.stopPropagation()" *ngIf="user?.role === UserRole.ROLE_ADMIN">
                  <ion-icon slot="icon-only" name="create-outline" color="blue600"></ion-icon>
                </ion-button>
                <ion-label color="grey900" class="ion-text-wrap">
                  <h3>
                    <b>{{ acte.libelle }}</b>
                  </h3>
                  <p>{{ acte.tarif }}€ TTC</p>
                  <p *ngIf="!selectedCategory">{{ acte.categorie | titlecase }}</p>
                  <p *ngIf="!selectedStructure || !acte.idStructure">{{ getStructureActe(acte) }}</p>
                </ion-label>
              </ion-item>
            </ng-container>
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
