import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { selectLoadingProduits } from '@core/store/selector/admin.selector';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, mergeMap, Observable, skipWhile, startWith, Subject, takeUntil } from 'rxjs';
import { Produit, SearchProduitRequest } from 'app/models/produit.model';
import { AdminService } from '../../../services/admin.service';
import { Page } from '../../../../../models/page.model';
import { Action, Context, TypeLog } from '../../../../../models/log.model';
import { LogService } from '@core/services/log/log.service';
import { Browser } from 'leaflet';
import { NavController } from '@ionic/angular';
import { User, UserRole } from '../../../../../models/user.model';
import { selectUser } from '@core/store/selector/session.selectors';
import { tap } from 'rxjs/operators';
import mobile = Browser.mobile;

@Component({
  selector: 'tarif-produits-page',
  templateUrl: 'tarif-produits.page.html',
  styleUrls: ['tarif-produits.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TarifProduitsPage implements OnInit, OnDestroy, AfterViewInit {
  loadingProduit$: Observable<boolean | undefined> = this.store.select(selectLoadingProduits).pipe(distinctUntilChanged());
  produitsPages?: Page<Produit>[];
  allProduits: Produit[] = [];
  private DEFAULT_VIEWPORT_HEIGHT = 40;
  viewportHeight: string = this.DEFAULT_VIEWPORT_HEIGHT + 'rem';
  viewportOverflow: string = 'auto';
  searchRequest: SearchProduitRequest = { page: 0, size: 50, offset: 0, visible: true };
  filterChangeSubject$: Subject<SearchProduitRequest> = new Subject();
  destroy$: Subject<void> = new Subject();
  nbTotalPage?: number;
  currentPage: number = 0;

  currentPageReqArr = new Int32Array([0, 0, 0]); // page, offset, isLoading
  protected readonly mobile = mobile;
  user?: User;

  constructor(
    private readonly store: Store,
    private readonly adminService: AdminService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly logService: LogService,
    private readonly navCtrl: NavController,
  ) {}

  trackById(obj: any) {
    return obj?.id;
  }

  ngAfterViewInit() {
    this.viewportHeight = window.innerHeight - (mobile ? 325 : 290 + 56) + 'px';
  }

  ngOnInit() {
    this.logService.log(Context.ADMIN_TARIF_PRODUITS, Action.CONSULTATION_LIST, TypeLog.INFO, {});
    this.store
      .select(selectUser)
      .pipe(
        skipWhile(user => user === null),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        tap(user => {
          this.user = user;
        }),
      )
      .subscribe();
    this.filterChangeSubject$
      .pipe(
        startWith(this.searchRequest),
        takeUntil(this.destroy$),
        mergeMap(filter => this.adminService.searchProduits(filter), 1),
      )
      .subscribe(produits => {
        if (!this.produitsPages?.length) {
          this.produitsPages = [produits];
        } else if (this.produitsPages.map(p => p.pageNumber).includes(produits.pageNumber)) {
          this.produitsPages = this.produitsPages.map(p => {
            if (p.pageNumber === produits.pageNumber) {
              return produits;
            } else {
              return p;
            }
          });
        } else {
          this.produitsPages = [...this.produitsPages, produits];
        }
        this.allProduits = this.produitsPages!.flatMap(p => p.elements);

        this.currentPage = produits.pageNumber;
        this.nbTotalPage = produits.nbTotalPage;
        this.setViewportHeight();
        Atomics.store(this.currentPageReqArr, 2, 0); //isloading

        this.changeDetectorRef.markForCheck();
      });
  }

  private setViewportHeight() {
    const length = this.allProduits.length * 4;
    if (length < this.DEFAULT_VIEWPORT_HEIGHT) {
      this.viewportHeight = length + 'rem';
      this.viewportOverflow = 'hidden';
    } else {
      //56 px est la taille du header en general
      this.viewportHeight = window.innerHeight - (mobile ? 325 : 290 + 56) + 'px';
      this.viewportOverflow = 'auto';
    }
  }

  searchProduits(ev: any) {
    const value = ev.target!.value;
    if (value?.length > 2) {
      this.resetSearchRequest();
      this.searchRequest.request = value;
      this.filterChangeSubject$.next(this.searchRequest);
    } else {
      if (this.searchRequest.request) {
        this.resetSearchRequest();

        this.searchRequest.request = undefined;
        this.filterChangeSubject$.next(this.searchRequest);
      }
    }
  }

  private resetSearchRequest() {
    this.currentPageReqArr = new Int32Array([0, 0, 0]); // page, offset, isLoading

    this.searchRequest.page = 0;
    this.searchRequest.offset = 0;
    this.produitsPages = [];
    this.allProduits = [];
  }

  toggleProduitsVisibles(checked: boolean) {
    this.resetSearchRequest();
    this.searchRequest.visible = checked ? checked : undefined;
    this.filterChangeSubject$.next(this.searchRequest);
  }

  toggleProduitsSansUvc(checked: boolean) {
    this.resetSearchRequest();

    this.searchRequest.sansUVC = checked ? checked : undefined;
    this.filterChangeSubject$.next(this.searchRequest);
  }

  toggleProduitsSansTarif(checked: boolean) {
    this.resetSearchRequest();

    this.searchRequest.sansTarif = checked ? checked : undefined;
    this.filterChangeSubject$.next(this.searchRequest);
  }

  openDetail(produit: Produit) {
    this.logService.log(Context.ADMIN_TARIF_PRODUITS, Action.CONSULTATION, TypeLog.INFO, produit);
    this.adminService.openDetailTarif(produit, 'produit', 'view', this.user!).then(ev => {
      if (ev.data) {
        this.filterChangeSubject$.next(this.searchRequest);
      }
    });
  }

  openEdit(produit: Produit) {
    this.logService.log(Context.ADMIN_TARIF_PRODUITS, Action.ASK_MODIFICATION, TypeLog.INFO, produit);
    this.adminService.openDetailTarif(produit, 'produit', 'edit', this.user!).then(ev => {
      if (ev.data) {
        this.filterChangeSubject$.next(this.searchRequest);
      }
    });
  }

  getNextPage(index: number) {
    if (
      this.allProduits.length &&
      index + 30 >= this.allProduits!.length - 1 &&
      (!this.nbTotalPage || Atomics.load(this.currentPageReqArr, 0) < this.nbTotalPage) &&
      Atomics.load(this.currentPageReqArr, 2) !== 1
    ) {
      Atomics.add(this.currentPageReqArr, 0, 1);
      Atomics.add(this.currentPageReqArr, 1, this.searchRequest.size!);
      Atomics.store(this.currentPageReqArr, 2, 1); //isloading

      this.filterChangeSubject$.next({ ...this.searchRequest, page: Atomics.load(this.currentPageReqArr, 0), offset: Atomics.load(this.currentPageReqArr, 1) });
    }
  }

  backTarif() {
    if (this.user?.role === UserRole.ROLE_VETERINAIRE) {
      this.navCtrl.navigateBack(['tarifs'], { queryParamsHandling: 'merge' });
    } else {
      this.navCtrl.navigateBack(['zone-admin', 'tarifs'], { queryParamsHandling: 'merge' });
    }
  }

  createProduit() {
    this.logService.log(Context.ADMIN_TARIF_ACTES, Action.ASK_CREATION, TypeLog.INFO, {});
    this.adminService.openDetailTarif({}, 'produit', 'edit', this.user!);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly UserRole = UserRole;
}
