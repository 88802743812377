import { ProduitPrescrit } from './produit.model';

export interface Ordonnance {
  id: string;
  numOrdonnance: string;
  etat: EtatOrdonnanceEnum;
  infosSupplementaires?: string;
  produitPrescrits: ProduitPrescrit[];
}

export enum EtatOrdonnanceEnum {
  EN_COURS = 'EN_COURS',
  TERMINEE = 'TERMINEE',
  ENVOYEE = 'ENVOYEE',
}
