<ion-header>
  <ion-toolbar color="blue200">
    <ion-title class="ion-text-wrap">
      <ion-label class="ion-text-wrap" color="grey900">{{ type === 'acte' ? detailProps.libelle : detailProps.nom }} </ion-label>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeModal()">
        <ion-label color="blue600">Fermer</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ng-container *ngIf="!editable">
      <ion-row>
        <ion-col *ngIf="type === 'acte'">
          <ng-container *ngIf="user?.role === UserRole.ROLE_ADMIN">
            <ion-item>
              <ion-label class="ion-text-wrap">
                <h3>Visible clients</h3>
                <p>{{ detailProps.visibleClient ? 'Oui' : 'Non' }}</p>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label class="ion-text-wrap">
                <h3>Visible vétos</h3>
                <p>{{ detailProps.visibleVeto ? 'Oui' : 'Non' }}</p>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label class="ion-text-wrap">
                <h3>Type de consultation</h3>
                <p>{{ detailProps.consultation ? 'Oui' : 'Non' }}</p>
              </ion-label>
            </ion-item>
          </ng-container>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Tarif TTC</h3>
              <p>{{ detailProps.tarif | defaultValue }}€</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Tarif Minimum HT</h3>
              <p>{{ detailProps.tarifMinHT !== null ? (detailProps.tarifMinHT | number: '1.0-2') : '-' }}€</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Tarif Minimum TTC</h3>
              <p>{{ detailProps.tarifMinHT !== null ? (detailProps.tarifMinHT * 1.2 | number: '1.0-2') : '-' }}€</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Tarif Maximum HT</h3>
              <p>{{ detailProps.tarifMaxHT !== null ? (detailProps.tarifMaxHT | number: '1.0-2') : '-' }}€</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Tarif Maximum TTC</h3>
              <p>{{ detailProps.tarifMaxHT !== null ? (detailProps.tarifMaxHT * 1.2 | number: '1.0-2') : '-' }}€</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Commentaire</h3>
              <p>{{ detailProps.commentaire | defaultValue }}</p>
            </ion-label>
          </ion-item>
          <ng-container *ngIf="user?.role === UserRole.ROLE_ADMIN">
            <ion-item>
              <ion-label class="ion-text-wrap">
                <h3>Durée rdv (mn)</h3>
                <p>{{ detailProps.dureeRdv | defaultValue }}</p>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label class="ion-text-wrap">
                <h3>Durée rdv supplémentaire par animal (mn)</h3>
                <p>{{ detailProps.dureeRdvSupplementaireParAnimal | defaultValue }}</p>
              </ion-label>
            </ion-item>
          </ng-container>
        </ion-col>
        <ion-col *ngIf="type === 'produit'">
          <ion-item *ngIf="user?.role === UserRole.ROLE_ADMIN">
            <ion-label class="ion-text-wrap">
              <h3>Visible</h3>
              <p>{{ detailProps.visible ? 'Oui' : 'Non' }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Médicament</h3>
              <p>{{ detailProps.medicament ? 'Oui' : 'Non' }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Vente hors RDV</h3>
              <p>{{ detailProps.vendableHorsRdv ? 'Oui' : 'Non' }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Sujet à déclaration</h3>
              <p>{{ detailProps.subjectToReporting ? 'Oui' : 'Non' }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Stupéfiant</h3>
              <p>{{ detailProps.stupefiant ? 'Oui' : 'Non' }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Injectable</h3>
              <p>{{ detailProps.injectable ? 'Oui' : 'Non' }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Froid</h3>
              <p>{{ detailProps.froid ? 'Oui' : 'Non' }}</p>
            </ion-label>
          </ion-item>
          <ion-item *ngIf="user?.role === UserRole.ROLE_ADMIN">
            <ion-label class="ion-text-wrap">
              <h3>Tarif d'achat HT</h3>
              <p>{{ detailProps.tarifAchatHT ? detailProps.tarifAchatHT + '€' : '-' }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Tarif de vente HT</h3>
              <p>{{ detailProps.tarifVenteHT ? detailProps.tarifVenteHT + '€' : '-' }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Tarif de vente TTC</h3>
              <p>{{ detailProps.tarifVenteHT ? +(detailProps.tarifVenteHT * 1.2).toFixed(2) + '€' : '-' }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Tarif total min HT</h3>
              <p>{{ detailProps.tarifTotalMinHT ? detailProps.tarifTotalMinHT + '€' : '-' }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Tarif total min TTC</h3>
              <p>{{ detailProps.tarifTotalMinHT ? +(detailProps.tarifTotalMinHT * 1.2).toFixed(2) + '€' : '-' }}</p>
            </ion-label>
          </ion-item>
          <ion-item *ngIf="user?.role === UserRole.ROLE_ADMIN">
            <ion-label class="ion-text-wrap">
              <h3>Coef achat/vente</h3>
              <p>{{ detailProps.coefAchatVente | defaultValue }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>UVC</h3>
              <p>{{ detailProps.uvc | defaultValue }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>UVA</h3>
              <p>{{ detailProps.uva | defaultValue }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Unité de vente</h3>
              <p>{{ detailProps.unite | defaultValue }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Unité d'achat</h3>
              <p>{{ detailProps.uniteAchat | defaultValue }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Nom Centravet</h3>
              <p>{{ detailProps.nomCentravet | defaultValue }}</p>
            </ion-label>
          </ion-item>

          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>GTIN</h3>
              <p>{{ detailProps.gtin | defaultValue }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Packaging</h3>
              <p>{{ detailProps.packaging | defaultValue }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Description</h3>
              <p>{{ detailProps.description | defaultValue }}</p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label class="ion-text-wrap">
              <h3>Posologie</h3>
              <p>{{ detailProps.posologie | defaultValue }}</p>
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
    </ng-container>
    <ng-container *ngIf="editable && editTarifForm">
      <form [formGroup]="editTarifForm" (ngSubmit)="editTarif()">
        <ion-grid>
          <ng-container *ngIf="type === 'acte'">
            <ion-row class="desktop">
              <ion-col size="4" class="ion-justify-content-start">
                <ion-item lines="none" fill="clear" shape="round">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Visible clients</ion-label>
                    <ion-checkbox formControlName="visibleClient"></ion-checkbox>
                  </ion-chip>
                </ion-item>
              </ion-col>
              <ion-col size="4" class="ion-justify-content-start">
                <ion-item lines="none" fill="clear" shape="round">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Visible vétos</ion-label>
                    <ion-checkbox formControlName="visibleVeto"></ion-checkbox>
                  </ion-chip>
                </ion-item>
              </ion-col>
              <ion-col size="4" class="ion-justify-content-start">
                <ion-item lines="none" fill="clear" shape="round">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Type de consultation</ion-label>
                    <ion-checkbox formControlName="consultation"></ion-checkbox>
                  </ion-chip>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="mobile">
              <ion-col size="6" class="ion-justify-content-start">
                <ion-chip>
                  <ion-label color="grey900" class="ion-text-wrap">Visible clients</ion-label>
                  <ion-checkbox formControlName="visibleClient"></ion-checkbox>
                </ion-chip>
              </ion-col>
              <ion-col size="6" class="ion-justify-content-start">
                <ion-chip>
                  <ion-label color="grey900" class="ion-text-wrap">Visible vétos</ion-label>
                  <ion-checkbox formControlName="visibleVeto"></ion-checkbox>
                </ion-chip>
              </ion-col>
            </ion-row>
            <ion-row class="mobile">
              <ion-col size="12" class="ion-justify-content-start">
                <ion-chip>
                  <ion-label color="grey900" class="ion-text-wrap">Type de consultation</ion-label>
                  <ion-checkbox formControlName="consultation"></ion-checkbox>
                </ion-chip>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Structure</ion-label>
                  <ion-select placeholder="Choisir une structure" formControlName="idStructure" interface="popover">
                    <ion-select-option [value]="null">Toutes</ion-select-option>
                    <ion-select-option *ngFor="let structure of structures" [value]="structure.id">
                      {{ structure.nomStructure | titlecase }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Catégorie</ion-label>
                  <ion-select placeholder="Choisir une catégorie" formControlName="categorie" interface="alert">
                    <ion-select-option *ngFor="let categorie of categories" [value]="categorie"> {{ categorie | titlecase }} </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Libellé</ion-label>
                  <ion-input formControlName="libelle"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Code</ion-label>
                  <ion-input formControlName="code"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Tarif de vente min (€ HT)</ion-label>
                  <ion-input formControlName="tarifMinHT" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Tarif de vente min (€ TTC)</ion-label>
                  <ion-input formControlName="tarifMinTTC" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Tarif de vente max (€ HT)</ion-label>
                  <ion-input formControlName="tarifMaxHT" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Tarif de vente max (€ TTC)</ion-label>
                  <ion-input formControlName="tarifMaxTTC" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Commentaire</ion-label>
                  <ion-textarea placeholder="Saisissez un commentaire" formControlName="commentaire"></ion-textarea>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row *ngIf="editTarifForm.value.categorie">
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Durée rdv (mn)</ion-label>
                  <ion-input formControlName="dureeRdv" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Durée rdv suppl. par animal (mn)</ion-label>
                  <ion-input formControlName="dureeRdvSupplementaireParAnimal" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </ng-container>
          <ng-container *ngIf="type === 'produit'">
            <div class="desktop">
              <ion-row class="checkbox-row">
                <ion-item lines="none" fill="clear" shape="round">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Visible</ion-label>
                    <ion-checkbox formControlName="visible"></ion-checkbox>
                  </ion-chip>
                </ion-item>
                <ion-item lines="none" fill="clear" shape="round">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Médicament</ion-label>
                    <ion-checkbox formControlName="medicament"></ion-checkbox>
                  </ion-chip>
                </ion-item>
                <ion-item lines="none" fill="clear" shape="round">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Vente hors RDV</ion-label>
                    <ion-checkbox formControlName="vendableHorsRdv"></ion-checkbox>
                  </ion-chip>
                </ion-item>
                <ion-item lines="none" fill="clear" shape="round">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Soumis à déclaration</ion-label>
                    <ion-checkbox formControlName="subjectToReporting"></ion-checkbox>
                  </ion-chip>
                </ion-item>
                <ion-item lines="none" fill="clear" shape="round">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Froid</ion-label>
                    <ion-checkbox formControlName="froid"></ion-checkbox>
                  </ion-chip>
                </ion-item>
                <ion-item lines="none" fill="clear" shape="round">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Injectable</ion-label>
                    <ion-checkbox formControlName="injectable"></ion-checkbox>
                  </ion-chip>
                </ion-item>
                <ion-item lines="none" fill="clear" shape="round">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Stupéfiant</ion-label>
                    <ion-checkbox formControlName="stupefiant"></ion-checkbox>
                  </ion-chip>
                </ion-item>
              </ion-row>
            </div>
            <div class="mobile">
              <ion-row class="checkbox-row">
                <ion-col size="6" class="ion-justify-content-start">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Visible</ion-label>
                    <ion-checkbox formControlName="visible"></ion-checkbox>
                  </ion-chip>
                </ion-col>
                <ion-col size="6" class="ion-justify-content-end">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Médicament</ion-label>
                    <ion-checkbox formControlName="medicament"></ion-checkbox>
                  </ion-chip>
                </ion-col>
              </ion-row>
              <ion-row class="checkbox-row">
                <ion-col size="12" class="ion-justify-content-start">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Vente hors RDV</ion-label>
                    <ion-checkbox formControlName="vendableHorsRdv"></ion-checkbox>
                  </ion-chip>
                </ion-col>
              </ion-row>
              <ion-row class="checkbox-row">
                <ion-col size="8" class="ion-justify-content-start">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Soumis à déclaration</ion-label>
                    <ion-checkbox formControlName="subjectToReporting"></ion-checkbox>
                  </ion-chip>
                </ion-col>
                <ion-col size="4" class="ion-justify-content-end">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Froid</ion-label>
                    <ion-checkbox formControlName="froid"></ion-checkbox>
                  </ion-chip>
                </ion-col>
              </ion-row>
              <ion-row class="checkbox-row">
                <ion-col size="6" class="ion-justify-content-start">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Injectable</ion-label>
                    <ion-checkbox formControlName="injectable"></ion-checkbox>
                  </ion-chip>
                </ion-col>
                <ion-col size="6" class="ion-justify-content-end">
                  <ion-chip>
                    <ion-label color="grey900" class="ion-text-wrap">Stupéfiant</ion-label>
                    <ion-checkbox formControlName="stupefiant"></ion-checkbox>
                  </ion-chip>
                </ion-col>
              </ion-row>
            </div>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Tarif de vente (€ HT)</ion-label>
                  <ion-input formControlName="tarif" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Tarif de vente (€ TTC)</ion-label>
                  <ion-input formControlName="tarifTTC" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Tarif total min (€ HT)</ion-label>
                  <ion-input formControlName="tarifTotalMinHT" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Tarif total min (€ TTC)</ion-label>
                  <ion-input formControlName="tarifTotalMinTTC" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">UVC</ion-label>
                  <ion-input formControlName="uvc" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>

              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Unité de vente</ion-label>
                  <ion-input formControlName="unite"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Coef</ion-label>
                  <ion-input formControlName="coef" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>

              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Tarif d'achat (€ HT)</ion-label>
                  <ion-input formControlName="tarifAchatHT" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">UVA</ion-label>
                  <ion-input formControlName="uva" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Unité d'achat</ion-label>
                  <ion-input formControlName="uniteAchat"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Nom</ion-label>
                  <ion-input formControlName="nom"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Nom Centravet</ion-label>
                  <ion-input formControlName="nomCentravet"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">GTIN</ion-label>
                  <ion-input formControlName="gtin"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">TVA</ion-label>
                  <ion-input formControlName="tva" type="number" min="0"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Packaging</ion-label>
                  <ion-input formControlName="packaging"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Code article</ion-label>
                  <ion-input formControlName="codeArticle"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="12">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Description</ion-label>
                  <ion-textarea formControlName="description"></ion-textarea>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <ion-item lines="none">
                  <ion-label position="stacked" class="ion-text-wrap">Posologie</ion-label>
                  <ion-textarea formControlName="posologie"></ion-textarea>
                </ion-item>
              </ion-col>
            </ion-row>
          </ng-container>
          <div class="ion-text-center validate-btn">
            <ion-button color="danger" *ngIf="editable" shape="round" (click)="edit()">
              <ion-label>Annuler les modifications</ion-label>
            </ion-button>
            <ion-button color="primary" type="submit" shape="round" [disabled]="editTarifForm.invalid">
              <ion-label>Valider les modifications</ion-label>
            </ion-button>
          </div>
        </ion-grid>
      </form>
    </ng-container>

    <ion-row>
      <ion-col>
        <div class="ion-text-center">
          <ion-button color="primary" (click)="edit()" shape="round" *ngIf="!editable && this.user?.role === UserRole.ROLE_ADMIN">
            <ion-label>Modifier</ion-label>
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
