import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { CompteRendu, FileCRTypeEnum } from '../../../../models/compte-rendu.model';
import { ModalController } from '@ionic/angular';
import { CompteRenduService } from '../../../rdv/wizard/services/compte-rendu.service';
import { User, UserRole } from '../../../../models/user.model';
import { Client, FindHorsRdvRequest, FindRdvRequest, HorsRdv, RdvDomicile, RdvState } from '../../../../models/rdv.model';
import { combineLatestWith } from 'rxjs';
import { StandaloneCrComponent } from '../standalone-cr/standalone-cr.component';
import { RdvDomicileService } from '@core/services/consultations/rdv-domicile.service';
import { DatePipe } from '@angular/common';
import { TooltipPosition } from '../tooltip/tooltip.model';
import { ExportDossierMedicalComponent } from '../../../fiche-client/components/export-dossier-medical/export-dossier-medical.component';
import { HorsRdvService } from '@core/services/consultations/hors-rdv.service';
import { RdvService } from '@core/services/consultations/rdv.service';
import { Veterinaire } from '../../../../models/admin.model';
import { VeterinaireService } from '../../../rdv/wizard/services/veterinaire.service';
import { UserService } from '@core/services/user/user.service';

@Component({
  selector: 'historiqueAnimal',
  templateUrl: './historique-animal.component.html',
  styleUrls: ['./historique-animal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoriqueAnimalComponent implements OnChanges {
  //Historique
  historique?: (CompteRendu | RdvDomicile | HorsRdv)[];
  @Input() mail!: string;
  @Input() client!: Client;
  @Input() idAnimal?: string;
  @Input() nomAnimal?: string;
  @Input() user!: User;
  @Input() idRdvFilter?: string;
  vetos: Veterinaire[] = [];

  lastVeto?: User;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly modalCtrl: ModalController,
    private readonly compteRenduService: CompteRenduService,
    private readonly userService: UserService,
    private readonly veterinaireService: VeterinaireService,
    private readonly rdvService: RdvDomicileService,
    private readonly horsRdvService: HorsRdvService,
    private readonly datePipe: DatePipe,
  ) {}

  ngOnChanges() {
    if (this.client) {
      if ([UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(this.user.role)) {
        this.veterinaireService
          .getVeto()
          .pipe(combineLatestWith(this.userService.findStructureIdByCodePostal(this.client.id)))
          .subscribe(vetosEtStructure => {
            this.vetos = vetosEtStructure[0].filter(
              v => v.structureVeterinaire.id === vetosEtStructure[1] || v.structureVeterinaireAlternative?.id === vetosEtStructure[1],
            );
          });
      } else if (this.user.role === UserRole.ROLE_VETERINAIRE) {
        this.veterinaireService.getProfilVeto().subscribe(v => (this.vetos = [v]));
      }
    }
    if (this.idAnimal) {
      this.compteRenduService
        .getStandaloneCRAnimal(this.idAnimal)
        .pipe(
          combineLatestWith(
            this.rdvService.findAllRdv(this.buildRequest(this.idAnimal)),
            this.horsRdvService.findAllRdv(this.buildHorsRdvRequest(this.idAnimal)),
          ),
        )
        .subscribe(crAndPrestas => {
          this.historique = this.compteRenduService.groupCrAndRdvs(crAndPrestas[0], crAndPrestas[1].elements, crAndPrestas[2].elements);
          this.changeDetectorRef.markForCheck();
          this.lastVeto = this.historique
            .filter(c => !this.isCompteRendu(c))
            .map(c => c as RdvDomicile)
            .filter(c => ![RdvState.ANNULEE, RdvState.RDV_ANNULE].includes(c.rdvStep))
            .map(rdv => rdv.consultantAssigne)
            .shift();
        });
    }
  }

  private buildRequest(idAnimal: string): FindRdvRequest {
    return {
      animalId: idAnimal,
      withPrestations: true,
      maxDate: this.datePipe.transform(new Date(), 'yyyy-MM-ddT23:59:59Z')!,
      notRdvStep: [RdvState.ABANDONNEE],
    };
  }

  protected readonly UserRole = UserRole;

  isCompteRendu(item: CompteRendu | RdvDomicile): item is CompteRendu {
    return (item as CompteRendu).dateCompteRendu !== undefined;
  }

  isRdvDomicile(item: CompteRendu | RdvDomicile | HorsRdv): item is RdvDomicile {
    return !this.isCompteRendu(item) && RdvService.isRdvDomicile(item);
  }

  isHorsRdv(item: CompteRendu | RdvDomicile | HorsRdv): item is HorsRdv {
    return !this.isCompteRendu(item) && RdvService.isHorsRdv(item);
  }

  async addComplement() {
    const modal = await this.modalCtrl.create({
      component: StandaloneCrComponent,
      backdropDismiss: false,
      cssClass: 'standalone-cr-modal-size',
      componentProps: {
        idAnimal: this.idAnimal,
        nomAnimal: this.nomAnimal,
        user: this.user,
      },
    });
    modal.onDidDismiss().then(data => {
      if (data.data) {
        this.historique?.unshift(data.data as CompteRendu);
        this.changeDetectorRef.markForCheck();
      }
    });
    await modal.present();
  }

  protected readonly position = TooltipPosition;
  protected readonly FileCRTypeEnum = FileCRTypeEnum;

  onDelete(cr: CompteRendu) {
    this.historique = this.historique!.filter(o => o.id !== cr.id);
  }

  private buildHorsRdvRequest(idAnimal: string): FindHorsRdvRequest {
    return {
      animalId: idAnimal,
      withPrestations: true,
      maxDate: this.datePipe.transform(new Date(), 'yyyy-MM-ddT23:59:59Z')!,
    };
  }

  async exportDossierMedical() {
    const modal = await this.modalCtrl.create({
      component: ExportDossierMedicalComponent,
      cssClass: 'export-dossier-medical-modal',
      componentProps: {
        title: 'Dossier médical',
        email: this.client.mail,
        historique: this.historique,
        idAnimal: this.idAnimal,
      },
    });

    await modal.present();
  }
}
