import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'french-number-input',
  template: ` <ion-input
    [value]="displayValue"
    (ionInput)="onInputChange($event)"
    [disabled]="disabled"
    [min]="min"
    [max]="max"
    [type]="type"
    [class]="hostClasses"
  ></ion-input>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FrenchNumberComponent),
      multi: true,
    },
  ],
})
export class FrenchNumberComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() min: number | null = null;
  @Input() max: number | null = null;
  @Input() type: string | null = null;
  hostClasses?: string;
  private innerValue: number | null = 0;
  displayValue: string | null = null;
  private observer?: MutationObserver;
  onChange: any = () => {};
  onTouched: any = () => {};
  control: NgControl | null = null;

  constructor(
    private readonly el: ElementRef,
    private readonly cd: ChangeDetectorRef,
    private readonly injector: Injector,
  ) {}

  ngOnInit() {
    // c'est un hack mais c'est le seul truc qui marche avec ionic
    // on regarde si les classes changent (pour ng-invalid etc, ajoutées par angular en asynchrone suite à la validation du formulaire)
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          this.updateHostClasses();
        }
      });
    });

    this.observer.observe(this.el.nativeElement, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }

  async ngAfterViewInit() {
    this.updateHostClasses();
    this.control = this.injector.get(NgControl, null);

    if (this.control) {
      this.control.control?.markAsTouched({ onlySelf: true });
      this.control.control?.updateValueAndValidity({ emitEvent: false });
    }
  }

  updateHostClasses() {
    const hostElem = this.el.nativeElement;
    // Assuming you're applying classes directly to the host element (e.g., <french-number-input class="some-class">)
    this.hostClasses = hostElem.className;
    this.cd.markForCheck();
  }

  @Input() set value(val: number | string | null) {
    if (!val) {
      this.innerValue = null;
      this.displayValue = '';
    } else if (typeof val === 'number') {
      this.writeValue(val);
      this.onChange(val);
    } else {
      this.writeValue(this.parseFrenchNumber(val));
      this.onChange(this.innerValue);
    }
  }

  get value(): number | null {
    return this.innerValue;
  }

  writeValue(value: any): void {
    this.innerValue = value;
    this.displayValue = this.formatToFrench(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onInputChange(event: any) {
    const value = event.target.value;
    this.innerValue = this.parseFrenchNumber(value);
    this.displayValue = this.innerValue === null ? null : isNaN(this.innerValue) ? value : this.formatToFrench(this.innerValue);
    this.onChange(this.innerValue);
  }

  formatToFrench(value: number | null): string | null {
    if (value || value === 0) {
      return new Intl.NumberFormat('fr-FR').format(value);
    } else {
      return null;
    }
  }

  parseFrenchNumber(frenchNumber: string | null): number | null {
    if (frenchNumber) {
      return parseFloat(frenchNumber.replace(/\s/g, '').replace(',', '.'));
    } else {
      return null;
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
