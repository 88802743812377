/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { Injectable } from '@angular/core';
import { isEmpty, isString } from 'lodash';
import { from, map, Observable, of, switchMap } from 'rxjs';

import { CacheConstants } from 'app/constants/cache.constants';
import { JwtToken } from 'app/models/jwt-token.model';
import { LoginData } from 'app/models/login-data.model';
import { HttpApiService } from '../http-api/http-api.service';
import { StorageService } from '../storage/storage.service';
import { User, UserDevice } from 'app/models/user.model';
import { getMessaging, getToken, isSupported, onMessage } from '@firebase/messaging';

import { environment } from '../../../../../environments/environment';
import { Store } from '@ngrx/store';
import { DiscussionActions } from '@core/store/actions/discussion.actions';
import { notifAction } from '@core/store/actions/notifications.action';
import { Howl } from 'howler';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private readonly httpApiService: HttpApiService,
    private readonly storageService: StorageService,
    private readonly store: Store,
  ) {}

  login(login: LoginData): Observable<JwtToken> {
    return this.httpApiService.post<JwtToken>('api/user/login', login);
  }

  getCredentialsFromStorage(): JwtToken | null {
    const credentialsAsString = this.storageService.getFromStorage(CacheConstants.TOKEN_KEY);

    return !isEmpty(credentialsAsString) && isString(credentialsAsString) ? JSON.parse(credentialsAsString) : undefined;
  }

  setCredentialsInStorage(credetials: JwtToken) {
    this.storageService.setToStorage(CacheConstants.TOKEN_KEY, JSON.stringify(credetials));
  }

  clearCredentials() {
    this.storageService.removeFromStorage(CacheConstants.TOKEN_KEY);
  }

  getDeviceId(user: User): Observable<string> {
    const deviceId$ = this.requestPermission(user);

    this.listen();

    return deviceId$;
  }

  deleteDevice(id: string): Observable<void> {
    return this.httpApiService.delete<void>(`api/chat/userDevice/${id}`);
  }

  requestPermission(user: User): Observable<string> {
    return from(isSupported()).pipe(
      switchMap(supported => {
        if (supported) {
          const messaging = getMessaging();

          return from(getToken(messaging, { vapidKey: environment.vapidKey })).pipe(
            switchMap(token =>
              this.httpApiService.post<UserDevice>('api/chat/userDevice', { deviceId: token, utilisateur: { id: user.id }, typeDevice: 'WEBAPP' }),
            ),
            map(userDevice => userDevice.id),
          );
        } else {
          return of('');
        }
      }),
      catchError(() => of('')),
    );
  }

  listen() {
    isSupported().then(supported => {
      if (supported) {
        const messaging = getMessaging();

        onMessage(messaging, payload => {
          this.store.dispatch(DiscussionActions.loadDiscussion());
          this.store.dispatch(DiscussionActions.loadWaitingDiscussion());
          this.store.dispatch(DiscussionActions.loadAllActiveDiscussion());
          console.log('Message received. ', payload);
          if (payload.notification) {
            const sound = new Howl({
              src: ['assets/notification.mp3'],
            });
            sound.play();
            this.store.dispatch(notifAction.notifReceived({ data: payload.data, notification: payload.notification }));
          }
        });
      }
    });
  }
}
