export enum ModePaiementEnum {
  CHEQUE = 'CHEQUE',
  ESPECE = 'ESPECE',
  CARD = 'CARD',
  DR_MILOU = 'DR_MILOU',
  VIREMENT = 'VIREMENT',
  ALMA = 'ALMA',
}

export interface Paiement {
  id: string;
  montant: number;
  status: PaiementStatus;
  type: ModePaiementEnum;
  idFacture?: string;
  updatedAt: Date;
}

export enum PaiementStatus {
  EN_COURS = 'EN_COURS',
  OK = 'OK',
  ECHEC = 'ECHEC',
  ANNULE = 'ANNULE',
}
