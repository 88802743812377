import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { adminActions } from '@core/store/actions/admin.action';
import { Actions, ofType } from '@ngrx/effects';
import { take } from 'rxjs/operators';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { Produit } from '../../../../../models/produit.model';
import { Acte, StructureVeterinaire } from '../../../../../models/rdv.model';
import { LogService } from '@core/services/log/log.service';
import { Action, Context, TypeLog } from '../../../../../models/log.model';
import { selectStructuresList } from '@core/store/selector/admin.selector';
import { User, UserRole } from '../../../../../models/user.model';

@Component({
  selector: 'detail-tarif-page',
  templateUrl: 'detail-tarif.page.html',
  styleUrls: ['detail-tarif.page.scss'],
})
export class DetailTarifPage implements OnInit, OnDestroy {
  // inputs
  editable: boolean = false;
  detailProps!: any;
  type!: 'acte' | 'produit';
  mode!: 'view' | 'edit';
  user!: User;

  editTarifForm!: FormGroup;
  destroy$: Subject<void>;
  tva: number = 1.2;
  categories: string[] = ['ACTES COMPLEMENTAIRES', 'MEDICAL', 'CONSULTATION VACCINALE', 'CONSULTATION FIN DE VIE'];
  structures: StructureVeterinaire[] = [];

  constructor(
    private readonly adminService: AdminService,
    private readonly store: Store,
    private readonly fb: FormBuilder,
    private readonly $actions: Actions,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly logService: LogService,
  ) {
    this.destroy$ = new Subject<void>();
    this.store
      .select(selectStructuresList)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(structures => {
        this.structures = structures;
      });
  }

  ngOnInit() {
    if (this.mode === 'edit') {
      this.editable = true;
    }
    if (this.type === 'produit') {
      this.editTarifForm = this.fb.group({
        tarif: [null, [Validators.required]],
        tarifTTC: [null, [Validators.required]],
        tarifTotalMinHT: [null],
        tarifTotalMinTTC: [null],
        tva: [null],
        uvc: [null, [Validators.required]],
        coef: [null, [Validators.required]],
        visible: [null, [Validators.required]],
        gtin: [null],
        nom: [null],
        nomCentravet: [null],
        codeArticle: [null],
        tarifAchatHT: [null],
        description: [null],
        posologie: [null],
        unite: [null],
        uniteAchat: [null],
        packaging: [null],
        uva: [null],
        medicament: [null],
        vendableHorsRdv: [null],
        subjectToReporting: [null],
        injectable: [null],
        froid: [null],
        stupefiant: [null],
      });
      this.tva = this.detailProps.tva ? (100 + this.detailProps.tva) / 100 : 1.2;
      this.editTarifForm.patchValue({
        tarif: this.detailProps.tarifVenteHT,
        tarifTTC: +(this.detailProps.tarifVenteHT * this.tva).toFixed(2),
        tarifTotalMinHT: this.detailProps.tarifTotalMinHT,
        tarifTotalMinTTC: this.detailProps.tarifTotalMinHT ? +(this.detailProps.tarifTotalMinHT * this.tva).toFixed(2) : undefined,
        coef: this.detailProps.coefAchatVente ? this.detailProps.coefAchatVente : 2,
        tva: this.detailProps.tva ? this.detailProps.tva : 20,
        visible: this.detailProps.visible !== undefined ? this.detailProps.visible : true,
        uvc: this.detailProps.uvc,
        gtin: this.detailProps.gtin,
        nom: this.detailProps.nom,
        nomCentravet: this.detailProps.nomCentravet,
        codeArticle: this.detailProps.codeArticle,
        tarifAchatHT: this.detailProps.tarifAchatHT,
        description: this.detailProps.description,
        posologie: this.detailProps.posologie,
        unite: this.detailProps.unite,
        uniteAchat: this.detailProps.uniteAchat,
        packaging: this.detailProps.packaging,
        uva: this.detailProps.uva ? this.detailProps.uva : 1,
        medicament: this.detailProps.medicament,
        vendableHorsRdv: this.detailProps.vendableHorsRdv,
        subjectToReporting: this.detailProps.subjectToReporting,
        injectable: this.detailProps.injectable,
        froid: this.detailProps.froid,
        stupefiant: this.detailProps.stupefiant,
      });
      // modif coef
      this.editTarifForm
        .get('coef')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(coef => {
          if (this.editTarifForm.get('uvc')!.value) {
            this.editTarifForm
              .get('tarif')!
              .setValue(
                +(
                  (this.editTarifForm.get('tarifAchatHT')!.value / this.editTarifForm.get('uvc')!.value / (this.editTarifForm.get('uva')!.value || 1)) *
                  coef
                ).toFixed(2),
                { emitEvent: false },
              );
            this.editTarifForm.get('tarifTTC')!.setValue(+(this.editTarifForm.get('tarif')!.value * this.tva).toFixed(2), { emitEvent: false });
          }
        });
      // modif tva
      this.editTarifForm
        .get('tva')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(tva => {
          this.tva = (100 + tva) / 100;
          if (this.editTarifForm.get('tarif')!.value) {
            this.editTarifForm.get('tarifTTC')!.setValue(+(this.editTarifForm.get('tarif')!.value * this.tva).toFixed(2), { emitEvent: false });
          }
          if (this.editTarifForm.get('tarifTotalMinHT')!.value) {
            this.editTarifForm
              .get('tarifTotalMinTTC')!
              .setValue(+(this.editTarifForm.get('tarifTotalMinHT')!.value * this.tva).toFixed(2), { emitEvent: false });
          }
        });
      // modif tarif ht
      this.editTarifForm
        .get('tarif')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(tarif => {
          this.editTarifForm.get('tarifTTC')!.setValue(+(tarif * this.tva).toFixed(2), { emitEvent: false });
          if (this.editTarifForm.get('uvc')!.value) {
            this.editTarifForm
              .get('coef')!
              .setValue(
                +(
                  tarif /
                  (this.editTarifForm.get('tarifAchatHT')!.value / this.editTarifForm.get('uvc')!.value / (this.editTarifForm.get('uva')!.value || 1))
                ).toFixed(2),
                { emitEvent: false },
              );
          }
        });
      // modif tarif achat
      this.editTarifForm
        .get('tarifAchatHT')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(tarifAchat => {
          if (this.editTarifForm.get('uvc')!.value && this.editTarifForm.get('tarif')!.value) {
            this.editTarifForm
              .get('coef')!
              .setValue(
                +(
                  this.editTarifForm.get('tarif')!.value /
                  (tarifAchat / this.editTarifForm.get('uvc')!.value / (this.editTarifForm.get('uva')!.value || 1))
                ).toFixed(2),
                { emitEvent: false },
              );
          }
        });
      // modif tarif ttc
      this.editTarifForm
        .get('tarifTTC')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(tarifTTC => {
          const tarifHT = +(tarifTTC / this.tva).toFixed(2);
          this.editTarifForm.get('tarif')!.setValue(tarifHT, { emitEvent: false });
          if (this.editTarifForm.get('uvc')!.value) {
            this.editTarifForm
              .get('coef')!
              .setValue(
                +(
                  tarifHT /
                  (this.editTarifForm.get('tarifAchatHT')!.value / this.editTarifForm.get('uvc')!.value / (this.editTarifForm.get('uva')!.value || 1))
                ).toFixed(2),
                { emitEvent: false },
              );
          }
        });
      // modif tarif total min ht
      this.editTarifForm
        .get('tarifTotalMinHT')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(tarifTotalMinHT => {
          this.editTarifForm.get('tarifTotalMinTTC')!.setValue(+(tarifTotalMinHT * this.tva).toFixed(2), { emitEvent: false });
        });
      // modif tarif total min ttc
      this.editTarifForm
        .get('tarifTotalMinTTC')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(tarifTotalMinTTC => {
          this.editTarifForm.get('tarifTotalMinHT')!.setValue(+(tarifTotalMinTTC / this.tva).toFixed(2), { emitEvent: false });
        });
      // modif uvc
      this.editTarifForm
        .get('uvc')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(uvc => {
          if (this.editTarifForm.get('tarifAchatHT')!.value && this.editTarifForm.get('tarif')!.value) {
            this.editTarifForm
              .get('coef')!
              .setValue(
                +(
                  this.editTarifForm.get('tarif')!.value /
                  (this.editTarifForm.get('tarifAchatHT')!.value / uvc / (this.editTarifForm.get('uva')!.value || 1))
                ).toFixed(2),
                { emitEvent: false },
              );
          }
          this.changeDetectorRef.markForCheck();
        });
      // modif uva
      this.editTarifForm
        .get('uva')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(uva => {
          if (this.editTarifForm.get('tarifAchatHT')!.value && this.editTarifForm.get('tarif')!.value) {
            this.editTarifForm
              .get('coef')!
              .setValue(
                +(
                  this.editTarifForm.get('tarif')!.value /
                  (this.editTarifForm.get('tarifAchatHT')!.value / this.editTarifForm.get('uvc')!.value / (uva || 1))
                ).toFixed(2),
                { emitEvent: false },
              );
          }
          this.changeDetectorRef.markForCheck();
        });
    } else {
      // acte
      this.editTarifForm = this.fb.group({
        code: [null, [Validators.required]],
        libelle: [null, [Validators.required]],
        idStructure: [null],
        tarifMinHT: [null, [Validators.required]],
        tarifMinTTC: [null, [Validators.required]],
        tarifMaxHT: [null, [Validators.required]],
        tarifMaxTTC: [null, [Validators.required]],
        categorie: [null, [Validators.required]],
        visibleClient: [false],
        visibleVeto: [true],
        consultation: [false],
        dureeRdv: [null],
        dureeRdvSupplementaireParAnimal: [null],
        commentaire: [null],
      });

      this.editTarifForm.patchValue({
        code: this.detailProps.code ? this.detailProps.code : 'CONSULTATION_DOMICILE',
        libelle: this.detailProps.libelle,
        categorie: this.detailProps.categorie,
        idStructure: this.detailProps.idStructure,
        tarifMinHT: this.detailProps.tarifMinHT ? +this.detailProps.tarifMinHT.toFixed(2) : null,
        tarifMinTTC: this.detailProps.tarifMinHT ? +(this.detailProps.tarifMinHT * this.tva).toFixed(2) : null,
        tarifMaxHT: this.detailProps.tarifMaxHT ? +this.detailProps.tarifMaxHT.toFixed(2) : null,
        tarifMaxTTC: this.detailProps.tarifMaxHT ? +(this.detailProps.tarifMaxHT * this.tva).toFixed(2) : null,
        dureeRdv: this.detailProps.dureeRdv,
        dureeRdvSupplementaireParAnimal: this.detailProps.dureeRdvSupplementaireParAnimal,
        commentaire: this.detailProps.commentaire,
        visibleClient: this.detailProps.visibleClient,
        visibleVeto: this.detailProps.visibleVeto,
        consultation: this.detailProps.consultation,
      });
      this.editTarifForm
        .get('tarifMinHT')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(tarifMinHT => {
          this.editTarifForm.get('tarifMinTTC')!.setValue(+(tarifMinHT * this.tva).toFixed(2), { emitEvent: false });
        });
      this.editTarifForm
        .get('tarifMaxHT')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(tarifMaxHT => {
          this.editTarifForm.get('tarifMaxTTC')!.setValue(+(tarifMaxHT * this.tva).toFixed(2), { emitEvent: false });
        });
      this.editTarifForm
        .get('tarifMinTTC')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(tarifMinTTC => {
          this.editTarifForm.get('tarifMinHT')!.setValue(+(tarifMinTTC / this.tva).toFixed(2), { emitEvent: false });
        });
      this.editTarifForm
        .get('tarifMaxTTC')!
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(tarifMaxTTC => {
          this.editTarifForm.get('tarifMaxHT')!.setValue(+(tarifMaxTTC / this.tva).toFixed(2), { emitEvent: false });
        });
    }

    this.$actions.pipe(ofType(adminActions.saveProduitSuccess), take(1)).subscribe(newProduit => {
      this.closeModal(newProduit.produit);
    });
    this.$actions.pipe(ofType(adminActions.saveActeSuccess), take(1)).subscribe(newActe => {
      this.closeModal(newActe.acte);
    });
    this.changeDetectorRef.markForCheck();
  }

  edit() {
    this.logService.log(
      this.type === 'acte' ? Context.ADMIN_TARIF_ACTES : Context.ADMIN_TARIF_PRODUITS,
      Action.ASK_MODIFICATION,
      TypeLog.INFO,
      this.detailProps.id,
    );
    this.editable = !this.editable;
  }

  editTarif() {
    let tarif = this.buildProp();
    if (this.type === 'acte') {
      this.logService.log(Context.ADMIN_TARIF_ACTES, Action.MODIFICATION, TypeLog.INFO, tarif);
      this.store.dispatch(adminActions.saveActe({ acte: tarif }));
    } else {
      this.logService.log(Context.ADMIN_TARIF_PRODUITS, Action.MODIFICATION, TypeLog.INFO, tarif);
      this.store.dispatch(adminActions.saveProduit({ produit: tarif }));
    }
  }

  closeModal(data?: Produit | Acte) {
    this.adminService.closeModal(data);
  }

  private buildProp() {
    let updatedProp = Object.assign({}, this.detailProps);
    if (this.type === 'acte') {
      updatedProp.tarif =
        this.editTarifForm.value.tarifMinHT !== this.editTarifForm.value.tarifMaxHT
          ? `${this.editTarifForm.value.tarifMinTTC} à ${this.editTarifForm.value.tarifMaxTTC}`
          : this.editTarifForm.value.tarifMinTTC;
      updatedProp.tarifMinHT = this.editTarifForm.value.tarifMinHT;
      updatedProp.tarifMaxHT = this.editTarifForm.value.tarifMaxHT;
      updatedProp.code = this.editTarifForm.value.code;
      updatedProp.libelle = this.editTarifForm.value.libelle;
      updatedProp.dureeRdv = this.editTarifForm.value.dureeRdv;
      updatedProp.dureeRdvSupplementaireParAnimal = this.editTarifForm.value.dureeRdvSupplementaireParAnimal;
      updatedProp.categorie = this.editTarifForm.value.categorie;
      updatedProp.idStructure = this.editTarifForm.value.idStructure;
      updatedProp.commentaire = this.editTarifForm.value.commentaire;
      updatedProp.visibleClient = this.editTarifForm.value.visibleClient;
      updatedProp.visibleVeto = this.editTarifForm.value.visibleVeto;
      updatedProp.consultation = this.editTarifForm.value.consultation;
    } else {
      updatedProp.uvc = this.editTarifForm.value.uvc;
      updatedProp.tarifVenteHT = this.editTarifForm.value.tarif;
      updatedProp.tarifTotalMinHT = this.editTarifForm.value.tarifTotalMinHT;
      updatedProp.coefAchatVente = this.editTarifForm.value.coef;
      updatedProp.visible = this.editTarifForm.value.visible;
      updatedProp.gtin = this.editTarifForm.value.gtin;
      updatedProp.nom = this.editTarifForm.value.nom;
      updatedProp.nomCentravet = this.editTarifForm.value.nomCentravet;
      updatedProp.codeArticle = this.editTarifForm.value.codeArticle;
      updatedProp.tarifAchatHT = this.editTarifForm.value.tarifAchatHT;
      updatedProp.tva = this.editTarifForm.value.tva;
      updatedProp.description = this.editTarifForm.value.description;
      updatedProp.posologie = this.editTarifForm.value.posologie;
      updatedProp.unite = this.editTarifForm.value.unite;
      updatedProp.uniteAchat = this.editTarifForm.value.uniteAchat;
      updatedProp.packaging = this.editTarifForm.value.packaging;
      updatedProp.uva = this.editTarifForm.value.uva;
      updatedProp.medicament = this.editTarifForm.value.medicament;
      updatedProp.vendableHorsRdv = this.editTarifForm.value.vendableHorsRdv;
      updatedProp.subjectToReporting = this.editTarifForm.value.subjectToReporting;
      updatedProp.injectable = this.editTarifForm.value.injectable;
      updatedProp.froid = this.editTarifForm.value.froid;
      updatedProp.stupefiant = this.editTarifForm.value.stupefiant;
    }
    this.detailProps = updatedProp;

    return updatedProp;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly UserRole = UserRole;
}
