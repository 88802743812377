import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Client, Facture } from 'app/models/rdv.model';
import { map, Observable } from 'rxjs';
import { Page } from '../../../models/page.model';
import { UserWithAnimal } from '../../../models/client.model';

@Injectable({ providedIn: 'root' })
export class FicheClientService {
  constructor(private httpApiService: HttpApiService) {}

  getClient(requestClient: string, pageNumber: number, offset: number): Observable<Page<UserWithAnimal>> {
    return this.httpApiService
      .post<Page<UserWithAnimal>>('api/back_office/user/search', {
        size: 10,
        offset: offset,
        page: pageNumber,
        request: requestClient,
        role: 'ROLE_CLIENT',
      })
      .pipe(map(data => data));
  }

  getInfosClient(idClient: string): Observable<Client> {
    return this.httpApiService.get<Client>(`api/back_office/user/${idClient}`);
  }

  getFacture(idClient: string): Observable<Facture[]> {
    return this.httpApiService.get<Facture[]>(`api/back_office/facture/client/${idClient}`).pipe(map(data => data));
  }
}
