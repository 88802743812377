import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { UserService } from '@core/services/user/user.service';
import { NotificationType } from 'app/constants/notification-type.enum';
import { UserWithDeviceId } from 'app/models/user.model';
import { SessionActions } from '../actions/session.actions';
import { UserApiActions } from '../actions/user.actions';
import { selectUserWithDevice } from '@core/store/selector/session.selectors';
import { Store } from '@ngrx/store';
import { adminActions } from '@core/store/actions/admin.action';
import { NavController } from '@ionic/angular';

@Injectable()
export class UserEffects {
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserApiActions.login),
      exhaustMap(action =>
        this.authenticationService.login(action.loginData).pipe(
          tap(credentials => {
            this.authenticationService.setCredentialsInStorage(credentials);
          }),
          map(credentials => UserApiActions.loginSucceeded({ credentials })),
          catchError((error: HttpErrorResponse) => {
            return of(UserApiActions.loginError({ error: error.error }));
          }),
        ),
      ),
    );
  });

  loginSucceeded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserApiActions.loginSucceeded),
      map(() => UserApiActions.getUserAfterLogin()),
    );
  });

  getUserAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserApiActions.getUserAfterLogin),
      switchMap(() =>
        this.userService.getCurrentUserWithDeviceId().pipe(
          map((userWithDeviceId: UserWithDeviceId) => UserApiActions.getUserAndDeviceIdSucceededAfterLogin(userWithDeviceId)),
          catchError(error => of(UserApiActions.getUserErrorAfterLogin({ error }))),
        ),
      ),
    );
  });

  getUserSucceededAfterLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserApiActions.getUserAndDeviceIdSucceededAfterLogin),
        tap(() => {
          this.navCtrl.navigateForward(['']);
          // this.notificationService.notify(NotificationType.LOGIN_SUCCES, 5000);
        }),
      );
    },
    { dispatch: false },
  );

  getUserErrorAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserApiActions.getUserErrorAfterLogin),
      tap(() => {
        this.authenticationService.clearCredentials();
      }),
      map(action => UserApiActions.loginError({ error: action.error })),
    );
  });

  getUserInAppInitializer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.setCredentials),
      switchMap(() =>
        this.userService.getCurrentUserWithDeviceId().pipe(
          map((userWithDeviceId: UserWithDeviceId) => UserApiActions.getUserAndDeviceIdSucceededAfterInit(userWithDeviceId)),
          catchError(() => of(SessionActions.resetStateAndFinishAppInitialization())),
        ),
      ),
    );
  });

  loadStructuresAfterInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserApiActions.getUserAndDeviceIdSucceededAfterInit),
      map(() => adminActions.getAllStructure()),
    );
  });

  finishAppInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminActions.getAllStructureSuccess),
      map(() => SessionActions.finishAppInitialization()),
    );
  });
  loadStructuresAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserApiActions.getUserAndDeviceIdSucceededAfterLogin),
      map(() => adminActions.getAllStructure()),
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserApiActions.logout),
      concatLatestFrom(() => this.store.select(selectUserWithDevice)),
      switchMap(([, userWithDevice]) => {
        if (userWithDevice?.deviceId) {
          return this.authenticationService.deleteDevice(userWithDevice.deviceId);
        } else {
          return of({});
        }
      }),
      map(() => SessionActions.resetState()),
    );
  });

  logoutSucceeded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserApiActions.logoutSucceeded),
        tap(() => {
          this.navCtrl.navigateBack(['/login']);
          this.notificationService.notify(NotificationType.LOGOUT, 5000);
        }),
      );
    },
    { dispatch: false },
  );
  updateUserPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserApiActions.updateuserpreferences),
      switchMap(action => this.userService.updateUserPreferences(action.preferences)),
      map(pref => UserApiActions.updateuserpreferencessuccess({ preferences: pref })),
    );
  });

  updateUserPreferencesSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserApiActions.updateuserpreferencessuccess),
        tap(() => {
          console.log('updateuserpreferencessuccess');
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly userService: UserService,
    private readonly navCtrl: NavController,
    private readonly store: Store,
    private readonly notificationService: NotificationService,
    private readonly authenticationService: AuthenticationService,
  ) {}
}
