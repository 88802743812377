import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { JwtToken } from 'app/models/jwt-token.model';
import { LoginData } from 'app/models/login-data.model';
import { UserWithDeviceId, UtilisateurPreferences } from 'app/models/user.model';

export const UserApiActions = createActionGroup({
  source: 'User API',
  events: {
    'Get User After Login': emptyProps(),
    'Get User And Device Id Succeeded After Init': props<UserWithDeviceId>(),
    'Get User And Device Id Succeeded After Login': props<UserWithDeviceId>(),
    'Get User Error After Login': props<{
      error: string;
    }>(),
    Login: props<{
      loginData: LoginData;
    }>(),
    'Login Succeeded': props<{
      credentials: JwtToken;
    }>(),
    'Login Error': props<{
      error: string;
    }>(),
    Logout: emptyProps(),
    'Logout Succeeded': emptyProps(),
    updateUserPreferences: props<{
      preferences: UtilisateurPreferences;
    }>(),
    updateUserPreferencesSuccess: props<{
      preferences: UtilisateurPreferences;
    }>(),
  },
});
