import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tarif-page',
  templateUrl: 'tarif.page.html',
  styleUrls: ['tarif.page.scss'],
})
export class TarifPage implements OnInit {
  constructor() {}

  ngOnInit() {
    console.log('init tarif home');
  }
}
