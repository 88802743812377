<!-- <ion-header>
  <ion-toolbar class="ion-text-center">

  </ion-toolbar>
</ion-header> -->
<ion-content color="blue100">
  <ion-grid>
    <ion-row>
      <ion-col>
        <div class="img-toolbar ion-text-center" [routerLink]="'/'" [style.cursor]="'pointer'">
          <img src="/assets/logo.svg" />
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-sm="10" offset-sm="1" size-xs="12" size-md="8" offset-md="2" size-lg="4" offset-lg="4" size-xl="4" offset-xl="4">
        <ion-card color="blue200">
          <div class="ion-text-center img-wrapper">
            <img src="/assets/illu2vetos.png" />
          </div>

          <ion-card-header class="ion-text-center">
            <ion-item lines="none" class="ion-text-center" color="blue200">
              <ion-label color="grey900" class="ion-text-wrap" id="connexion">
                <h1>
                  <ion-icon name="lock-closed" color="grey900" slot="start"></ion-icon>
                  <b>Modification du mot de passe</b>
                </h1>
              </ion-label>
            </ion-item>
          </ion-card-header>
          <ion-card-content>
            <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()" (keyup.enter)="submit()">
              <ion-item lines="none" color="blue200">
                <!-- <ion-label position="floating"> E-mail</ion-label> -->
                <ion-input placeholder="Mot de passe" type="password" formControlName="password" required autofocus></ion-input>
                <ion-note slot="error" *ngIf="resetPasswordForm.get('password')?.hasError('required') && resetPasswordForm.touched">
                  Le mot de passe est <strong>requis</strong></ion-note
                >
              </ion-item>
              <ion-item lines="none" color="blue200">
                <!-- <ion-label position="floating"> E-mail</ion-label> -->
                <ion-input placeholder="Confirmation du mot de passe" type="password" formControlName="passwordConfirm" required autofocus></ion-input>
                <ion-note slot="error" *ngIf="resetPasswordForm.get('passwordConfirm')?.hasError('required') && resetPasswordForm.touched">
                  La confirmation du mot de passe est <strong>requise</strong></ion-note
                >
                <ion-note
                  slot="error"
                  *ngIf="resetPasswordForm.get('passwordConfirm')?.value !== resetPasswordForm.get('password')?.value && resetPasswordForm.touched"
                >
                  La confirmation du mot de passe n'est pas identique au mot de passe
                </ion-note>
              </ion-item>

              <ion-button
                expand="block"
                type="submit"
                [disabled]="
                  isSubmitting || resetPasswordForm.invalid || resetPasswordForm.get('passwordConfirm')?.value !== resetPasswordForm.get('password')?.value
                "
                color="grey900"
              >
                <ion-label *ngIf="!isSubmitting">Valider</ion-label>
                <ion-label *ngIf="isSubmitting">
                  <ion-spinner name="crescent"></ion-spinner>
                </ion-label>
              </ion-button>
              <br />
            </form>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
