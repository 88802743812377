import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Animal } from 'app/models/animal.model';
import { Poids } from 'app/models/compte-rendu.model';
import { Client, HorsRdv, RdvDomicile } from 'app/models/rdv.model';
import { UserWithAnimal } from '../../../../models/client.model';
import { Page } from '../../../../models/page.model';

export const clientActions = createActionGroup({
  source: 'clients',
  events: {
    'search client': props<{ input: string }>(),
    'search client success': props<{ results: Page<UserWithAnimal> }>(),
    'search client scroll': props<{ input: string; pageNumber: number; offset: number }>(),
    'search client scroll success': props<{ results: Page<UserWithAnimal> }>(),
    'reset search state': emptyProps(),
    'get client infos': props<{ idClient: string }>(),
    'get clients infos success': props<{ infosClient: Client }>(),
    'get historique success': props<{ rdvData: Page<RdvDomicile>; horsRdvData: Page<HorsRdv> }>(),
    'add hors rdv': props<{ horsRdvData: HorsRdv }>(),
    'add rdv domicile': props<{ rdv: RdvDomicile }>(),
    'Get animal list client': props<{ idClient: string }>(),
    'Get animal list client success': props<{ animalClientList: Animal[] }>(),
    'set active fiche animal': props<{ idAnimal: string; idClient: string }>(),
    'set active fiche animal success': props<{ animal: Animal }>(),
    'edit fiche animal': props<{ animal: Animal }>(),
    'edit fiche client': props<{ client: Client }>(),
    'edit fiche client success': props<{ client: Client }>(),
    'reset state client': emptyProps(),

    //gestion poids

    'get poids': props<{ idAnimal: string | undefined }>(),
    'get poids success': props<{ poids: Poids[] }>(),
    'update poids': props<{ newPoids: Poids }>(),
    'delete poids': props<{ newPoids: Poids }>(),
    'reset poids': emptyProps(),
  },
});
