import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Prestation } from 'app/models/rdv.model';
import { CompteRendu } from '../../../../models/compte-rendu.model';
import { Consentement } from '../../../../models/consentement.model';

export const PrestationAction = createActionGroup({
  source: 'prestation',
  events: {
    'access prestation': props<{ idPrestation: string }>(),
    resetactiveprestation: emptyProps(),
    'access prestation success': props<{ prestation: Prestation }>(),
    // PRESTATION
    createprestation: props<{ prestation: Prestation; idRdv: string }>(),
    updateprestation: props<{ prestation: Prestation }>(),
    saveprestationsuccess: props<{ prestation: Prestation }>(),
    // CR
    createcr: props<{ compteRendu: CompteRendu; idPrestation: string; finalize: boolean }>(),
    createcrsuccess: props<{ compteRendu: CompteRendu; idPrestation: string }>(),
    // Facture
    markfacturepayee: props<{ id: string; idPrestation: string }>(),
    markfacturepayeesuccess: props<{ id: string; idPrestation: string }>(),
    // Com
    updatecommentaireinterne: props<{ commentaireInterne: string; idPrestation: string }>(),
    updatecommentaireinternesuccess: props<{ commentaireInterne: string; idPrestation: string }>(),
    // consentement
    demandeconsentement: props<{ consentement: Consentement; idPrestation: string }>(),
    demandeconsentementsuccess: props<{ consentement: Consentement; idPrestation: string }>(),
  },
});
