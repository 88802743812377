import { APP_BASE_HREF, CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from 'environments/environment';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HttpTokenInterceptor } from './interceptors/http-token.interceptor';
import { HomePage } from './pages/home/home.page';
import { LoginPage } from './pages/login/login.page';
import { AppInitializerService } from './services/app-initializer/app-initializer.service';
import { GlobalErrorHandlerService } from './services/error-handlers/global-error-handler.service';
import { SessionEffects, UserEffects } from './store/effects';
import { metaReducers } from './store/reducers';
import { sessionReducer } from './store/reducers/session.reducer';
import { discussionReducer } from '@core/store/reducers/discussion.reducer';
import { DiscussionEffects } from '@core/store/effects/discussion.effects';
import { chatReducer } from './store/reducers/chat.reducer';
import { ChatEffects } from './store/effects/chat.effects';
import { IonicModule } from '@ionic/angular';
import { RdvDomicileEffects } from './store/effects/rdv-domicile.effects';
import { ProduitEffects } from '@core/store/effects/produit.effects';
import { rdvDomicileReducer } from '@core/store/reducers/rdv-domicile.reducer';
import { prestationReducer } from '@core/store/reducers/prestation.reducer';
import { PrestationEffects } from './store/effects/prestation.effects';
import { clientsReducer } from './store/reducers/clients.reducer';
import { clientsEffects } from './store/effects/clients.effects';
import { adminEffects } from './store/effects/admin.effects';
import { adminReducer } from './store/reducers/admin.reducer';
import { TooltipModule } from '../shared/components/tooltip/tooltip.module';
import { ForgotPasswordPage } from '@core/pages/forgot-password/forgot-password.page';
import { ChangementMotDePassePage } from '@core/pages/changement-mot-de-passe/changement-mot-de-passe.page';
import { SharedModule } from '../shared/shared.module';
import { ChatbotComponent } from '@core/components/bot/chatbot.component';

const components = [PageNotFoundComponent, ChatbotComponent];
const pages = [HomePage, LoginPage, ForgotPasswordPage, ChangementMotDePassePage];

@NgModule({
  declarations: [...pages, ...components],
  imports: [
    IonicModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
    }),
    StoreModule.forRoot(
      {
        session: sessionReducer,
        discussion: discussionReducer,
        Chat: chatReducer,
        rendezvous: rdvDomicileReducer,
        prestation: prestationReducer,
        clients: clientsReducer,
        admin: adminReducer,
      },
      {
        metaReducers,
        runtimeChecks: {
          strictActionImmutability: true,
          strictActionSerializability: false,
          strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
          strictStateImmutability: true,
          strictStateSerializability: true,
        },
      },
    ),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([
      SessionEffects,
      UserEffects,
      DiscussionEffects,
      ChatEffects,
      RdvDomicileEffects,
      ProduitEffects,
      PrestationEffects,
      clientsEffects,
      adminEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
    NgOptimizedImage,
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializerService: AppInitializerService) => () => appInitializerService.load(),
      deps: [AppInitializerService],
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    DatePipe,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('Core module is already loaded. Import it in the main module only');
    }
  }
}
