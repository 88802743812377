import { Action, createReducer, on } from '@ngrx/store';
import { ChatMessage } from 'app/models/message.model';
import { User } from 'app/models/user.model';
import { ChatActions } from '../actions/chat.actions';
import { MilouDispo, PartenaireMilou, RdvStructure } from 'app/models/client.model';

export interface ChatState {
  readonly messages: ChatMessage[];
  readonly listVeto: User[];
  readonly isDispoChat: boolean;
  readonly listPartenaire?: PartenaireMilou[];
  readonly rdvStructure?: RdvStructure;
  readonly isMilouDispo?: MilouDispo;
}

export const initialState: ChatState = {
  messages: [],
  listVeto: [],
  isDispoChat: false,
};

export const chatReducer = createReducer(
  initialState,

  on(ChatActions.getMessagesSuccess, (state, { messages }): ChatState => ({ ...state, messages })),
  on(ChatActions.subscribeChannelSuccess, (state, { message }): ChatState => ({ ...state, messages: [...state.messages, message] })),
  on(ChatActions.resetMessageState, (state): ChatState => ({ ...state, messages: initialState.messages })),
  on(ChatActions.loadVeterinairesSuccess, (state, { listVeto }): ChatState => ({ ...state, listVeto: listVeto })),
  on(ChatActions.isVetoDispoSuccess, (state, { isDispo }): ChatState => ({ ...state, isDispoChat: isDispo })),
  on(
    ChatActions.isDispoMilouSuccess,
    (state, { rdvStructure }): ChatState => ({ ...state, rdvStructure: rdvStructure, isMilouDispo: { isDispo: true, message: '' } }),
  ),
  on(
    ChatActions.getPartenaireVadSuccess,
    (state, { listPartenaire, indisponible }): ChatState => ({
      ...state,
      listPartenaire: listPartenaire,
      isMilouDispo: { isDispo: false, message: indisponible },
    }),
  ),
);

export function reducer(state: ChatState, action: Action): ChatState {
  return chatReducer(state, action);
}
