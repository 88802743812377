import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Acte, Client, Devis, EtatDevisEnum, EtatFacturationEnum, Facturation, Prestation, RdvDomicile } from 'app/models/rdv.model';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';

import { Actions, ofType } from '@ngrx/effects';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { LogService } from '@core/services/log/log.service';
import { User, UserRole } from 'app/models/user.model';
import BigNumber from 'bignumber.js';
import { Browser } from 'leaflet';
import { Produit, SearchProduitRequest } from '../../../../models/produit.model';
import { ReferenceService } from '../../../rdv/wizard/services/reference.service';
import { ProduitActions } from '@core/store/actions/produit.actions';
import { Action, Context, TypeLog } from '../../../../models/log.model';
import { FacturationService } from '../../../rdv/wizard/services/facturation.service';
import { DecesModalComponent } from '../../../rdv/wizard/components/deces/deces-modal.component';
import { VaccinModalComponent } from '../../../rdv/wizard/components/vaccin/vaccin-modal.component';
import { DevisService } from '../../../rdv/wizard/services/devis.service';
import { EnvoiFactureComponent } from '../../../rdv/wizard/components/facturation/envoi-facture/envoi-facture.component';
import { ChoixDevisPage } from '../../../planning/pages/devis-rdv/choix-devis/choix-devis.page';
import { Animal } from '../../../../models/animal.model';
import mobile = Browser.mobile;

@Component({
  selector: 'facturation',
  templateUrl: './facturation.component.html',
  styleUrls: ['./facturation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FacturationComponent implements OnChanges, OnDestroy {
  form!: FormGroup;
  @Input() idStructure!: string;
  @Input() finalisable!: boolean;
  @Input() client!: Client;
  @Input() rendezvous?: RdvDomicile;
  @Input() facturationEditable!: boolean;
  @Input() user!: User;
  @Input() prestation?: Prestation;
  @Input() displayDevisBtn = true;
  @Input() displayActes = true;
  @Input() genererFactureParAnimal = false;
  @Input() animaux?: Animal[];
  @Input() displayDeleteBtn = true;
  @Input() displayAddBtn = true;
  @Input() displaySaveBtns = true;
  @Input() loadBlankProduit = false;
  @Input() onlyRenouvelables = false;

  oldPresta?: Prestation;

  acteList?: Acte[];
  actesFiltres?: Acte[];
  currentIndexForActeSearch?: number;
  currentIndexForProduitSearch?: number;
  currentFacturationForProduitSearch?: number;
  currentFacturationForActeSearch?: number;
  produitSub?: Subscription;

  produitsFiltres: Produit[] = [];
  remise?: string;

  protected readonly EtatFacturationEnum = EtatFacturationEnum;

  private readonly destroy$: Subject<void>;
  private readonly CENT: BigNumber = new BigNumber(100);

  protected readonly UserRole = UserRole;
  devisList: Devis[] = [];
  mobile;

  constructor(
    private readonly store: Store,
    private readonly fb: FormBuilder,
    private readonly referenceService: ReferenceService,
    private readonly devisService: DevisService,
    private readonly actions$: Actions,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly alertController: AlertController,
    private readonly logService: LogService,
    private readonly modalCtrl: ModalController,
    private readonly toastController: ToastController,
  ) {
    this.mobile = mobile;

    this.destroy$ = new Subject<void>();
  }

  ngOnChanges() {
    if (this.oldPresta) {
      // pour ne charger qu'une fois
      return;
    }
    if (this.facturationEditable) {
      let factuArray: FormArray;

      if (!this.genererFactureParAnimal) {
        factuArray = this.fb.array([this.getNewFactu()]);
      } else {
        factuArray = this.fb.array(this.animaux!.map(() => this.getNewFactu()));
      }
      this.form = this.fb.group({
        facturations: factuArray,
      });

      const firstFacturation = this.facturations.at(0);
      firstFacturation.valueChanges
        .pipe(
          takeUntil(this.destroy$),
          filter(() => firstFacturation.get('etat')!.value === EtatFacturationEnum.TERMINEE),
        )
        .subscribe(() => firstFacturation.get('etat')!.setValue(EtatFacturationEnum.EN_COURS, { emitEvent: false }));

      if (this.client?.id) {
        this.devisService.findByIdClient(this.client.id).subscribe(listDevis => {
          this.devisList = this.devisService.sortDevis(listDevis, this.rendezvous?.id, this.prestation?.animal?.id);
          this.changeDetectorRef.markForCheck();
        });
      }
    } else {
      this.form = this.fb.group({
        facturations: this.fb.array([]),
      });
    }

    this.actions$.pipe(ofType(ProduitActions.mergefacturationsuccess), takeUntil(this.destroy$)).subscribe(a => {
      this.loadFacturations(a.facturations);
      if (a.hasModified) {
        this.form.markAsDirty();
      }
    });
    this.actions$
      .pipe(
        ofType(ProduitActions.updatefacturationstatesuccess),
        filter(action => action.facturation.etat === EtatFacturationEnum.FINALISEE),
        takeUntil(this.destroy$),
      )
      .subscribe(a => {
        if (a.facturation.idDevis?.length) {
          a.facturation.idDevis.forEach(id => {
            let devis = this.devisList.find(d => d.id === id);
            if (devis) {
              devis.etat = EtatDevisEnum.FACTURE;
              devis.idFacturation = a.facturation.id;
            }
          });
        }
        if (a.facturation.etat === EtatFacturationEnum.FINALISEE) {
          this.toastController
            .create({
              header: 'Facture envoyée',
              duration: 5000,
              message: 'Un mail a été envoyé au client',
              position: 'bottom',
            })
            .then(toast => toast.present());
        }
        this.facturations.at(a.idxFacturation).get('idFacture')!.setValue(a.facturation.idFacture);
        this.changeDetectorRef.markForCheck();
      });

    this.referenceService
      .findActesByIdStructure(this.idStructure)
      .pipe(takeUntil(this.destroy$))
      .subscribe(actes => {
        this.acteList = actes;
        this.changeDetectorRef.markForCheck();
      });

    this.actions$
      .pipe(
        ofType(ProduitActions.savefacturationsuccess),
        takeUntil(this.destroy$),
        tap(action => {
          let factuCtrl = this.facturations.at(action.idxFacturation);
          factuCtrl.get('id')!.setValue(action.facturation.id, { emitEvent: false });
          factuCtrl.get('idFacture')!.setValue(action.facturation.idFacture, { emitEvent: false });
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe();

    this.oldPresta = this.prestation;
    if (this.prestation?.ordonnances?.length && this.finalisable) {
      this.store.dispatch(ProduitActions.generatefacturation({ ordonnances: this.prestation.ordonnances }));
    } else {
      if (this.prestation?.facturations) {
        this.loadFacturations(this.prestation?.facturations);
      }
    }
    this.logService.log(Context.RDV_FACTURE, Action.CONSULTATION, TypeLog.INFO, { idPrestation: this.prestation?.id! });
  }

  get facturations() {
    return this.form?.controls['facturations'] as FormArray;
  }

  produits(idxFacturation: number) {
    return this.facturations.at(idxFacturation).get('produits') as FormArray;
  }

  actes(idxFacturation: number) {
    return this.facturations.at(idxFacturation).get('actes') as FormArray;
  }

  addActe(idxFacturation: number) {
    if (this.actes(idxFacturation).controls.filter(a => a.get('acteSearch')!.value === '').length) {
      return;
    }
    const formGroup = this.getNewActe();
    this.logService.log(Context.RDV_FACTURE, Action.ADD_ACTE, TypeLog.INFO, { idxFacturation: idxFacturation });
    this.actes(idxFacturation).push(formGroup);
    this.subscribeToActeValueChanges(formGroup, idxFacturation);
    this.calculRemise(idxFacturation);
  }

  deleteActe(idxFacturation: number, index: number) {
    this.actes(idxFacturation).removeAt(index);
    this.logService.log(Context.RDV_FACTURE, Action.DELETE_ACTE, TypeLog.INFO, {
      idxFacturation: idxFacturation,
      index: index,
    });
    this.facturations
      .at(idxFacturation)
      .get('total')!
      .setValue(this.computeTotal(this.facturations.at(idxFacturation)!), { emitEvent: false });
    this.changeDetectorRef.markForCheck();
    this.calculRemise(idxFacturation);
  }

  addProduit(idxFacturation: number) {
    if (this.produits(idxFacturation).controls.filter(p => p.get('produitSearch')!.value === '').length) {
      return;
    }

    const formGroup = this.getNewProduit();
    this.logService.log(Context.RDV_FACTURE, Action.ADD_PRODUIT, TypeLog.INFO, { idxFacturation: idxFacturation });
    this.produits(idxFacturation).push(formGroup);
    this.subscribeToProduitValueChanges(formGroup, idxFacturation);
  }

  deleteProduit(idxFacturation: number, index: number) {
    const produit = this.produits(idxFacturation).at(index);
    if (produit.get('idProduitPrescrit')!.value) {
      this.facturations
        .at(idxFacturation)
        .get('idsProduitsPrescritsSupprimes')!
        .setValue([...this.facturations.at(idxFacturation).get('idsProduitsPrescritsSupprimes')!.value, produit.get('idProduitPrescrit')!.value], {
          emitEvent: false,
        });
    }
    this.produits(idxFacturation).removeAt(index);
    this.logService.log(Context.RDV_FACTURE, Action.DELETE_PRODUIT, TypeLog.INFO, {
      idxFacturation: idxFacturation,
      index: index,
    });
    this.facturations
      .at(idxFacturation)
      .get('total')!
      .setValue(this.computeTotal(this.facturations.at(idxFacturation)!), { emitEvent: false });
    this.changeDetectorRef.markForCheck();
  }

  addFacturation() {
    this.logService.log(Context.RDV_FACTURE, Action.ADD_FACTURE, TypeLog.INFO, this.prestation!.id);
    const newFacturation = this.fb.group({
      id: [null],
      idFacture: [null],
      etat: [EtatFacturationEnum.EN_COURS],
      idsProduitsPrescritsSupprimes: [[]],
      idDevis: [[]],
      actes: this.fb.array([this.getNewActe()]),
      produits: this.fb.array([]),
      total: [0, [Validators.min(0.01)]],
    });
    this.facturations.push(newFacturation);
    this.subscribeToActeValueChanges(this.actes(this.facturations.length - 1).at(0), this.facturations.length - 1);
    newFacturation.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(() => newFacturation.get('etat')!.value === EtatFacturationEnum.TERMINEE),
      )
      .subscribe(() => newFacturation.get('etat')!.setValue(EtatFacturationEnum.EN_COURS, { emitEvent: false }));
  }

  deleteFacturation(index: number) {
    this.logService.log(Context.RDV_FACTURE, Action.DELETE_FACTURE, TypeLog.INFO, {
      idPrestation: this.prestation!.id,
      index: index,
    });
    this.alertController
      .create({
        header: 'Êtes-vous sûr de vouloir supprimer cette facture ?',
        buttons: [
          {
            text: 'Non',
            handler: () => {},
          },
          {
            text: 'Oui',
            handler: () => {
              const id = this.facturations.at(index).get('id')!.value;
              const produits = this.produits(index);
              const idsProduitsPrescritsSupprimes = produits.controls
                .filter(c => !!c.get('idProduitPrescrit')!.value)
                .map(c => c.get('idProduitPrescrit')!.value);
              if (id) {
                this.store.dispatch(ProduitActions.deletefacturation({ id }));
              }
              this.currentFacturationForActeSearch = -1;
              this.currentFacturationForProduitSearch = -1;
              this.facturations.removeAt(index);
              if (this.facturations.length === 0) {
                this.addFacturation();
              }
              this.facturations
                .at(0)
                .get('idsProduitsPrescritsSupprimes')!
                .setValue([...this.facturations.at(0).get('idsProduitsPrescritsSupprimes')!.value, ...idsProduitsPrescritsSupprimes], { emitEvent: false });

              this.form.markAsDirty();
              this.calculRemise(0);
              this.changeDetectorRef.markForCheck();
            },
          },
        ],
      })
      .then(res => {
        res.present();
      });
  }

  private unaccent(str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  searchActe(event: any, idxFacturation: number, i: number) {
    this.actes(idxFacturation).at(i).get('acte')!.setValue(null);
    this.setActeSearchToIndex(idxFacturation, i);

    if (event.target.value.length > 2) {
      this.actesFiltres = this.acteList!.filter(
        acte => acte.visibleVeto && this.unaccent(acte.libelle.toLowerCase()).includes(this.unaccent(event!.target!.value.toLowerCase())),
      );
    } else {
      this.actesFiltres = [];
    }
  }

  selectActe(a: Acte, idxFacturation: number, i: number) {
    const acteCtrl = this.actes(idxFacturation).at(i);
    acteCtrl.reset();
    acteCtrl.clearValidators();

    acteCtrl.get('acte')!.setValue(a);
    acteCtrl.get('quantity')!.setValue(1);
    acteCtrl.get('tva')!.setValue(20);

    acteCtrl.get('priceHT')!.setValue(BigNumber(a.tarifMinHT).decimalPlaces(2).toNumber());
    acteCtrl.get('priceHTInitial')!.setValue(BigNumber(a.tarifMinHT).decimalPlaces(2).toNumber());
    acteCtrl.get('priceTTC')!.setValue(this.computeTarifTTC(acteCtrl));

    const tva = BigNumber(1.2);
    if (a.tarifMaxHT && a.tarifMaxHT !== a.tarifMinHT) {
      acteCtrl.get('priceTTC')!.addValidators([
        Validators.max(
          Math.round(
            BigNumber(acteCtrl.value.tarifMaxHT)
              .decimalPlaces(2)
              .multipliedBy(BigNumber(acteCtrl.get('quantity')!.value))
              .multipliedBy(tva)
              .toNumber(),
          ),
        ),
      ]);
    }
    acteCtrl.get('acteSearch')!.setValue(a.libelle);
    acteCtrl.get('priceTTC')!.addValidators([Validators.required]);

    acteCtrl.get('quantity')!.addValidators([Validators.min(1), Validators.required]);
    this.facturations
      .at(idxFacturation)
      .get('total')!
      .setValue(this.computeTotal(this.facturations.at(idxFacturation)!), { emitEvent: false });
    this.calculRemise(idxFacturation);
    this.form.markAsDirty();
  }

  searchProduit(event: any, idxFacturation: number, i: number) {
    this.setProduitSearchToIndex(idxFacturation, i);
    this.produits(idxFacturation).at(i).get('produit')!.setValue(null);
    if (event.target.value?.length > 2) {
      if (this.produitSub) {
        this.produitSub.unsubscribe();
      }
      const searchRequest: SearchProduitRequest = { request: event.target.value, visible: true };
      if (this.onlyRenouvelables) {
        searchRequest.isVendableOrRenouvelableForAnimalId = this.animaux![idxFacturation].id;
      }
      this.produitSub = this.referenceService
        .searchProduits(searchRequest)
        .pipe(takeUntil(this.destroy$))
        .subscribe(produits => {
          this.produitsFiltres = produits.elements;
          this.changeDetectorRef.markForCheck();
        });
    } else {
      this.produitsFiltres = [];
      this.changeDetectorRef.markForCheck();
    }
  }

  selectProduit(p: Produit, idxFacture: number, i: number) {
    const produitCtrl = this.produits(idxFacture).at(i);
    produitCtrl.clearValidators();
    produitCtrl.get('produit')!.setValue(p);
    if (p.tarifVenteHT) {
      produitCtrl.get('priceHT')!.setValue(BigNumber(p.tarifVenteHT).decimalPlaces(2).toNumber());
      produitCtrl.get('priceHTInitial')!.setValue(BigNumber(p.tarifVenteHT).decimalPlaces(2).toNumber());
    }

    if (p.tarifTotalMinHT) {
      produitCtrl.get('priceTTC')!.addValidators([
        Validators.min(
          BigNumber(p.tarifTotalMinHT)
            .decimalPlaces(2)
            .multipliedBy(this.CENT.plus(p.tva ? BigNumber(p.tva) : BigNumber(20)))
            .dividedBy(this.CENT)
            .decimalPlaces(2)
            .toNumber(),
        ),
      ]);
    }
    produitCtrl.get('priceTTC')!.setValue(this.computeTarifTTC(produitCtrl), { emitEvent: false, onlySelf: true });
    // produitCtrl.get('priceTTC')!.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    produitCtrl.get('produitSearch')!.setValue(FacturationService.getProduitName(p));
    this.facturations
      .at(idxFacture)
      .get('total')!
      .setValue(this.computeTotal(this.facturations.at(idxFacture)!), { emitEvent: false });
    this.form.markAsDirty();

    this.changeDetectorRef.markForCheck();
  }

  computeTotal(facturation: any): number {
    if (!facturation.get('actes').controls?.length && !facturation.get('produits').controls?.length) {
      return 0;
    }

    return BigNumber.sum(
      ...facturation.get('actes').controls.map((control: any) => BigNumber(control.get('priceTTC')!.value).decimalPlaces(2)),
      ...facturation.get('produits').controls.map((control: any) => BigNumber(control.get('priceTTC')!.value).decimalPlaces(2)),
    )
      .decimalPlaces(2)
      .toNumber();
  }

  saveFacturation(idxFacturation: number) {
    this.form.markAsPristine({ onlySelf: true });
    const facturationCtrl = this.facturations.at(idxFacturation);
    facturationCtrl.get('etat')!.setValue(EtatFacturationEnum.TERMINEE, { emitEvent: false });
    const facturation: Facturation = FacturationService.buildFacturation(facturationCtrl as FormGroup, idxFacturation);
    this.logService.log(Context.RDV_FACTURE, Action.MODIFICATION, TypeLog.INFO, facturation);
    let facture = { facturation, idPrestation: this.prestation!.id!, idxFacturation };

    this.store.dispatch(ProduitActions.savefacturation(facture));
  }

  finaliserFacturation(idxFacturation: number) {
    const actesFinDeVie = this.actesFinDeVie(idxFacturation);

    const actesVaccin = this.actesVaccination(idxFacturation);

    if (actesFinDeVie.length !== 0) {
      this.showModalDeces(idxFacturation);
    } else if (actesVaccin.length !== 0) {
      this.showModalVaccin(idxFacturation);
    } else {
      this.confirmFinalisation(idxFacturation);
    }
  }

  protected calculRemise(idxFacturation: number) {
    if (!this.rendezvous) {
      return;
    }
    const nbAnimaux = this.rendezvous.animaux.length;
    if (nbAnimaux > 1) {
      if (this.actesVaccination(idxFacturation).length > 0 || this.actesIdentification(idxFacturation).length > 0) {
        this.remise = nbAnimaux <= 3 ? '10% sur TOUS les animaux, sauf complément' : '20% sur TOUS les animaux, sauf complément';
      } else if (this.actesConsultationMedical(idxFacturation).length > 0) {
        this.remise = nbAnimaux <= 3 ? '10% de remise sur TOUS les actes' : '20% de remise sur TOUS les actes';
      } else this.remise = undefined;
    }
  }

  private actesFinDeVie(idxFacturation: number): any[] {
    const facturationCtrl = this.facturations.at(idxFacturation);

    return facturationCtrl
      .getRawValue()
      .actes.map((acte: any) => acte.acte)
      .filter((acte: Acte | null | undefined) => !!acte)
      .filter(
        (acte: Acte) => acte.libelle.indexOf('Euthanasie') !== -1 || acte.libelle.indexOf('fin de vie') !== -1 || acte.libelle.indexOf('fin de vie') !== -1,
      );
  }

  private actesIdentification(idxFacturation: number): any[] {
    const facturationCtrl = this.facturations.at(idxFacturation);

    return facturationCtrl
      .getRawValue()
      .actes.map((acte: any) => acte.acte)
      .filter((acte: Acte | null | undefined) => !!acte)
      .filter((acte: Acte) => acte.libelle.indexOf('Identification') !== -1);
  }

  private actesConsultationMedical(idxFacturation: number): any[] {
    const facturationCtrl = this.facturations.at(idxFacturation);

    return facturationCtrl
      .getRawValue()
      .actes.map((acte: any) => acte.acte)
      .filter((acte: Acte | null | undefined) => !!acte)
      .filter((acte: Acte) => acte.libelle.indexOf('Consultation Médicale') !== -1);
  }

  private actesVaccination(idxFacturation: number): any[] {
    const facturationCtrl = this.facturations.at(idxFacturation);

    return facturationCtrl
      .getRawValue()
      .actes.map((acte: any) => acte.acte)
      .filter((acte: Acte | null | undefined) => !!acte)
      .filter((acte: Acte) => acte.libelle.indexOf('vaccinale') !== -1 || acte.libelle.indexOf('vaccin') !== -1);
  }

  async showModalDeces(idxFacturation: number) {
    const infosModal = await this.modalCtrl.create({
      component: DecesModalComponent,
      cssClass: 'deces-modal-size',
      componentProps: {
        animal: this.prestation?.animal,
        client: this.client,
        rdv: this.rendezvous,
      },
    });

    infosModal.onDidDismiss().then(result => {
      if (result.data) {
        this.confirmFinalisation(idxFacturation);
      }
    });
    await infosModal.present();
  }

  async showModalVaccin(idxFacturation: number) {
    const infosModal = await this.modalCtrl.create({
      component: VaccinModalComponent,
      cssClass: 'vaccin-modal-size',
      componentProps: {
        animal: this.prestation?.animal,
        client: this.rendezvous!.client,
        rdv: this.rendezvous,
      },
    });

    infosModal.onDidDismiss().then(result => {
      if (result.data) {
        this.confirmFinalisation(idxFacturation);
      }
    });
    await infosModal.present();
  }

  confirmFinalisation(idxFacturation: number) {
    this.alertController
      .create({
        header: 'Attention',
        message: 'Cette facture ne sera plus modifiable suite à cette validation.',
        buttons: [
          {
            text: 'Annuler',
            handler: () => {
              return;
            },
          },
          {
            text: 'Finaliser',
            handler: () => {
              this.form.markAsPristine({ onlySelf: true });
              const facturationCtrl = this.facturations.at(idxFacturation);

              facturationCtrl.get('etat')!.setValue(EtatFacturationEnum.FINALISEE, { emitEvent: false });

              facturationCtrl.disable({ emitEvent: false });

              this.changeDetectorRef.markForCheck();

              this.store.dispatch(
                ProduitActions.updatefacturationstate({
                  idPrestation: this.prestation!.id!,
                  idFacturation: facturationCtrl.get('id')!.value,
                  etat: EtatFacturationEnum.FINALISEE,
                  idxFacturation: idxFacturation,
                }),
              );
            },
          },
        ],
      })
      .then(res => {
        res.present();
      });
  }

  private loadFacturations(facturations: Facturation[]) {
    const facturationsSorted: Facturation[] = !facturations
      ? []
      : [...facturations]?.sort((f1, f2) => {
          if (f1.idx === undefined && f2.idx !== undefined) return 1;
          if (f2.idx === undefined && f1.idx !== undefined) return -1;
          if (f1.idx !== undefined && f2.idx !== undefined) return f1.idx > f2.idx ? 1 : -1;

          return 0;
        });
    if (facturationsSorted && facturationsSorted.length > 0) {
      this.facturations.clear();
      facturationsSorted.forEach((facturation: Facturation) => {
        this.loadFactu(facturation);
      });
    }
  }

  private loadFactu(facturation: Facturation) {
    const newFacturation = this.fb.group({
      id: [facturation.id],
      etat: [facturation.etat],
      idFacture: [facturation.idFacture],
      idsProduitsPrescritsSupprimes: [facturation.idsProduitsPrescritsSupprimes || []],
      idDevis: [facturation.idDevis],
      actes: this.fb.array([]),
      produits: this.fb.array([]),
      total: [0, [Validators.min(0.01)]],
    });
    if (facturation.produitsDelivres) {
      [...facturation.produitsDelivres]
        ?.sort((p1, p2) => (p1.index !== undefined && p2.index !== undefined ? (p1.index > p2.index ? 1 : -1) : 1))
        .forEach(produit => {
          const produitCtrl = this.fb.group({
            produit: [produit.produit],
            produitSearch: [produit.produit?.nom ? FacturationService.getProduitName(produit.produit) : produit.nom, [Validators.required]],
            quantity: [produit.quantiteUvc, [Validators.required]],
            numLot: [produit.numLot],
            idProduitPrescrit: [produit.idProduitPrescrit],
            priceHT: [
              {
                value: BigNumber(produit.tarifHT).decimalPlaces(2).toNumber(),
                disabled: true,
              },
              [Validators.required],
            ],
            priceHTInitial: [
              {
                value: BigNumber(produit.produit?.tarifVenteHT ? produit.produit.tarifVenteHT : produit.tarifHT)
                  .decimalPlaces(2)
                  .toNumber(),
                disabled: true,
              },
            ],
            remise: [produit.remise ? produit.remise : 0, [Validators.min(0), Validators.max(100)]],
            tva: [{ value: produit.produit?.tva ? produit.produit.tva : 20, disabled: true }, [Validators.required]],
            priceTTC: [0, [Validators.required]],
          });
          produitCtrl.get('priceTTC')!.setValue(this.computeTarifTTC(produitCtrl));

          if (produit.produit?.tarifTotalMinHT) {
            produitCtrl.get('priceTTC')!.addValidators([
              Validators.min(
                BigNumber(produit.produit.tarifTotalMinHT)
                  .decimalPlaces(2)
                  .multipliedBy(this.CENT.plus(produit.produit.tva ? BigNumber(produit.produit.tva) : BigNumber(20)))
                  .dividedBy(this.CENT)
                  .decimalPlaces(2)
                  .toNumber(),
              ),
            ]);
          }
          (newFacturation.get('produits')! as FormArray).push(produitCtrl);
        });
    }
    if (facturation.actesRealises) {
      [...facturation.actesRealises]
        ?.sort((a1, a2) => (a1.index !== undefined && a2.index !== undefined ? (a1.index > a2.index ? 1 : -1) : 1))
        ?.forEach(acte => {
          const acteCtrl = this.fb.group({
            acte: [acte.acte],
            acteSearch: [acte.nom ? acte.nom : acte.acte?.libelle, [Validators.required]],
            quantity: [acte.quantite, [Validators.required]],
            priceHT: [
              {
                value: BigNumber(acte.tarifHT).decimalPlaces(2).toNumber(),
                disabled: true,
              },
              [Validators.required],
            ],
            priceHTInitial: [
              {
                value: BigNumber(acte.acte?.tarif ? acte.acte.tarif : acte.tarifHT)
                  .decimalPlaces(2)
                  .toNumber(),
                disabled: true,
              },
            ],
            remise: [acte.remise ? acte.remise : 0, [Validators.min(0), Validators.max(100)]],
            tva: [{ value: 20, disabled: true }, [Validators.required]],
            priceTTC: [0, [Validators.required]],
          });

          acteCtrl.get('priceTTC')!.setValue(this.computeTarifTTC(acteCtrl));
          const tva = BigNumber(1.2);

          if (acte.acte?.tarifMaxHT && acte.acte?.tarifMaxHT !== acte.acte?.tarifMinHT) {
            // const max = Math.round(BigNumber(acte.acte.tarifMaxHT).decimalPlaces(2).multipliedBy(tva).toNumber());
            const max = Math.round(
              BigNumber(acte.acte.tarifMaxHT)
                .decimalPlaces(2)
                .multipliedBy(BigNumber(acte.quantite || 1))
                .multipliedBy(tva)
                .toNumber(),
            );
            acteCtrl.get('priceTTC')!.addValidators([Validators.max(max)]);
          }
          (newFacturation.get('actes')! as FormArray).push(acteCtrl);
        });
    }

    newFacturation.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(() => newFacturation.get('etat')!.value === EtatFacturationEnum.TERMINEE),
      )
      .subscribe(() => {
        newFacturation.get('etat')!.setValue(EtatFacturationEnum.EN_COURS, { emitEvent: false });
      });

    if (!this.facturationEditable || [EtatFacturationEnum.PAYEE, EtatFacturationEnum.FINALISEE].includes(facturation.etat)) {
      newFacturation.disable();
    }
    newFacturation.get('total')!.setValue(this.computeTotal(newFacturation), { emitEvent: false });

    this.facturations.push(newFacturation);

    if (!this.facturationEditable || [EtatFacturationEnum.PAYEE, EtatFacturationEnum.FINALISEE].includes(facturation.etat)) {
      this.actes(this.facturations.length - 1).controls.forEach(c => c.disable());
      this.produits(this.facturations.length - 1).controls.forEach(c => c.disable());
    } else {
      this.actes(this.facturations.length - 1).controls.forEach(c => this.subscribeToActeValueChanges(c, this.facturations.length - 1));
      this.produits(this.facturations.length - 1).controls.forEach(c => this.subscribeToProduitValueChanges(c, this.facturations.length - 1));
    }
  }

  private loadDevis(devis: Devis) {
    if (this.actes(0)?.controls?.every(p => !p.get('acteSearch')!.value) && this.produits(0)?.controls?.every(p => !p.get('produitSearch')!.value)) {
      this.facturations.removeAt(0);
    }
    const existingFactuNonFinaliseeIdx = this.facturations.controls.findIndex(f => {
      return ![EtatFacturationEnum.FINALISEE, EtatFacturationEnum.PAYEE].includes(f.get('etat')!.value) && !f.get('idDevis')!.value;
    });
    const existingFactuNonFinalisee = existingFactuNonFinaliseeIdx !== undefined ? this.facturations.controls.at(existingFactuNonFinaliseeIdx) : undefined;
    const devisCtrl = existingFactuNonFinalisee
      ? existingFactuNonFinalisee
      : this.fb.group({
          id: [null],
          etat: [EtatFacturationEnum.EN_COURS],
          idFacture: [null],
          idsProduitsPrescritsSupprimes: [[]],
          idDevis: [[]],
          actes: this.fb.array([]),
          produits: this.fb.array([]),
          total: [0, [Validators.min(0.01)]],
        });
    if (devis.produitsDelivres) {
      [...devis.produitsDelivres]
        ?.sort((p1, p2) => (p1.index !== undefined && p2.index !== undefined ? (p1.index > p2.index ? 1 : -1) : 1))
        ?.forEach(produit => {
          const alreadyHasProduit = (devisCtrl.get('produits')! as FormArray).controls.some(
            p => p.get('produitSearch')!.value === FacturationService.getProduitName(produit),
          );
          if (!alreadyHasProduit) {
            const produitCtrl = this.fb.group({
              produit: [produit.produit],
              produitSearch: [produit.produit?.nom ? FacturationService.getProduitName(produit.produit) : produit.nom, [Validators.required]],
              quantity: [produit.quantiteUvc, [Validators.required]],
              numLot: [produit.numLot],
              idProduitPrescrit: [produit.idProduitPrescrit],
              priceHT: [
                {
                  value: BigNumber(produit.tarifHT).decimalPlaces(2).toNumber(),
                  disabled: true,
                },
                [Validators.required],
              ],
              priceHTInitial: [
                {
                  value: BigNumber(produit.produit?.tarifVenteHT ? produit.produit.tarifVenteHT : produit.tarifHT)
                    .decimalPlaces(2)
                    .toNumber(),
                  disabled: true,
                },
              ],
              remise: [produit.remise ? produit.remise : 0, [Validators.min(0), Validators.max(100)]],
              tva: [{ value: produit.produit?.tva ? produit.produit.tva : 20, disabled: true }, [Validators.required]],
              priceTTC: [0, [Validators.required]],
            });
            produitCtrl.get('priceTTC')!.setValue(this.computeTarifTTC(produitCtrl));

            if (produit.produit?.tarifTotalMinHT) {
              produitCtrl.get('priceTTC')!.addValidators([
                Validators.min(
                  BigNumber(produit.produit.tarifTotalMinHT)
                    .decimalPlaces(2)
                    .multipliedBy(this.CENT.plus(produit.produit.tva ? BigNumber(produit.produit.tva) : BigNumber(20)))
                    .dividedBy(this.CENT)
                    .decimalPlaces(2)
                    .toNumber(),
                ),
              ]);
            }
            (devisCtrl.get('produits')! as FormArray).push(produitCtrl);
            this.subscribeToProduitValueChanges(
              produitCtrl,
              existingFactuNonFinaliseeIdx !== undefined ? existingFactuNonFinaliseeIdx : this.facturations.length - 1,
            );
          }
        });
    }
    if (devis.actesRealises) {
      [...devis.actesRealises]
        ?.sort((a1, a2) => (a1.index !== undefined && a2.index !== undefined ? (a1.index > a2.index ? 1 : -1) : 1))
        ?.forEach(acte => {
          const alreadyHasActe = (devisCtrl.get('actes')! as FormArray).controls.some(p => p.get('acteSearch')!.value === acte.nom);
          if (!alreadyHasActe) {
            const acteCtrl = this.fb.group({
              acte: [acte.acte],
              acteSearch: [acte.nom ? acte.nom : acte.acte?.libelle, [Validators.required]],
              quantity: [acte.quantite, [Validators.required]],
              priceHT: [
                {
                  value: BigNumber(acte.tarifHT).decimalPlaces(2).toNumber(),
                  disabled: true,
                },
                [Validators.required],
              ],
              priceHTInitial: [
                {
                  value: BigNumber(acte.acte?.tarif ? acte.acte.tarif : acte.tarifHT)
                    .decimalPlaces(2)
                    .toNumber(),
                  disabled: true,
                },
              ],
              remise: [acte.remise ? acte.remise : 0, [Validators.min(0), Validators.max(100)]],
              tva: [{ value: 20, disabled: true }, [Validators.required]],
              priceTTC: [0, [Validators.required]],
            });

            acteCtrl.get('priceTTC')!.setValue(this.computeTarifTTC(acteCtrl));
            const tva = BigNumber(1.2);

            if (acte.acte?.tarifMaxHT && acte.acte?.tarifMaxHT !== acte.acte?.tarifMinHT) {
              const max = Math.round(
                BigNumber(acte.acte.tarifMaxHT)
                  .decimalPlaces(2)
                  .multipliedBy(BigNumber(acte.quantite || 1))
                  .multipliedBy(tva)
                  .toNumber(),
              );
              acteCtrl.get('priceTTC')!.addValidators([Validators.max(max)]);
            }

            (devisCtrl.get('actes')! as FormArray).push(acteCtrl);

            this.subscribeToActeValueChanges(
              acteCtrl,
              existingFactuNonFinaliseeIdx !== undefined ? existingFactuNonFinaliseeIdx : this.facturations.length - 1,
            );
          }
        });
    }
    if (!existingFactuNonFinalisee) {
      devisCtrl.valueChanges
        .pipe(
          takeUntil(this.destroy$),
          filter(() => devisCtrl.get('etat')!.value === EtatFacturationEnum.TERMINEE),
        )
        .subscribe(() => devisCtrl.get('etat')!.setValue(EtatFacturationEnum.EN_COURS, { emitEvent: false }));
      devisCtrl.get('total')!.setValue(this.computeTotal(devisCtrl), { emitEvent: false });

      this.facturations.push(devisCtrl);
    } else {
      existingFactuNonFinalisee.get('total')!.setValue(this.computeTotal(existingFactuNonFinalisee), { emitEvent: false });
    }

    devis.idPrestation = this.prestation?.id;
    devis.idRdvDomicile = this.rendezvous?.id;
    devis.idStructure = this.idStructure;
    if (existingFactuNonFinalisee && existingFactuNonFinalisee.get('id')!.value) {
      devis.idFacturation = existingFactuNonFinalisee.get('id')!.value;
    }
    this.devisService.saveDevis(devis).subscribe(d => (this.devisList = this.devisList.map(existingDevis => (existingDevis.id === d.id ? d : existingDevis))));
    if (devisCtrl.get('idDevis')!.value && !devisCtrl.get('idDevis')!.value.includes(devis.id)) {
      devisCtrl.get('idDevis')!.setValue([...devisCtrl.get('idDevis')!.value, devis.id]);
    } else {
      devisCtrl.get('idDevis')!.setValue([devis.id]);
    }
    this.changeDetectorRef.markForCheck();
  }

  async appliquerDevis() {
    const modal = await this.modalCtrl.create({
      component: ChoixDevisPage,
      cssClass: 'choix-devis-modal',
      componentProps: {
        devisList: this.devisList.filter(d => ![EtatDevisEnum.FACTURE, EtatDevisEnum.REFUSE].includes(d.etat)),
        animaux: this.rendezvous?.animaux,
      },
    });

    await modal.present();

    modal.onDidDismiss().then(data => {
      if (data.data) {
        this.logService.log(Context.RDV_FACTURE, Action.APPLIQUER_DEVIS, TypeLog.INFO, data.data);

        this.loadDevis(data.data);
      }

      this.devisService.findByIdClient(this.client.id).subscribe(listDevis => {
        this.devisList = this.devisService.sortDevis(listDevis, this.rendezvous?.id, this.prestation?.animal?.id);
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  public isMinPriceSelected(p: any): boolean {
    let priceTTC = p.get('priceTTC');

    let produit = p.get('produit');

    if (priceTTC && produit && produit?.value?.tarifTotalMinHT) {
      let minPriceTtc = produit!.value.tarifTotalMinHT * ((100 + (produit!.value.tva ? produit!.value.tva : 20)) / 100);

      return minPriceTtc === priceTTC.value;
    } else {
      return false;
    }
  }

  private subscribeToActeValueChanges(formGroup: any, idxFacture: number) {
    const tva = BigNumber(1.2);
    formGroup
      .get('remise')!
      .valueChanges.pipe(
        filter((value: string) => !isNaN(+value)),
        filter(() => !isNaN(+formGroup.get('quantity')!.value)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        formGroup.get('priceTTC')!.setValidators([Validators.required]);
        formGroup.get('priceTTC')!.setValue(this.computeTarifTTC(formGroup), { emitEvent: false, onlySelf: true });
        this.facturations
          .at(idxFacture)
          .get('total')!
          .setValue(this.computeTotal(this.facturations.at(idxFacture)!), { emitEvent: false });
      });

    formGroup
      .get('priceTTC')!
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        formGroup.get('remise')!.setValue(this.computeRemise(formGroup), { emitEvent: false, onlySelf: true });
        formGroup.get('priceHT')!.setValue(this.computeTarifHT(formGroup), { emitEvent: false, onlySelf: true });
        formGroup.get('priceTTC')!.setValue(this.computeTarifTTC(formGroup), { emitEvent: false, onlySelf: true });
        this.facturations
          .at(idxFacture)
          .get('total')!
          .setValue(this.computeTotal(this.facturations.at(idxFacture)!), { emitEvent: false });
      });
    formGroup
      .get('quantity')!
      .valueChanges.pipe(
        filter((value: string) => !isNaN(+value)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (formGroup.get('acte')!.value?.tarifMaxHT && formGroup.get('acte')!.value?.tarifMaxHT !== formGroup.get('acte')!.value?.tarifMinHT) {
          formGroup.get('priceTTC')!.setValidators([
            Validators.required,
            Validators.max(
              Math.round(
                BigNumber(formGroup.get('acte')!.value.tarifMaxHT)
                  .decimalPlaces(2)
                  .multipliedBy(BigNumber(formGroup.get('quantity')!.value))
                  .multipliedBy(tva)
                  .toNumber(),
              ),
            ),
          ]);
        }
        formGroup.get('priceTTC')!.setValue(this.computeTarifTTC(formGroup), { emitEvent: false, onlySelf: true });
        this.facturations
          .at(idxFacture)
          .get('total')!
          .setValue(this.computeTotal(this.facturations.at(idxFacture)!), { emitEvent: false });
      });
    formGroup
      .get('acteSearch')!
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        filter(value => value === ''),
      )
      .subscribe(() => {
        formGroup.reset();
        formGroup.patchValue({ quantity: 1, remise: 0, priceHT: 0, priceHTInitial: 0, tva: 20, priceTTC: 0 });
        this.currentIndexForActeSearch = -1;
        this.currentFacturationForActeSearch = -1;
        this.facturations
          .at(idxFacture)
          .get('total')!
          .setValue(this.computeTotal(this.facturations.at(idxFacture)!), { emitEvent: false });
      });
  }

  private subscribeToProduitValueChanges(formGroup: any, idxFacture: number) {
    formGroup
      .get('remise')!
      .valueChanges.pipe(
        filter((value: string) => !isNaN(+value)),
        filter(() => !isNaN(+formGroup.get('quantity')!.value)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        formGroup.get('priceTTC')!.setValue(this.computeTarifTTC(formGroup), { emitEvent: false, onlySelf: true });
        this.facturations
          .at(idxFacture)
          .get('total')!
          .setValue(this.computeTotal(this.facturations.at(idxFacture)!), { emitEvent: false });
      });
    formGroup
      .get('quantity')!
      .valueChanges.pipe(
        filter((value: string) => !isNaN(+value)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (formGroup.get('priceHTInitial')!.value) {
          formGroup.get('priceHT')!.setValue(BigNumber(formGroup.get('priceHTInitial')!.value).decimalPlaces(2).toNumber(), {
            emitEvent: false,
            onlySelf: true,
          });
        }
        formGroup.get('priceTTC')!.setValue(this.computeTarifTTC(formGroup), { emitEvent: false, onlySelf: true });
        this.facturations
          .at(idxFacture)
          .get('total')!
          .setValue(this.computeTotal(this.facturations.at(idxFacture)!), { emitEvent: false });
      });

    formGroup
      .get('priceTTC')!
      .valueChanges.pipe(
        filter(() => !isNaN(+formGroup.get('quantity')!.value)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const remise = this.computeRemise(formGroup);
        formGroup.get('remise')!.setValue(remise, { emitEvent: false, onlySelf: true });
        formGroup.get('priceHT')!.setValue(this.computeTarifHT(formGroup), { emitEvent: false, onlySelf: true });
        formGroup.get('priceTTC')!.setValue(this.computeTarifTTC(formGroup), { emitEvent: false, onlySelf: true });
        this.facturations
          .at(idxFacture)
          .get('total')!
          .setValue(this.computeTotal(this.facturations.at(idxFacture)!), { emitEvent: false });
      });

    formGroup
      .get('produitSearch')!
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        filter(value => value === ''),
      )
      .subscribe(() => {
        formGroup.reset();
        formGroup.patchValue({ quantity: 1, remise: 0, priceHT: 0, priceHTInitial: 0, tva: 20, priceTTC: 0 });
        this.currentIndexForProduitSearch = -1;
        this.currentFacturationForProduitSearch = -1;
        this.facturations
          .at(idxFacture)
          .get('total')!
          .setValue(this.computeTotal(this.facturations.at(idxFacture)!), { emitEvent: false });
      });
  }

  private computeRemise(acteOrProduit: any): number {
    if (acteOrProduit.get('priceTTC')!.value) {
      const tarifTTC = BigNumber(acteOrProduit.get('priceTTC')!.value).decimalPlaces(2);
      const tarifHT = BigNumber(acteOrProduit.get('priceHTInitial')!.value).decimalPlaces(2);
      const quantite = BigNumber(acteOrProduit.get('quantity')!.value).decimalPlaces(2);
      if (tarifHT.isNaN() || quantite.isNaN() || tarifTTC.isNaN() || tarifHT.isNegative() || tarifTTC.isNegative() || quantite.isNegative()) {
        return 0;
      }

      let baseTarifTTC = tarifHT
        .multipliedBy(quantite)
        .decimalPlaces(2)
        .multipliedBy(this.CENT.plus(BigNumber(acteOrProduit.get('tva')!.value)).dividedBy(this.CENT))
        .decimalPlaces(2);
      let diff = baseTarifTTC.minus(BigNumber(acteOrProduit.get('priceTTC')!.value, 10));
      if (diff.dividedBy(baseTarifTTC).multipliedBy(this.CENT).isLessThan(BigNumber(1))) {
        return 0;
      }

      return diff.dividedBy(baseTarifTTC).multipliedBy(this.CENT).decimalPlaces(2).toNumber();
    } else {
      return 0;
    }
  }

  private computeTarifTTC(acteOrProduit: any): number {
    if (acteOrProduit.get('priceHT')!.value) {
      const quantity = BigNumber(acteOrProduit.get('quantity')!.value).decimalPlaces(2);
      const tarifHT = BigNumber(acteOrProduit.get('priceHT')!.value).decimalPlaces(2);
      if (quantity.isNaN() || tarifHT.isNaN()) {
        return 0;
      }
      const qtteTarif = quantity.multipliedBy(tarifHT).decimalPlaces(2);

      if (acteOrProduit.get('remise')!.value && acteOrProduit.get('remise')!.valid && !isNaN(+acteOrProduit.get('remise')!.value)) {
        const remise = BigNumber(acteOrProduit.get('remise')!.value).decimalPlaces(2);

        return qtteTarif
          .minus(remise.multipliedBy(qtteTarif).dividedBy(100).decimalPlaces(2))
          .multipliedBy(this.CENT.plus(BigNumber(acteOrProduit.get('tva')!.value)).dividedBy(this.CENT))
          .decimalPlaces(2)
          .toNumber();
      } else {
        let tarifTotalTTC = qtteTarif
          .multipliedBy(this.CENT.plus(BigNumber(acteOrProduit.get('tva')!.value)).dividedBy(this.CENT))
          .decimalPlaces(2)
          .toNumber();
        if (acteOrProduit.get('produit') && acteOrProduit.get('produit')!.value?.tarifTotalMinHT) {
          let tarifMinTTC = BigNumber(acteOrProduit.get('produit')!.value.tarifTotalMinHT)
            .decimalPlaces(2)
            .multipliedBy(this.CENT.plus(BigNumber(acteOrProduit.get('tva')!.value)).dividedBy(this.CENT))
            .decimalPlaces(2)
            .toNumber();
          if (tarifTotalTTC < tarifMinTTC) {
            let tarifHT = BigNumber(tarifMinTTC)
              .dividedBy(quantity)
              .dividedBy(this.CENT.plus(BigNumber(acteOrProduit.get('tva')!.value)).dividedBy(this.CENT))
              .decimalPlaces(2, BigNumber.ROUND_UP) // pour éviter les cas ou on perd un centime par rapport au tarif min
              .toNumber();
            acteOrProduit.get('priceHT').setValue(tarifHT);
            // on recalcule le ttc pour éviter des rares cas d'écarts de centimes
            tarifMinTTC = quantity
              .multipliedBy(tarifHT)
              .decimalPlaces(2)
              .multipliedBy(this.CENT.plus(BigNumber(acteOrProduit.get('tva')!.value)).dividedBy(this.CENT))
              .decimalPlaces(2)
              .toNumber();

            return tarifMinTTC;
          } else {
            return tarifTotalTTC;
          }
        } else {
          return tarifTotalTTC;
        }
      }
    }

    return 0;
  }

  private computeTarifHT(acteOrProduit: any): number {
    if (acteOrProduit.get('priceTTC')!.value) {
      const quantity = BigNumber(acteOrProduit.get('quantity')!.value).decimalPlaces(2);
      const tarifTTC = BigNumber(acteOrProduit.get('priceTTC')!.value).decimalPlaces(2);
      if (quantity.isNaN() || tarifTTC.isNaN()) {
        return 0;
      }
      if (acteOrProduit.get('remise')!.value && acteOrProduit.get('remise')!.valid && !isNaN(+acteOrProduit.get('remise')!.value)) {
        return acteOrProduit.get('priceHTInitial')!.value;
      } else {
        const newTarifHT = tarifTTC
          .dividedBy(this.CENT.plus(BigNumber(acteOrProduit.get('tva')!.value)).dividedBy(this.CENT))
          .decimalPlaces(2)
          .dividedBy(quantity)
          .decimalPlaces(2)
          .toNumber();
        if (acteOrProduit.get('priceHTInitial')!.value > 0 && newTarifHT < acteOrProduit.get('priceHTInitial')!.value && quantity.isPositive()) {
          return acteOrProduit.get('priceHTInitial')!.value;
        } else {
          return newTarifHT;
        }
      }
    }

    return 0;
  }

  private getNewActe(): FormGroup {
    return this.fb.group({
      acte: [null],
      acteSearch: ['', [Validators.required]],
      priceHT: [{ value: 0, disabled: true }, [Validators.required]],
      priceHTInitial: [{ value: 0, disabled: true }],
      quantity: [1, [Validators.required, Validators.pattern('\\d+')]],
      remise: [0, [Validators.min(0), Validators.max(100)]],
      tva: [{ value: 20, disabled: true }, [Validators.required]],
      priceTTC: [0, [Validators.required, Validators.pattern('-?[\\d]+\\.?\\d{0,2}')]],
    });
  }

  private getNewProduit(): FormGroup {
    return this.fb.group({
      produit: [null],
      produitSearch: ['', [Validators.required]],
      quantity: [1, [Validators.required, Validators.pattern('-?[\\d]+\\.?\\d{0,2}')]],
      idProduitPrescrit: [null],
      numLot: [''],
      priceHT: [{ value: 0, disabled: true }, [Validators.required]],
      priceHTInitial: [{ value: 0, disabled: true }],
      remise: [0, [Validators.min(0), Validators.max(100)]],
      tva: [{ value: 20, disabled: true }, [Validators.required]],
      priceTTC: [0, [Validators.required, Validators.pattern('-?[\\d]+\\.?\\d{0,2}')]],
    });
  }

  tarifMinMax(min: number | undefined, max: number | undefined) {
    const tva = BigNumber(1.2, 10);
    if (min && max) {
      const prixMin = Math.round(BigNumber(min).decimalPlaces(2).times(tva).toNumber());
      const prixMax = Math.round(BigNumber(max).decimalPlaces(2).times(tva).toNumber());
      if (min === max) {
        return `Tarif unique : ${prixMin}€ (hors remise)`;
      }

      return `Tarif : de ${prixMin} à ${prixMax}€ (hors remise)`;
    }

    return '0€ - Erreur de prix';
  }

  resetSearch() {
    this.currentIndexForProduitSearch = -1;
    this.currentIndexForActeSearch = -1;
    this.currentFacturationForProduitSearch = -1;
    this.currentFacturationForActeSearch = -1;
  }

  setActeSearchToIndex(idxFacturation: number, i: number) {
    this.currentIndexForActeSearch = i;
    this.currentFacturationForActeSearch = idxFacturation;
    this.changeDetectorRef.markForCheck();
  }

  setProduitSearchToIndex(idxFacturation: number, i: number) {
    this.currentIndexForProduitSearch = i;
    this.currentFacturationForProduitSearch = idxFacturation;
    this.changeDetectorRef.markForCheck();
  }

  async envoyerFactureParMail(idxFacturation: number) {
    const modal = await this.modalCtrl.create({
      component: EnvoiFactureComponent,
      cssClass: 'envoi-facture-modal',
      componentProps: {
        title: this.facturations.at(idxFacturation).get('etat')!.value === EtatFacturationEnum.PAYEE ? 'Envoyer la facture' : 'Envoyer la facture non soldée',
        email: this.client.mail,
        idFacture: this.facturations.at(idxFacturation).get('idFacture')!.value,
        idFacturation: this.facturations.at(idxFacturation).get('id')!.value,
      },
    });
    await modal.present();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getNewFactu() {
    let newActe;
    if (this.displayActes) {
      newActe = this.getNewActe();
      this.subscribeToActeValueChanges(newActe, 0);
    }
    let newProduit;
    if (this.loadBlankProduit) {
      newProduit = this.getNewProduit();
      this.subscribeToProduitValueChanges(newProduit, 0);
    }

    return this.fb.group({
      id: [null],
      etat: [EtatFacturationEnum.EN_COURS],
      idFacture: [null],
      idsProduitsPrescritsSupprimes: [[]],
      idDevis: [[]],
      actes: this.fb.array(this.displayActes ? [newActe] : []),
      produits: this.fb.array(this.loadBlankProduit ? [newProduit] : []),
      total: [0, Validators.min(0.01)],
    });
  }
}
