import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'range',
})
export class RangePipe implements PipeTransform {
  constructor() {}

  transform(value: number): number[] {
    let arr = [];
    for (let i = 0; i < value + 1; i++) {
      arr.push(i);
    }

    return arr;
  }
}
