<ion-header>
  <ion-toolbar color="blue100" class="ion-text-center">
    <ion-buttons slot="start">
      <ion-button color="blue600" (click)="dismissModal()">Fermer</ion-button>
    </ion-buttons>
    <ion-title color="grey900">Anamnèse</ion-title>
    <ion-buttons slot="end">
      <ion-button color="blue100">Fermer</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div style="padding: 2rem" [innerHTML]="htmlContent"></div>
</ion-content>
