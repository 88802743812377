import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError, ApiValidationError } from 'app/models/api-error.model';
import { StatusCodes } from 'http-status-codes';

/** Api error handler */
@Injectable({
  providedIn: 'root',
})
export class ApiErrorHandlerService {
  process(errorResponse: HttpErrorResponse): ApiError {
    const detail: ApiValidationError | undefined = errorResponse.error
      ? {
          code: `ERROR.${errorResponse.error.code}`,
          message: errorResponse.error,
        }
      : undefined;
    console.log('ERREUR ! HANDLER');

    return new ApiError(this.getMessageError(errorResponse.statusText, errorResponse.status), detail);
  }

  /**
   * Get message error
   *
   * @param statusText The status text
   * @param status The error status
   */
  private getMessageError(statusText: string, status: number): string {
    switch (status) {
      case StatusCodes.BAD_REQUEST:
        return 'BAD_REQUEST';

      case StatusCodes.FORBIDDEN:
        return 'FORBIDDEN';

      case StatusCodes.NOT_FOUND:
        return 'NOT_FOUND';

      case StatusCodes.INTERNAL_SERVER_ERROR:
        return 'INTERNAL_SERVER_ERROR';

      case StatusCodes.NOT_ACCEPTABLE:
        return 'NOT_ACCEPTABLE';
      case StatusCodes.UNAUTHORIZED:
        return 'UNAUTHORIZED';

      default:
        return this.formatMessage(statusText);
    }
  }

  /**
   * Format message
   *
   * @param statusText The message to format
   */
  private formatMessage(statusText: string): string {
    let message = statusText.toLowerCase();
    message = message.replace(/\s/g, '_');

    return message;
  }
}
