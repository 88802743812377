import { Component, Input, OnDestroy } from '@angular/core';
import { from, Subject, switchMap } from 'rxjs';
import { ModalController, ToastController } from '@ionic/angular';
import { CompteRenduService } from '../../../services/compte-rendu.service';
import { Action, Context, TypeLog } from '../../../../../../models/log.model';
import { LogService } from '@core/services/log/log.service';

@Component({
  selector: 'envoi-cr',
  templateUrl: 'envoi-cr.component.html',
  styleUrls: ['envoi-cr.component.scss'],
})
export class EnvoiCrComponent implements OnDestroy {
  private readonly destroy$: Subject<void>;
  @Input() email!: string;
  @Input() idCr!: string;
  @Input() idRdv!: string;
  @Input() title!: string;
  loadingSend = false;
  hasSent = false;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly toastController: ToastController,
    private readonly compteRenduService: CompteRenduService,
    private readonly logService: LogService,
  ) {
    this.destroy$ = new Subject<void>();
  }

  cancel() {
    if (this.hasSent) {
      this.modalCtrl.dismiss(this.hasSent);
    } else {
      this.modalCtrl.dismiss();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  send() {
    this.loadingSend = true;
    this.logService.log(Context.RDV_CR, Action.ENVOI, TypeLog.INFO, {
      idCR: this.idCr,
      mail: this.email,
    });
    this.compteRenduService
      .sendCR(this.idRdv, this.idCr, this.email)
      .pipe(
        switchMap(() =>
          from(
            this.toastController.create({
              header: 'Compte-rendu envoyé',
              duration: 5000,
              message: 'Un mail a été envoyé à : ' + this.email,
              position: 'bottom',
            }),
          ),
        ),
        switchMap(toast => toast.present()),
      )
      .subscribe(() => {
        this.loadingSend = false;
        this.hasSent = true;
      });
  }

  download() {
    this.compteRenduService.getCrPDF(this.idCr).subscribe(pdf => {
      const blob = new Blob([pdf], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = 'compte-rendu_' + this.idCr + '.pdf';
      link.click();
    });
  }

  setMail($event: any) {
    this.email = $event.target.value;
  }
}
