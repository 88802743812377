import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TooltipPosition } from '../../tooltip/tooltip.model';
import { HorsRdv, HorsRdvStep } from '../../../../../models/rdv.model';
import { User } from '../../../../../models/user.model';
import { TypeHorsRdv } from '../../../../../models/interaction.model';
import { ModePaiementEnum, PaiementStatus } from '../../../../../models/paiement.model';
import { ModalController } from '@ionic/angular';
import { PreviewHorsRdvPage } from '../../../../fiche-client/components/preview-hors-rdv/preview-hors-rdv.page';
import { Veterinaire } from '../../../../../models/admin.model';
import { HorsRdvService } from '@core/services/consultations/hors-rdv.service';
import { Browser } from 'leaflet';
import mobile = Browser.mobile;

@Component({
  selector: 'app-horsrdv',
  templateUrl: './hors-rdv.component.html',
  styleUrls: ['./hors-rdv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HorsRdvComponent implements OnInit {
  @Input() horsRdv!: HorsRdv;
  @Input() vetos!: Veterinaire[];
  @Input() user!: User;
  @Input() idAnimalFilter?: string;
  @Input() displayClient = false;

  mobile = mobile;

  protected readonly position = TooltipPosition;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly horsRdvService: HorsRdvService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  protected readonly TypeHorsRdv = TypeHorsRdv;
  protected readonly PaiementStatus = PaiementStatus;
  protected readonly ModePaiementEnum = ModePaiementEnum;
  rdvStepSelectionnables: HorsRdvStep[] = [];

  ngOnInit() {
    if (this.horsRdv.structureVeterinaire && this.vetos) {
      this.vetos = this.vetos.filter(
        v =>
          v.structureVeterinaire.id === this.horsRdv.structureVeterinaire.id || v.structureVeterinaireAlternative?.id === this.horsRdv.structureVeterinaire.id,
      );
    }
    this.rdvStepSelectionnables = HorsRdvService.getStepsSelectionnables(this.horsRdv.step, this.user);
  }

  showClient(id?: string) {
    window.open(`/fiche-client/${id}`, '_blank');
  }

  async openPreview() {
    const modal = await this.modalCtrl.create({
      component: PreviewHorsRdvPage,
      cssClass: 'modal-size',
      backdropDismiss: false,
      componentProps: {
        vetos: this.vetos,
        horsRdv: this.horsRdv,
        user: this.user,
      },
    });
    modal.onDidDismiss().then(data => {
      if (data.data) {
        this.horsRdv = data.data;
        this.cd.markForCheck();
      }
    });
    await modal.present();
  }

  updateRdvState($event: any) {
    if (this.horsRdv.step !== $event.target.value) {
      this.horsRdvService.updateStep(this.horsRdv.id, $event.target.value).subscribe(() => {
        this.horsRdv = { ...this.horsRdv, step: $event.target.value };
        this.cd.markForCheck();
      });
    }
  }

  doNothing() {
    // hack pour éviter que le click sur l'accordéon ouvre le select
  }
}
