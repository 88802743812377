<ion-header class="ion-no-shadow ion-no-border">
  <ion-toolbar color="blue100" class="ion-text-center">
    <ion-buttons slot="start">
      <ion-button color="blue600" (click)="cancel()">Annuler</ion-button>
    </ion-buttons>
    <ion-title color="blue600" *ngIf="!createOnly">Ajouter un animal</ion-title>
    <ion-title color="blue600" *ngIf="createOnly">Créer un animal</ion-title>
    <ion-buttons slot="end">
      <ion-button color="blue600" [disabled]="(form.invalid && !showList) || (changeAnimalForm.invalid && showList)" (click)="confirm()">Valider </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="blue100">
  <ion-segment mode="md" [value]="currentSegment" (ionChange)="changeSegment()" class="segment-div" *ngIf="!createOnly">
    <ion-segment-button value="animal-list">
      <ion-label color="blue900">Animaux enregistrés{{ nbAnxSelectionnables !== undefined ? ' (' + nbAnxSelectionnables + ')' : '' }} </ion-label>
    </ion-segment-button>
    <ion-segment-button value="new-animal">
      <ion-label color="blue900">Créer un animal</ion-label>
    </ion-segment-button>
  </ion-segment>
  <form [formGroup]="changeAnimalForm">
    <ng-container *ngIf="showList">
      <ion-radio-group value="test" formControlName="animal" allowEmptySelection="true">
        <div class="animal-list">
          <ng-container *ngFor="let animal of animalClientList$ | async">
            <ion-item *ngIf="!isAnimalNonSelectionnable(animal)" color="blue400" lines="none">
              <!-- <ion-icon slot="start" name="paw-outline" color="blue600"></ion-icon> -->
              <ion-label class="ion-text-wrap" color="blue900">
                <h2>
                  <b>{{ animal.nom }}</b>
                </h2>
                <p>{{ animal.espece?.nom }} ({{ animal.race?.nom }})</p>
                <p>{{ animal.dateNaissance | age }}</p>
              </ion-label>
              <ion-radio slot="end" [value]="animal" color="blue900"></ion-radio>
            </ion-item>
          </ng-container>
          <div class="ion-text-center no-item">
            <p>Aucun animal disponible</p>
          </div>
        </div>
      </ion-radio-group>
    </ng-container>
  </form>

  <br />
  <form [formGroup]="form">
    <div class="formulaire animal-info" *ngIf="!showList">
      <div class="famille-1 magic-margin">
        <ion-item lines="none">
          <fieldset>
            <legend><b>Nom</b></legend>
            <ion-input formControlName="animalNom"></ion-input>
          </fieldset>
        </ion-item>
        <ion-item lines="none">
          <fieldset>
            <legend><b>Sexe</b></legend>
            <ion-select placeholder="" formControlName="animalSexe" interface="popover">
              <ion-select-option value="MALE">Mâle</ion-select-option>
              <ion-select-option value="FEMELLE">Femelle</ion-select-option>
            </ion-select>
          </fieldset>
        </ion-item>
        <ion-item lines="none">
          <fieldset>
            <legend><b>Espèce</b></legend>
            <ion-select placeholder="" interface="popover" formControlName="animalEspece" (ionChange)="getRaces($event)">
              <div *ngFor="let espece of especeList | async">
                <ion-select-option [value]="espece" color="grey900"> {{ espece.nom }}</ion-select-option>
              </div>
            </ion-select>
          </fieldset>
        </ion-item>
        <div *ngIf="race$ | async as races">
          <ion-item tappable (click)="select.open()" lines="none">
            <fieldset>
              <legend><b>Race</b></legend>
              <ion-select
                placeholder="{{ selectedRace?.nom ? selectedRace?.nom : selectedRace }}"
                [value]="selectedRace"
                interface="alert"
                formControlName="animalRace"
                [interfaceOptions]="{ cssClass: 'no-display' }"
              >
                <app-searchable-select
                  [data]="races"
                  title="Choisir une race"
                  itemTextField="nom"
                  (selectedChanged)="selectRaceChanged($event)"
                  #select
                ></app-searchable-select>
              </ion-select>
            </fieldset>
          </ion-item>
        </div>
        <ion-item lines="none" class="ion-text-left">
          <fieldset>
            <legend>
              <b>Date de naissance</b>
            </legend>
            <ion-input type="date" formControlName="animalNaissance"></ion-input>
          </fieldset>
        </ion-item>
        <ion-item lines="none">
          <fieldset>
            <legend>Croisé</legend>
            <ion-select placeholder="" interface="popover" formControlName="animalCroise">
              <ion-select-option [value]="true">Oui</ion-select-option>
              <ion-select-option [value]="false">Non</ion-select-option>
            </ion-select>
          </fieldset>
        </ion-item>
      </div>
      <div class="famille-2 magic-margin">
        <ion-item lines="none">
          <fieldset>
            <legend><b>Stérilisé</b></legend>
            <ion-select placeholder="" interface="popover" formControlName="animalSterilise">
              <ion-select-option [value]="true">Oui</ion-select-option>
              <ion-select-option [value]="false">Non</ion-select-option>
            </ion-select>
          </fieldset>
        </ion-item>
        <ion-item lines="none">
          <fieldset>
            <legend>Tatouage</legend>
            <ion-input placeholder="" formControlName="animalTatouage"></ion-input>
          </fieldset>
        </ion-item>
        <ion-item lines="none">
          <fieldset>
            <legend>Puce</legend>
            <ion-input placeholder="" formControlName="animalPuce"></ion-input>
          </fieldset>
        </ion-item>
        <ion-item lines="none">
          <fieldset>
            <legend>Poids (kg)</legend>
            <ion-input placeholder="" formControlName="animalPoids"></ion-input>
          </fieldset>
        </ion-item>
        <ion-item lines="none">
          <fieldset>
            <legend>Remarque</legend>
            <ion-input placeholder="" formControlName="animalRemarque"></ion-input>
          </fieldset>
        </ion-item>
      </div>
      <div class="ion-text-center">
        <ion-note slot="error" color="danger" *ngIf="hasDoublon"
          >Il existe déjà un animal avec ce nom et cette date de naissance, merci de vérifier que ce n'est pas un doublon.
        </ion-note>
      </div>
      <br />
    </div>
  </form>
</ion-content>
