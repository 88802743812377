import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jour',
})
export class JourPipe implements PipeTransform {
  constructor() {}

  transform(value: any): string {
    const jour = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

    return jour[parseInt(value) - 1];
  }
}
