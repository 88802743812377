import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Ordonnance } from '../../../../models/ordonnance.model';
import { EtatFacturationEnum, Facturation } from '../../../../models/rdv.model';
import { Observable, of } from 'rxjs';
import { FormArray, FormGroup } from '@angular/forms';
import { Produit, ProduitDelivre, ProduitPrescrit } from '../../../../models/produit.model';
import { sortBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FacturationService {
  constructor(private readonly httpApiService: HttpApiService) {}

  generateFacturations(ordonnances: Ordonnance[]): Observable<Facturation[]> {
    const ordonnancesTermineesAvecProduitsDelivres = ordonnances.filter(ordo => ordo.produitPrescrits?.filter(produit => produit.delivre)?.length > 0);

    return of(
      ordonnancesTermineesAvecProduitsDelivres.map((ordo: Ordonnance, idx: number) => ({
        etat: EtatFacturationEnum.EN_COURS,
        id: ordo.id,
        idx,
        idOrdonnances: [ordo.id],
        produitsDelivres: ordo.produitPrescrits
          .filter(produit => produit.delivre)
          .map(produitPrescrit => ({
            produit: produitPrescrit.produit,
            quantiteUvc: produitPrescrit.quantiteUvp,
            numLot: produitPrescrit.numLot,
            remise: 0,
            id: produitPrescrit.id,
            nom: produitPrescrit.produit?.nom ? FacturationService.getProduitName(produitPrescrit.produit) : produitPrescrit.nom!,
            tarifHT: produitPrescrit.produit?.tarifVenteHT ? produitPrescrit.produit.tarifVenteHT : 0,
          })),
      })),
    );
  }

  static areProduitsEqual(p1: ProduitDelivre | ProduitPrescrit, p2: ProduitDelivre | ProduitPrescrit): boolean {
    return p1.produit?.id === p2.produit?.id || p1.nom === p2.nom;
  }

  /**
   * @param existingFacturations les factus existantes
   * @param ordoAsFactu les factus générées par les ordonnances
   */
  mergeFacturations(existingFacturations: Facturation[], ordoAsFactu?: Facturation[]): { facturations: Facturation[]; hasModified: boolean } {
    if (!ordoAsFactu || ordoAsFactu.length <= 0) {
      return { facturations: existingFacturations || [], hasModified: false };
    }

    let facturationsToReturn: Facturation[] = sortBy(
      existingFacturations.filter(f => [EtatFacturationEnum.TERMINEE, EtatFacturationEnum.PAYEE, EtatFacturationEnum.FINALISEE].includes(f.etat)),
      'idx',
    );
    const factusModifiables: Facturation[] = sortBy(
      existingFacturations.filter(f => ![EtatFacturationEnum.PAYEE, EtatFacturationEnum.FINALISEE].includes(f.etat)),
      'idx',
    );

    const existingProduits = existingFacturations.flatMap(f => f.produitsDelivres || []);
    const produitsOrdo = ordoAsFactu.flatMap(o => o.produitsDelivres || []);
    let produitsOrdoNonPresents = ordoAsFactu
      .flatMap(o => o.produitsDelivres || [])
      .filter(
        p =>
          !existingProduits.some(p2 => p2.idProduitPrescrit === p.id || FacturationService.areProduitsEqual(p, p2)) &&
          !existingFacturations.some(e => p.id && e.idsProduitsPrescritsSupprimes && e.idsProduitsPrescritsSupprimes.includes(p.id)),
      );

    if (produitsOrdoNonPresents.length <= 0 && factusModifiables.length <= 0) {
      return { facturations: existingFacturations || [], hasModified: false };
    }

    let factuRegroupement: Facturation | undefined = undefined;
    let hasModifiedProduits = false;

    if (factusModifiables && factusModifiables.length > 0) {
      let produitsExistants = factusModifiables[0].produitsDelivres || [];
      let lengthProduitsExistants = produitsExistants.length;
      produitsExistants = produitsExistants
        .filter(p => !p.idProduitPrescrit || produitsOrdo.map(pd => pd.id).includes(p.idProduitPrescrit)) // on filtre pour ne pas garder les produits supprimés dans l'ordo
        .map(p => {
          let produitOrdo = produitsOrdo.find(p2 => p2?.id === p.idProduitPrescrit);
          if (!produitOrdo) {
            produitOrdo = produitsOrdo.find(p2 => FacturationService.areProduitsEqual(p, p2));
          }
          if (produitOrdo) {
            produitOrdo = { ...produitOrdo, idProduitPrescrit: produitOrdo.id, id: undefined };
          }

          return produitOrdo || p;
        });
      produitsOrdoNonPresents = produitsOrdoNonPresents.map(p => ({ ...p, idProduitPrescrit: p.id, id: undefined }));
      hasModifiedProduits = produitsExistants.length !== lengthProduitsExistants || produitsOrdoNonPresents.length > 0;
      produitsExistants = [...produitsExistants, ...produitsOrdoNonPresents];
      factuRegroupement = {
        ...factusModifiables[0],
        produitsDelivres: produitsExistants,
      };
    } else if (produitsOrdoNonPresents.length > 0) {
      produitsOrdoNonPresents = produitsOrdoNonPresents.map(p => ({ ...p, idProduitPrescrit: p.id, id: undefined }));
      hasModifiedProduits = true;

      factuRegroupement = {
        etat: EtatFacturationEnum.EN_COURS,
        produitsDelivres: produitsOrdoNonPresents,
      };
    }

    if (factuRegroupement) {
      facturationsToReturn = facturationsToReturn.filter(f => factuRegroupement != undefined && f.idx !== factuRegroupement.idx);
      facturationsToReturn = [...facturationsToReturn, factuRegroupement];
    }

    return { facturations: facturationsToReturn, hasModified: hasModifiedProduits };
  }

  deleteFacturation(id: string): Observable<void> {
    return this.httpApiService.delete(`api/back_office/facturation/${id}`);
  }

  saveFacturation(facturation: Facturation, idPrestation: string): Observable<Facturation> {
    return this.httpApiService.post<Facturation>(`api/back_office/facturation/${idPrestation}`, facturation);
  }

  saveFacturations(facturations: Facturation[], idPrestation: string) {
    return this.httpApiService.post<Facturation[]>(`api/back_office/facturation/${idPrestation}/batch`, facturations);
  }

  envoyerFactureParMail(idFacturation: string, email: string): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/facturation/${idFacturation}/envoyerFactureParMail`, email);
  }

  updateFacturationState(idPrestation: string, idFacturation: string, etat: EtatFacturationEnum) {
    return this.httpApiService.post<Facturation>(`api/back_office/facturation/${idPrestation}/${idFacturation}/etat/${etat}`);
  }

  static getProduitName(p: Produit): string {
    return `${p.nom} ${p.unite || p.packaging ? '(' + (p.unite ? p.unite.toLowerCase() : p.packaging) + ')' : ''}`;
  }

  static buildFacturations(facturations: FormArray): Facturation[] {
    return facturations.controls.map((value, index) => FacturationService.buildFacturation(value as FormGroup, index));
  }

  static buildFacturation(facturationCtrl: FormGroup, idx: number): Facturation {
    const value = facturationCtrl.getRawValue();

    return {
      id: value.id === '' ? undefined : value.id,
      idx,
      idFacture: value.idFacture === '' ? undefined : value.idFacture,
      idsProduitsPrescritsSupprimes: value.idsProduitsPrescritsSupprimes || [],
      idDevis: value.idDevis,
      etat: value.etat,
      montant: value.total,
      actesRealises: value.actes
        .filter((acte: any) => acte.acteSearch !== '')
        .sort((acte: any) => (acte.acte?.consultation ? -1 : 1))
        .map((acte: any, idx: number) => ({
          nom: acte.acteSearch,
          acte: acte.acte,
          description: acte.acte?.description,
          tarifHT: acte.priceHT,
          remise: acte.remise,
          quantite: acte.quantity,
          index: idx * 100,
        })),
      produitsDelivres: value.produits
        .filter((prod: any) => prod.produitSearch !== '')
        .map((produit: any, idx: number) => ({
          nom: produit.produitSearch,
          produit: produit.produit,
          tarifHT: produit.priceHT,
          idProduitPrescrit: produit.idProduitPrescrit,
          remise: produit.remise,
          quantiteUvc: produit.quantity,
          numLot: produit.numLot,
          index: idx * 100,
        })),
    };
  }
}
