<ion-header class="ion-no-border ion-no-shadow">
  <ion-toolbar color="blue200">
    <ion-title class="welcome stats ion-text-center">Décès</ion-title>
    <ion-button shape="round" slot="start" fill="clear" (click)="annuler()" color="danger">
      <ion-icon slot="start" name="close-outline"></ion-icon>
      <ion-label>Annuler</ion-label>
    </ion-button>

    <ion-button slot="end" shape="round" (click)="confirm()" color="done">
      <!-- <ion-icon name="add-circle-outline" slot="start"></ion-icon> -->
      <ion-icon slot="start" name="save-outline"></ion-icon>

      <ion-label>Valider</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-header>
<ng-template #loading>
  <ion-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <div class="ion-text-center">
            <ion-spinner name="crescent"></ion-spinner>
            <ion-text color="medium">
              <p>Chargement des informations</p>
            </ion-text>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ng-template>
<ion-content color="blue100" *ngIf="animal; else loading">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label class="ion-text-wrap">Décès confirmé</ion-label>
          <ion-toggle [(ngModel)]="deces"></ion-toggle>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label class="ion-text-wrap">Envoyer un mail de condoléance</ion-label>
          <ion-toggle [(ngModel)]="envoiCondoleance"></ion-toggle>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
