<div *ngIf="selected.length; else placeholder">
  <span *ngFor="let item of selected; let last = last; trackBy: trackById"> {{ leaf(item, itemTextField) + last ? '' : ', ' }} </span>
</div>
<ng-template #placeholder> Aucune</ng-template>

<ion-modal [isOpen]="isOpen" [cssClass]="'searchable-select-modal-' + size" (didDismiss)="cancel()">
  <ng-template>
    <ion-header>
      <ion-toolbar color="blue100">
        <ion-buttons slot="start">
          <ion-button color="blue600" (click)="cancel()">Annuler</ion-button>
        </ion-buttons>
        <ion-buttons slot="start">
          <ion-button color="blue600" (click)="reinit()">Retirer filtre</ion-button>
        </ion-buttons>
        <ion-title color="blue600" *ngIf="!mobile">{{ title }}</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar (ionChange)="filter($any($event))" placeholder="Rechercher"></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content color="light" class="items">
      <cdk-virtual-scroll-viewport itemSize="50" minBufferPx="900" maxBufferPx="1350">
        <ion-list>
          <ion-item *cdkVirtualFor="let item of filtered; trackBy: trackById" (click)="itemSelect(item)" class="item-list-item" button>
            <ion-label class="ion-text-wrap">
              <h2>{{ leaf(item, itemTextField) }}</h2>
              <p class="ion-text-wrap item-list-additional-label" *ngIf="additionalField">
                {{ leaf(item, additionalField) }}
              </p>
            </ion-label>
          </ion-item>
        </ion-list>
      </cdk-virtual-scroll-viewport>
    </ion-content>
  </ng-template>
</ion-modal>
