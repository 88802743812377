import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRendezvous from '../reducers/rdv-domicile.reducer';
import * as fromPrestation from '../reducers/prestation.reducer';

export const selectRendezvousState = createFeatureSelector<fromRendezvous.RdvDomicileState>('rendezvous');
export const selectPrestationState = createFeatureSelector<fromPrestation.PrestationState>('prestation');
// rendezvous
export const selectAllRendezvous = createSelector(selectRendezvousState, (state: fromRendezvous.RdvDomicileState) => state.rdvList);

export const selectActiveRendezvous = createSelector(selectRendezvousState, (state: fromRendezvous.RdvDomicileState) => state.activeRdv);
export const selectActiveRendezvousEditable = createSelector(selectRendezvousState, (state: fromRendezvous.RdvDomicileState) => state.activeRdvEditable);
export const selectActiveRendezvousFactuEditable = createSelector(selectRendezvousState, (state: fromRendezvous.RdvDomicileState) => state.activeFactuEditable);

//client
export const selectActiveClient = createSelector(selectRendezvousState, (state: fromRendezvous.RdvDomicileState) => state.activeClient);

// animal
export const selectAllRaces = createSelector(selectRendezvousState, (state: fromRendezvous.RdvDomicileState) => state.races);

export const selectAllEspece = createSelector(selectRendezvousState, (state: fromRendezvous.RdvDomicileState) => state.especes);

export const selectActiveAnimal = createSelector(selectRendezvousState, (state: fromRendezvous.RdvDomicileState) => state.activeAnimal);

export const selectAnimalClientList = createSelector(selectRendezvousState, (state: fromRendezvous.RdvDomicileState) => state.animalClientList);
// Prestation
export const selectActivePrestation = createSelector(selectPrestationState, (state: fromPrestation.PrestationState) => state.activePrestation);
