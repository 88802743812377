import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { distinctUntilChanged, Observable } from 'rxjs';
import { UserApiActions } from '@core/store/actions/user.actions';
import { Store } from '@ngrx/store';
import { selectLoading, selectLoginError } from '@core/store/selector/session.selectors';
import { SessionLoading } from '@core/store/reducers/session.reducer';
import { UserService } from '@core/services/user/user.service';
import { Groupe } from '../../../../models/user.model';
import { AlertController } from '@ionic/angular';
import { AlertInput } from '@ionic/core/dist/types/components/alert/alert-interface';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPage implements OnInit {
  loginError$!: Observable<string | undefined>;
  loginLoading$!: Observable<boolean>;
  loginForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });
  showPassword = false;
  @ViewChild('passwordShow', { read: ElementRef }) passwordShow!: ElementRef;

  constructor(
    private readonly formBuilder: NonNullableFormBuilder,
    private readonly store: Store,
    private readonly userService: UserService,
    private readonly alertCtrl: AlertController,
  ) {}

  ngOnInit(): void {
    this.loginError$ = this.store.select(selectLoginError).pipe(distinctUntilChanged());
    this.loginLoading$ = this.store.select(selectLoading({ loadingType: SessionLoading.login })).pipe(distinctUntilChanged());
  }

  async submit() {
    const email = this.loginForm.value.email?.toLowerCase();
    const pw = this.loginForm.value.password;
    if (email && pw) {
      this.userService.getGroupes({ email: email, password: '', from: 'BACK_OFFICE', groupe: { codeGroupe: 'UNKNOW' } }).subscribe(async groupes => {
        let groupe: Groupe = { codeGroupe: 'UNKNOW' };
        if (groupes.length == 1) {
          groupe = groupes[0];
          this.store.dispatch(
            UserApiActions.login({
              loginData: {
                email: email,
                password: pw,
                from: 'BACK_OFFICE',
                groupe,
              },
            }),
          );
        } else if (groupes.length > 1) {
          const inputs: AlertInput[] = groupes.map(groupe => {
            return { label: groupe?.codeGroupe, type: 'radio', value: groupe };
          });
          const alert = await this.alertCtrl.create({
            header: 'Selectionnez votre groupe.',
            inputs: inputs,
            buttons: [
              {
                text: 'Ok',
                handler: alertData => {
                  groupe = alertData;
                  this.store.dispatch(
                    UserApiActions.login({
                      loginData: {
                        email: email,
                        password: pw,
                        from: 'BACK_OFFICE',
                        groupe,
                      },
                    }),
                  );
                },
              },
            ],
          });
          await alert.present();
        } else {
          this.store.dispatch(
            UserApiActions.login({
              loginData: {
                email: email,
                password: pw,
                from: 'BACK_OFFICE',
                groupe,
              },
            }),
          );
        }
      });
    }
  }

  toggleShow() {
    this.showPassword = !this.showPassword;
    this.passwordShow.nativeElement.type = this.showPassword ? 'text' : 'password';
  }
}
