<ion-header>
  <ion-toolbar color="blue200">
    <ion-title class="ion-text-wrap">
      <ion-label class="ion-text-wrap" color="grey900">Détail doublon</ion-label>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeModal()">
        <ion-label color="blue600">Fermer</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="blue100">
  <div class="center">
    <div class="box">
      <div class="invert gestion">
        <div class="welcome">
          <ion-item lines="none" color="blue200">
            <ion-label class="ion-text-wrap">
              <ion-text color="grey900">
                <h1>
                  {{ isAnimal(detailProps) ? (detailProps.nom | titlecase) : (detailProps.prenom | titlecase) + ' ' + (detailProps.nom | titlecase) }}
                </h1>
              </ion-text>
            </ion-label>
          </ion-item>
        </div>
        <div class="grid">
          <ng-container *ngIf="isAnimal(detailProps)">
            <div class="profil">
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Date de naissance</b></h2>
                  <p>{{ detailProps.dateNaissance | date: 'mediumDate' | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Id Vétophonie</b></h2>
                  <p>{{ detailProps.idVetophonie | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Poids</b></h2>
                  <p>{{ detailProps.poids ? detailProps.poids + 'kg' : '-' }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Décédé</b></h2>
                  <p>{{ detailProps.decede ? 'Oui' : 'Non' }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Couleur</b></h2>
                  <p>{{ detailProps.couleur | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Mail utilisateur</b></h2>
                  <p>{{ detailProps.user?.mail | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Nb prestations</b></h2>
                  <p>{{ detailProps.nbPrestations }}</p>
                </ion-label>
              </ion-item>

              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Nom</b></h2>
                  <p>{{ detailProps.nom }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Croisé</b></h2>
                  <p>{{ detailProps.croise ? 'Oui' : 'Non' }}</p>
                </ion-label>
              </ion-item>
            </div>
            <div class="profil">
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Espèce</b></h2>
                  <p>{{ detailProps.espece?.nom | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Race</b></h2>
                  <p>{{ detailProps.race?.nom | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Puce</b></h2>
                  <p>{{ detailProps.puce | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Tatouage</b></h2>
                  <p>{{ detailProps.tatouage | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Sexe</b></h2>
                  <p>{{ detailProps.sexe | sexe }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Date création</b></h2>
                  <p>{{ detailProps.createDateTime | date: 'yyyy-MM-dd HH:mm:ss' }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Commentaire</b></h2>
                  <p>{{ detailProps.commentaire | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Stérilisé</b></h2>
                  <p>{{ detailProps.sterilise ? 'Oui' : 'Non' }}</p>
                </ion-label>
              </ion-item>
            </div>
          </ng-container>
          <ng-container *ngIf="isUser(detailProps)">
            <div class="profil">
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Téléphone</b></h2>
                  <p>{{ detailProps.telephone | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Téléphone 2</b></h2>
                  <p>{{ detailProps.telephone2 | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Adresse</b></h2>
                  <p>{{ detailProps.adresse?.adresse | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Code postal</b></h2>
                  <p>{{ detailProps.adresse?.codePostal | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Ville</b></h2>
                  <p>{{ detailProps.adresse?.ville | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Id Vétophonie</b></h2>
                  <p>{{ detailProps.idVetophonie | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Nb animaux</b></h2>
                  <p>{{ detailProps.nbAnimaux }}</p>
                </ion-label>
              </ion-item>
            </div>
            <div class="profil">
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Vérifié</b></h2>
                  <p>{{ detailProps.verifie ? 'Oui' : 'Non' }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Nom 2</b></h2>
                  <p>{{ detailProps.nom2 | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Prenom 2</b></h2>
                  <p>{{ detailProps.prenom2 | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Mail</b></h2>
                  <p>{{ detailProps.mail | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Mail 2</b></h2>
                  <p>{{ detailProps.mail2 | defaultValue }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Date création</b></h2>
                  <p>{{ detailProps.createDateTime | date: 'yyyy-MM-dd HH:mm:ss' }}</p>
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label color="grey900">
                  <h2><b>Nb prestations</b></h2>
                  <p>{{ detailProps.nbPrestations }}</p>
                </ion-label>
              </ion-item>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="isAnimal(detailProps)">
          <ion-list>
            <ng-container *ngFor="let animalSource of detailProps.animalSource | orderBy: 'nom'; let idx = index">
              <div class="invert gestion">
                <div class="welcome">
                  <div class="doublon-header">
                    <ion-item color="yellow200" lines="none">
                      <ion-label class="ion-text-wrap">
                        <ion-text color="grey900">
                          <h1>Doublon : {{ animalSource.nom | titlecase }}</h1>
                        </ion-text>
                      </ion-label>
                    </ion-item>
                    <div class="doublon-header-btns">
                      <ion-button shape="round" slot="end" (click)="setAnimalAsCible(animalSource, idx)"> Choisir comme cible </ion-button>

                      <ion-checkbox
                        slot="start"
                        [checked]="checkedList.includes(animalSource.id)"
                        id="checked"
                        (ionChange)="updateCheckedList($event, animalSource.id)"
                      ></ion-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid doublon-content">
                <div class="profil">
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Date de naissance</b></h2>
                      <p>{{ animalSource.dateNaissance | date: 'mediumDate' | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.dateNaissance" (click)="updateAnimalField('dateNaissance', animalSource.dateNaissance)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Id Vétophonie</b></h2>
                      <p>{{ animalSource.idVetophonie | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.idVetophonie" (click)="updateAnimalField('idVetophonie', animalSource.idVetophonie)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Poids</b></h2>
                      <p>{{ animalSource.poids | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.poids" (click)="updateAnimalField('poids', animalSource.poids)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Décédé</b></h2>
                      <p>{{ animalSource.decede ? 'Oui' : 'Non' }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.decede !== undefined" (click)="updateAnimalField('decede', animalSource.decede)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Couleur</b></h2>
                      <p>{{ animalSource.couleur | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.couleur" (click)="updateAnimalField('couleur', animalSource.couleur)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Mail utilisateur</b></h2>
                      <p>{{ animalSource.user?.mail | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.user?.mail" (click)="updateAnimalField('user', animalSource.user)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Nb prestations</b></h2>
                      <p>{{ animalSource.nbPrestations | defaultValue }}</p>
                    </ion-label>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Nom</b></h2>
                      <p>{{ animalSource.nom | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.nom" (click)="updateAnimalField('nom', animalSource.nom)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                </div>
                <div class="profil">
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Croisé</b></h2>
                      <p>{{ animalSource.croise ? 'Oui' : 'Non' }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.croise !== undefined" (click)="updateAnimalField('croise', animalSource.croise)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Espèce</b></h2>
                      <p>{{ animalSource.espece?.nom | defaultValue }}</p>
                    </ion-label>

                    <ion-button shape="round" *ngIf="animalSource.espece?.nom" (click)="updateAnimalField('espece', animalSource.espece)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Race</b></h2>
                      <p>{{ animalSource.race?.nom | defaultValue }}</p>
                    </ion-label>

                    <ion-button shape="round" *ngIf="animalSource.race?.nom" (click)="updateAnimalField('race', animalSource.race)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Puce</b></h2>
                      <p>{{ animalSource.puce | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.puce" (click)="updateAnimalField('puce', animalSource.puce)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Tatouage</b></h2>
                      <p>{{ animalSource.tatouage | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.tatouage" (click)="updateAnimalField('tatouage', animalSource.tatouage)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Sexe</b></h2>
                      <p>{{ animalSource.sexe | sexe }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.sexe" (click)="updateAnimalField('sexe', animalSource.sexe)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Date création</b></h2>
                      <p>{{ animalSource.createDateTime | date: 'yyyy-MM-dd HH:mm:ss' }}</p>
                    </ion-label>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Commentaire</b></h2>
                      <p>{{ animalSource.commentaire | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.commentaire" (click)="updateAnimalField('commentaire', animalSource.commentaire)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Stérilisé</b></h2>
                      <p>{{ animalSource.sterilise ? 'Oui' : 'Non' }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="animalSource.sterilise !== undefined" (click)="updateAnimalField('sterilise', animalSource.sterilise)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                </div>
              </div>
            </ng-container>

            <!--<ion-item
                    color="blue200"
                    lines="none"
                    [style.cursor]="'pointer'"
                    *ngFor="let animalSource of detailProps.animalSource | orderBy: 'nom'; let idx = index"
                    class="produit-item"
                  >
                    <ion-checkbox
                      slot="start"
                      [checked]="checkedList.includes(animalSource.id)"
                      id="checked"
                      (ionChange)="updateCheckedList($event, animalSource.id)"
                    ></ion-checkbox>
                    <ion-label class="ion-text-wrap" color="grey900">
                      <p>
                        <b>
                          {{
                            animalSource.nom +
                              (animalSource.espece || animalSource.race
                                ? ' (' +
                                  (animalSource.espece ? animalSource.espece?.nom + (animalSource.race ? ' / ' : '') : '') +
                                  (animalSource.race ? animalSource.race?.nom : '') +
                                  ')'
                                : '')
                          }}</b
                        >
                      </p>
                      <p *ngIf="animalSource.idVetophonie"><b>Id vétophonie : </b>{{ animalSource.idVetophonie }}</p>
                      <p><b>Créé le : </b>{{ animalSource.createDateTime | date: 'mediumDate' }}</p>
                      <p *ngIf="animalSource.user"><b>Utilisateur : </b>{{ animalSource.user.mail }}</p>
                      <p *ngIf="animalSource.commentaire"><b>Commentaire : </b>{{ animalSource.commentaire }}</p>
                      <p><b>Nb prestations : </b>{{ animalSource.nbPrestations }}</p>
                    </ion-label>
                    <ion-button slot="end" (click)="setAnimalAsCible(animalSource, idx)">Choisir comme cible </ion-button>
                  </ion-item>-->
          </ion-list>
        </ng-container>
        <ng-container *ngIf="isUser(detailProps)">
          <ion-list>
            <ng-container *ngFor="let userSource of detailProps.userSource | orderBy: 'nom'; let idx = index">
              <div class="invert gestion">
                <div class="welcome">
                  <div class="doublon-header">
                    <ion-item color="yellow200" lines="none">
                      <ion-label class="ion-text-wrap">
                        <ion-text>
                          <h1>{{ (userSource.prenom | titlecase) + ' ' + (userSource.nom | titlecase) }}</h1>
                          <p>Doublon</p>
                        </ion-text>
                      </ion-label>
                    </ion-item>
                    <div class="doublon-header-btns">
                      <ion-button shape="round" (click)="setUserAsCible(userSource, idx)"> Choisir comme cible </ion-button>

                      <ion-checkbox
                        slot="end"
                        [checked]="checkedList.includes(userSource.id)"
                        (ionChange)="updateCheckedList($event, userSource.id)"
                        mode="md"
                      ></ion-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid doublon-content">
                <div class="profil">
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Téléphone</b></h2>
                      <p>{{ userSource.telephone | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="userSource.telephone" (click)="updateUserField('telephone', userSource.telephone)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Téléphone 2</b></h2>
                      <p>{{ userSource.telephone2 | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="userSource.telephone2" (click)="updateUserField('telephone2', userSource.telephone2)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Nom</b></h2>
                      <p>{{ userSource.nom | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="userSource.nom" (click)="updateUserField('nom', userSource.nom)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Nom 2</b></h2>
                      <p>{{ userSource.nom2 | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="userSource.nom2" (click)="updateUserField('nom2', userSource.nom2)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Adresse</b></h2>
                      <p>{{ userSource.adresse?.adresse | defaultValue }}</p>
                    </ion-label>

                    <ion-button shape="round" *ngIf="userSource.adresse?.adresse" (click)="updateUserField('adresse.adresse', userSource.adresse.adresse)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Code postal</b></h2>
                      <p>{{ userSource.adresse?.codePostal | defaultValue }}</p>
                    </ion-label>

                    <ion-button
                      shape="round"
                      *ngIf="userSource.adresse?.codePostal"
                      (click)="updateUserField('adresse.codePostal', userSource.adresse.codePostal)"
                    >
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Ville</b></h2>
                      <p>{{ userSource.adresse?.ville | defaultValue }}</p>
                    </ion-label>

                    <ion-button shape="round" *ngIf="userSource.adresse?.ville" (click)="updateUserField('adresse.ville', userSource.adresse.ville)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Id Vétophonie</b></h2>
                      <p>{{ userSource.idVetophonie | defaultValue }}</p>
                    </ion-label>

                    <ion-button shape="round" *ngIf="userSource.idVetophonie" (click)="updateUserField('idVetophonie', userSource.idVetophonie)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                </div>
                <div class="profil">
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Nb animaux</b></h2>
                      <p>{{ userSource.nbAnimaux | defaultValue }}</p>
                    </ion-label>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Prénom</b></h2>
                      <p>{{ userSource.prenom | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="userSource.prenom" (click)="updateUserField('prenom', userSource.prenom)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Prénom 2</b></h2>
                      <p>{{ userSource.prenom2 | defaultValue }}</p>
                    </ion-label>
                    <ion-button shape="round" *ngIf="userSource.prenom2" (click)="updateUserField('prenom2', userSource.prenom2)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Mail</b></h2>
                      <p>{{ userSource.mail | defaultValue }}</p>
                    </ion-label>

                    <ion-button shape="round" *ngIf="userSource.mail" (click)="updateUserField('mail', userSource.mail)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Mail 2</b></h2>
                      <p>{{ userSource.mail2 | defaultValue }}</p>
                    </ion-label>

                    <ion-button shape="round" *ngIf="userSource.mail2" (click)="updateUserField('mail2', userSource.mail2)">
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Vérifié</b></h2>
                      <p>{{ userSource.verifie ? 'Oui' : 'Non' }}</p>
                    </ion-label>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Date création</b></h2>
                      <p>{{ userSource.createDateTime | date: 'yyyy-MM-dd HH:mm:ss' }}</p>
                    </ion-label>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-label color="grey900">
                      <h2><b>Nb prestations</b></h2>
                      <p>{{ userSource.nbPrestations | defaultValue }}</p>
                    </ion-label>
                  </ion-item>
                </div>
              </div>
            </ng-container>
          </ion-list>
        </ng-container>
        <br />
        <div class="fusionner-btn">
          <ion-button
            shape="round"
            button
            lines="none"
            color="blue600"
            class="ion-text-center"
            [disabled]="checkedList.length === 0"
            (click)="fusionner()"
            id="fusion"
          >
            Fusionner
          </ion-button>
          <br />
        </div>
      </div>
    </div>
  </div>
  <br />
</ion-content>
