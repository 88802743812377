import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trigramme',
  pure: true,
})
export class TrigrammePipe implements PipeTransform {
  transform(user: { nom?: string; prenom?: string }): string {
    if (user && user.nom && user.prenom) {
      return user.prenom[0] + user.nom[0] + user.nom[1];
    } else {
      return 'INCONNU';
    }
  }
}
