import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SavingService {
  private pendingOperationsSubject = new BehaviorSubject<number>(0); // le nombre de dispatch en cours

  get pendingOperations$(): Observable<number> {
    return this.pendingOperationsSubject.asObservable();
  }

  increment() {
    this.pendingOperationsSubject.next(this.pendingOperationsSubject.value + 1);
  }

  decrement() {
    this.pendingOperationsSubject.next(this.pendingOperationsSubject.value - 1);
  }
}
