import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'dateCompare',
})
export class DateComparePipe implements PipeTransform {
  constructor() {}

  /**
   * Renvoie true une fois que la date actuelle >= date fournie + intervalSeconds
   * @param minDate
   * @param intervalSeconds
   */
  transform(minDate: Date, intervalSeconds: number): Observable<boolean> {
    if (!minDate) {
      return of(false);
    }

    const nowTimeMs = new Date(minDate).getTime();
    const timeSpentMs = new Date().getTime() - nowTimeMs;
    const remainingTimeMs = intervalSeconds * 1000 - timeSpentMs;

    return timer(Math.max(remainingTimeMs, 0)).pipe(map(() => true));
  }
}
