import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '@core/services/user/user.service';
import { NavController, ToastController } from '@ionic/angular';
import { from, map, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'changement-mot-de-passe',
  templateUrl: './changement-mot-de-passe.page.html',
  styleUrls: ['./changement-mot-de-passe.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangementMotDePassePage implements OnInit {
  resetPasswordForm = this.formBuilder.group({
    password: [null, [Validators.required]],
    passwordConfirm: [null, [Validators.required]],
  });

  isSubmitting: boolean = false;
  codeVerif!: string;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly navCtrl: NavController,
    private readonly toastController: ToastController,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.codeVerif = this.route.snapshot.queryParams['code']!;
  }

  submit() {
    this.isSubmitting = true;
    const pass = this.resetPasswordForm.value.password;
    const passwordConfirm = this.resetPasswordForm.value.passwordConfirm;
    if (pass && passwordConfirm && pass === passwordConfirm) {
      this.userService
        .changePassword(this.codeVerif, pass)
        .pipe(
          switchMap(() =>
            from(
              this.toastController.create({
                header: 'Mot de passe modifié',
                duration: 5000,
                message: `Votre mot de passe a bien été changé`,
                position: 'bottom',
              }),
            ),
          ),
          map(toast => toast.present()),
          switchMap(() => this.navCtrl.navigateBack('/login')),
        )
        .subscribe(() => {
          this.isSubmitting = false;
          this.changeDetectorRef.markForCheck();
        });
    }
  }
}
