import { Pipe, PipeTransform } from '@angular/core';
import { isSet, orderBy } from 'lodash';

@Pipe({
  name: 'orderBy',
  pure: false,
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, sortBy: string | string[], order?: 'asc' | 'desc'): any[] {
    const sortOrder: 'asc' | 'desc' = order ? order : 'asc'; // setting default ascending order
    if (isSet(array)) {
      array = Array.from(array);
    }
    if (Array.isArray(sortBy)) {
      return orderBy(array, sortBy, [sortOrder]);
    }

    return orderBy(array, [sortBy], [sortOrder]);
  }
}
