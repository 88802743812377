import { Action, createReducer, on } from '@ngrx/store';
import { adminActions } from '../actions/admin.action';
import { Commune, Veterinaire } from 'app/models/admin.model';
import { Produit } from 'app/models/produit.model';
import { Acte, StructureVeterinaire } from '../../../../models/rdv.model';
import { User } from '../../../../models/user.model';

export interface AdminState {
  structureList: StructureVeterinaire[];
  communeList: Commune[];
  activeStructure?: StructureVeterinaire;
  produits: Produit[];
  isLoadingProduits: boolean;
  listVeto: Veterinaire[];
  listAsv: User[];
  selectedVeto?: Veterinaire;
  selectedAsv?: User;
}

export const initialState: AdminState = {
  structureList: [],
  communeList: [],
  produits: [],
  isLoadingProduits: false,
  listVeto: [],
  listAsv: [],
};

function addOrReplaceActe(structure: StructureVeterinaire, acte: Acte): Acte[] {
  if (structure.actes!.map(s => s.id).includes(acte.id)) {
    if (!acte.idStructure || acte.idStructure === structure.id) {
      return structure.actes!.map(s => {
        if (s.id === acte.id) {
          return acte;
        } else {
          return s;
        }
      });
    } else {
      return structure.actes!.filter(a => a.id !== acte.id);
    }
  } else {
    if (!acte.idStructure || acte.idStructure === structure.id) {
      return [...structure.actes!, acte];
    } else {
      return structure.actes!;
    }
  }
}

export const adminReducer = createReducer(
  initialState,

  on(
    adminActions.getAllStructureSuccess,
    (state, { structures }): AdminState => ({
      ...state,
      structureList: structures,
      activeStructure: state.activeStructure ? structures.find(s => s.id === state.activeStructure?.id) : undefined,
    }),
  ),
  on(adminActions.getAllCommuneSuccess, (state, { communes }): AdminState => ({ ...state, communeList: communes })),
  on(adminActions.setActiveStructure, (state, { activeStructure }): AdminState => ({ ...state, activeStructure })),
  on(adminActions.resetActiveStructure, (state): AdminState => ({ ...state, activeStructure: undefined })),
  on(adminActions.getVetoStructureSuccess, (state, { listVeto }): AdminState => ({ ...state, listVeto })),
  on(adminActions.getAsvListSuccess, (state, { listAsv }): AdminState => ({ ...state, listAsv })),
  on(adminActions.getSelectedVetoSuccess, (state, { selectedVeto }): AdminState => ({ ...state, selectedVeto })),
  on(adminActions.getSelectedAsvSuccess, (state, { selectedAsv }): AdminState => ({ ...state, selectedAsv })),
  on(adminActions.getSelectedStructureSuccess, (state, { structure }): AdminState => ({ ...state, activeStructure: structure })),
  on(adminActions.updateVetoSuccess, (state, { veto }): AdminState => ({ ...state, selectedVeto: veto })),
  on(adminActions.updateAsvSuccess, (state, { asv }): AdminState => ({ ...state, selectedAsv: asv })),
  on(adminActions.updateStructureSuccess, (state, { structure }): AdminState => ({ ...state, activeStructure: structure })),
  on(
    adminActions.saveActeSuccess,
    (state, { acte }): AdminState => ({
      ...state,
      activeStructure: state.activeStructure
        ? {
            ...state.activeStructure,
            actes: addOrReplaceActe(state.activeStructure, acte),
          }
        : undefined,
      structureList: state.structureList.map(s => {
        if (s.id === state.activeStructure?.id) {
          return { ...s, actes: addOrReplaceActe(s, acte) };
        } else {
          return s;
        }
      }),
    }),
  ),
);

export function reducer(state: AdminState, action: Action): AdminState {
  return adminReducer(state, action);
}
