<form [formGroup]="form" class="renouvelable">
  <div formArrayName="renouvelables">
    <ion-card class="card-ordo">
      <div class="card-content">
        <ion-grid>
          <ion-text class="no-items ion-text-center" *ngIf="!renouvelablesForm.length" color="grey600">
            <p>Aucun renouvelable</p>
          </ion-text>
          <ng-container *ngFor="let r of renouvelablesForm.controls; let i = index">
            <div [formGroupName]="i" class="prescription-line">
              <ion-row class="produit">
                <ng-container *ngIf="!mobile">
                  <ion-col size="8">
                    <fieldset>
                      <legend class="produit-legend">Produit n°{{ i + 1 }}</legend>
                      <ion-select
                        (ionChange)="filterProduitsPrescrits()"
                        formControlName="produit"
                        interface="popover"
                        [placeholder]="r.get('produit')!.value?.nom || (produitsPrescritsFiltres.length === 0 ? 'Aucun produit éligible' : '')"
                      >
                        <ng-container *ngFor="let p of produitsPrescritsFiltres | orderBy: 'nom'; trackBy: trackById">
                          <ion-select-option *ngIf="p.produit" [value]="p.produit">{{ p.produit.nom }} </ion-select-option>
                        </ng-container>
                      </ion-select>
                    </fieldset>
                  </ion-col>
                  <ion-col size="3.5" offset="0.5" class="quantity">
                    <fieldset>
                      <legend class="ion-text-wrap" [class]="r.get('produit')!.value?.unite ? 'with-unit' : ''">
                        Qté / mois{{ r.get('produit')!.value?.unite ? ' (' + (r.get('produit')!.value.unite | lowercase) + ')' : '' }}
                      </legend>
                      <french-number-input formControlName="quantity"></french-number-input>
                    </fieldset>
                  </ion-col>
                </ng-container>
                <ng-container *ngIf="mobile">
                  <ion-col size="12">
                    <fieldset>
                      <legend class="produit-legend">Produit n°{{ i + 1 }}</legend>
                      <ion-select
                        formControlName="produit"
                        interface="popover"
                        [placeholder]="r.get('produit')!.value?.nom"
                        [disabled]="!produitsPrescrits.length"
                      >
                        <ng-container *ngFor="let p of produitsPrescrits">
                          <ion-select-option *ngIf="p.produit" [value]="p.produit">{{ p.produit.nom }} </ion-select-option>
                        </ng-container>
                      </ion-select>
                    </fieldset>
                  </ion-col>
                  <ion-col size="12" class="quantity">
                    <fieldset>
                      <legend class="ion-text-wrap" [class]="r.get('produit')!.value?.unite ? 'with-unit' : ''">
                        Qté / mois{{ r.get('produit')!.value?.unite ? ' (' + (r.get('produit')!.value.unite | lowercase) + ')' : '' }}
                      </legend>
                      <french-number-input formControlName="quantity"></french-number-input>
                    </fieldset>
                  </ion-col>
                </ng-container>
                <ion-col size="6">
                  <fieldset>
                    <legend>Date d'échéance</legend>
                    <ion-input type="date" formControlName="dateEcheance"></ion-input>
                  </fieldset>
                </ion-col>
                <ion-col size="6" *ngIf="i > -1">
                  <div class="delete-btn">
                    <ion-button fill="clear" color="danger" *ngIf="renouvelablesForm.enabled" (click)="delete(i)" size="small">
                      <ion-icon name="close-outline" slot="start" color="danger" size="small"></ion-icon>
                      <ion-label color="danger">Supprimer</ion-label>
                    </ion-button>
                  </div>
                </ion-col>
              </ion-row>
            </div>
          </ng-container>
          <div>
            <ion-button
              expand="block"
              (click)="add()"
              *ngIf="renouvelablesForm.enabled && produitsPrescritsFiltres.length"
              color="blue600"
              class="add-btn"
              fill="clear"
              slot="start"
            >
              <ion-icon slot="start" name="add-circle-outline"></ion-icon>
              <span> <ion-label slot="start">Ajouter un produit</ion-label></span>
            </ion-button>
          </div>
        </ion-grid>
        <br />
      </div>

      <ion-row>
        <ion-col size="12">
          <div class="save-div">
            <ion-button
              color="secondary"
              class="secondary-btn"
              expand="block"
              (click)="save()"
              *ngIf="renouvelablesForm.enabled"
              [disabled]="saving || form.pristine"
            >
              <ion-spinner name="crescent" *ngIf="saving"></ion-spinner>
              <ion-label>Enregistrer</ion-label>
            </ion-button>
          </div>
          <br />
        </ion-col>
      </ion-row>
    </ion-card>
  </div>
</form>
