import { ChangeDetectorRef, Component, Input, OnInit, TrackByFunction, ViewEncapsulation } from '@angular/core';
import { ReferenceService } from '../../../rdv/wizard/services/reference.service';
import { ProduitPrescrit, ProduitRenouvelable } from '../../../../models/produit.model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Browser } from 'leaflet';
import { DatePipe } from '@angular/common';
import { User, UserRole } from '../../../../models/user.model';
import mobile = Browser.mobile;

@Component({
  selector: 'app-produit-renouvelable',
  templateUrl: './produit-renouvelable.component.html',
  styleUrls: ['./produit-renouvelable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProduitRenouvelableComponent implements OnInit {
  @Input() idAnimal!: string;
  @Input() produitsPrescrits!: ProduitPrescrit[];
  @Input() user!: User;
  produitsPrescritsFiltres: ProduitPrescrit[] = [];

  renouvelables: ProduitRenouvelable[] = [];
  protected readonly mobile = mobile;

  saving = false;
  form: FormGroup;

  constructor(
    private readonly referenceService: ReferenceService,
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly datePipe: DatePipe,
  ) {
    this.form = this.fb.group({
      renouvelables: this.fb.array([]),
    });
  }

  ngOnInit() {
    this.produitsPrescritsFiltres = this.produitsPrescrits;
    this.referenceService.getRenouvelablesByAnimal(this.idAnimal).subscribe(res => {
      this.renouvelables = res;
      this.loadForm();
      this.filterProduitsPrescrits();
    });
  }

  add() {
    (this.form.get('renouvelables') as FormArray).push(
      this.fb.group({
        id: [null],
        produit: [null, [Validators.required]],
        idAnimal: [this.idAnimal, [Validators.required]],
        quantity: [null, [Validators.required]],
        dateEcheance: [null, [Validators.required]],
      }),
    );
  }

  get renouvelablesForm(): FormArray {
    return this.form.get('renouvelables') as FormArray;
  }

  delete(idx: number) {
    const r = this.renouvelablesForm.at(idx);
    const id = r.get('id')!.value;
    if (id) {
      this.referenceService.deleteRenouvelable(id).subscribe(() => {
        this.renouvelables = this.renouvelables.filter(rr => rr.id !== id);
        this.renouvelablesForm.removeAt(idx);
        this.filterProduitsPrescrits();

        this.cd.markForCheck();
      });
    } else {
      this.renouvelablesForm.removeAt(idx);
      this.filterProduitsPrescrits();

      this.cd.markForCheck();
    }
  }

  save() {
    this.saving = true;
    this.cd.markForCheck();
    this.referenceService.saveRenouvelables(this.buildRenouvelables()).subscribe(res => {
      this.renouvelables = res;
      this.loadForm();
      this.saving = false;
      this.cd.markForCheck();
    });
  }

  private buildRenouvelables(): ProduitRenouvelable[] {
    return this.renouvelablesForm.controls.map(value => this.buildRenouvelable(value as FormGroup));
  }

  private buildRenouvelable(formGroup: FormGroup): ProduitRenouvelable {
    const rawValue = formGroup.getRawValue();

    return {
      id: rawValue.id,
      dateEcheance: rawValue.dateEcheance,
      idAnimal: rawValue.idAnimal,
      produit: rawValue.produit,
      quantiteUvcParMois: rawValue.quantity,
    };
  }

  private loadForm() {
    this.form = this.fb.group({
      renouvelables: this.fb.array([]),
    });
    this.renouvelables.forEach(r => {
      const formGroup = this.fb.group({
        id: [r.id],
        produit: [r.produit, [Validators.required]],
        idAnimal: [r.idAnimal, [Validators.required]],
        quantity: [r.quantiteUvcParMois, [Validators.required]],
        dateEcheance: [this.datePipe.transform(r.dateEcheance, 'yyyy-MM-dd'), [Validators.required]],
      });
      this.renouvelablesForm.push(formGroup);
    });
    if (![UserRole.ROLE_ADMIN, UserRole.ROLE_VETERINAIRE].includes(this.user.role)) {
      this.renouvelablesForm.disable();
    }
    this.cd.markForCheck();
  }

  protected readonly Browser = Browser;
  trackById: TrackByFunction<any> = (obj: any) => obj.id;

  filterProduitsPrescrits() {
    this.produitsPrescritsFiltres = this.produitsPrescrits.filter(
      p =>
        !this.renouvelables.some(r => r.produit.id === p.produit?.id) &&
        !this.renouvelablesForm.controls.map(c => c.get('produit')!.value).some(r => !!r && r.id === p.produit?.id),
    );
  }
}
