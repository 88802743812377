import { ModalController } from '@ionic/angular';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Animal } from 'app/models/animal.model';
import { Client, RdvDomicile } from 'app/models/rdv.model';
import { UserService } from '@core/services/user/user.service';
import { ChannelRappel, Rappel, TypeRappel } from '../../../../../models/user.model';
import { clientActions } from '@core/store/actions/clients.actions';
import { addDays } from 'date-fns';

@Component({
  selector: 'deces-modal-component',
  templateUrl: './deces-modal.component.html',
  styleUrls: ['./deces-modal.component.scss'],
})
export class DecesModalComponent {
  animal!: Animal;
  client!: Client;
  rdv?: RdvDomicile;
  deces?: boolean = true;
  envoiCondoleance: boolean = true;

  constructor(
    private readonly store: Store,
    private readonly modalCtrl: ModalController,
    protected readonly userSevice: UserService,
  ) {}

  confirm() {
    const dateDeces = new Date();
    if (this.envoiCondoleance) {
      const rappel: Rappel = {
        type: TypeRappel.CONDOLEANCE,
        channels: [ChannelRappel.MAIL],
        dateRappel: addDays(dateDeces, 1),
        userId: this.client.id,
        animalId: this.animal.id,
        contextId: this.rdv?.id,
      };
      this.userSevice.saveRappel(rappel).subscribe();
    }
    if (this.deces) {
      const animalToUpdate: Animal = JSON.parse(JSON.stringify(this.animal));
      animalToUpdate.decede = true;
      animalToUpdate.dateDeces = dateDeces;
      this.store.dispatch(clientActions.editFicheAnimal({ animal: animalToUpdate }));
    }
    this.modalCtrl.dismiss(true);
  }

  annuler() {
    this.modalCtrl.dismiss(false);
  }
}
