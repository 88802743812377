import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { FileCR, FileCRTypeEnum } from '../../../../models/compte-rendu.model';
import { CompteRenduService } from '../../../rdv/wizard/services/compte-rendu.service';
import { User } from '../../../../models/user.model';

@Component({
  selector: 'upload-animal-file',
  templateUrl: './upload-animal-file.component.html',
  styleUrls: ['./upload-animal-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadAnimalFileComponent {
  @Input() user!: User;
  @Output() readonly files: EventEmitter<FileCR[]> = new EventEmitter<FileCR[]>();

  public readonly MAX_FILE_SIZE_MB = 50;
  public readonly MAX_REQUEST_SIZE_MB = 100;

  displayedFiles: FileCR[] = [];
  isUploadingFiles = false;

  texte: any;
  filesToSave: FileCR[] = [];
  fileForm!: FormGroup;

  hasModifiedFiles = false;

  protected readonly FileCRTypeEnum = FileCRTypeEnum;
  hasFileOverSizeLimit = false;
  sumFileSizeOverSizeLimit = false;

  constructor(
    private readonly compteRenduService: CompteRenduService,
    private readonly cd: ChangeDetectorRef,
    private readonly modalCtrl: ModalController,
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly alertCtrl: AlertController,
    private readonly toastCtrl: ToastController,
  ) {
    this.initForm();
  }

  private initForm() {
    this.fileForm = this.fb.group({
      fileType: [null, [Validators.required]],
      dateDoc: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), [Validators.required]],
    });
  }

  uploadFile(event: any) {
    this.isUploadingFiles = true;
    this.cd.markForCheck();
    let fileList: FileList = event!.target!.files;
    const uploadedFilesTmp: FileCR[] = [];

    const checkFinishUpload = (i: number) => {
      if (i === fileList.length - 1) {
        this.isUploadingFiles = false;
        this.hasModifiedFiles = true;
        this.displayedFiles.push(...uploadedFilesTmp);
        this.filesToSave.push(...uploadedFilesTmp);

        this.sumFileSizeOverSizeLimit = this.filesToSave.map(f => f.file!.length).reduce((a, b) => a + b, 0) / 1024 / 1024 > this.MAX_REQUEST_SIZE_MB;
        this.cd.markForCheck();
      }
    };
    for (let i = 0; i < fileList.length; i++) {
      const reader = new FileReader();
      let file = fileList.item(i);
      const fileByteArray: number[] = [];
      if (file !== null) {
        reader.readAsArrayBuffer(file);
        reader.onloadend = async evt => {
          let arrayBuffer: ArrayBuffer;
          if (evt.target?.readyState == FileReader.DONE) {
            arrayBuffer = evt.target.result as ArrayBuffer;
            if (file!.name.endsWith('.jpg') || file!.name.endsWith('.png') || file!.name.endsWith('.jpeg')) {
              const resizedImage = await this.compteRenduService.resizeImage(file!, 1920, 1920).then(res => res?.arrayBuffer());
              if (resizedImage) {
                arrayBuffer = resizedImage;
              } else {
                console.error("Impossible de redimensionner l'image");
              }
            }
            if (arrayBuffer.byteLength === 0) {
              const toast = await this.toastCtrl.create({
                header: `Erreur lors du transfert du fichier : ${file!.name}`,
                message: 'Veuillez réessayer',
                color: 'danger',
              });
              await toast.present();
              this.isUploadingFiles = false;
              this.cd.markForCheck();

              return;
            }
            if (arrayBuffer.byteLength / 1024 / 1024 > this.MAX_FILE_SIZE_MB) {
              this.hasFileOverSizeLimit = true;
            }

            const array: Uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < array.length; i++) {
              fileByteArray.push(array[i]);
            }

            uploadedFilesTmp.push({
              fileName: file!.name,
              file: fileByteArray,
              type: this.fileForm.get('fileType')!.value,
              dateDocument: this.fileForm.get('dateDoc')!.value,
              auteur: {
                id: this.user.id,
              },
            });
            checkFinishUpload(i);
          }
        };
        reader.onerror = () => {
          checkFinishUpload(i);
        };
        reader.onabort = () => {
          checkFinishUpload(i);
        };
      } else {
        checkFinishUpload(i);
      }
    }
  }

  deleteDisplayedFile(index: number) {
    const animalFile = this.displayedFiles![index];
    if (animalFile.id !== undefined) {
      this.alertCtrl
        .create({
          header: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
          buttons: [
            {
              text: 'Non',
              handler: () => {},
            },
            {
              text: 'Oui',
              handler: () => {
                this.hasModifiedFiles = true;
                this.displayedFiles = this.displayedFiles!.filter(file => file.id !== animalFile.id);
                this.cd.markForCheck();
              },
            },
          ],
        })
        .then(res => {
          res.present();
        });
    } else {
      this.hasModifiedFiles = true;

      this.filesToSave.splice(
        this.filesToSave.findIndex(f => f.file === animalFile.file),
        1,
      );
      this.displayedFiles!.splice(index, 1);
      this.sumFileSizeOverSizeLimit = this.filesToSave.map(f => f.file!.length).reduce((a, b) => a + b, 0) / 1024 / 1024 > this.MAX_REQUEST_SIZE_MB;
      this.hasFileOverSizeLimit = this.filesToSave.map(f => f.file!.length).some((size: number) => size / 1024 / 1024 > this.MAX_FILE_SIZE_MB);

      this.cd.markForCheck();
    }
  }

  cancel() {
    this.resetModal();
  }

  save() {
    if (this.filesToSave.length) {
      this.files.emit(this.filesToSave);
      this.resetModal();
    }
  }

  private resetModal() {
    this.initForm();
    this.filesToSave = [];
    this.hasModifiedFiles = false;
    this.displayedFiles = [];
    this.texte = undefined;
    this.modalCtrl.dismiss();
    this.cd.markForCheck();
  }

  downloadFile(file: FileCR) {
    this.compteRenduService.downloadFile(file);
  }
}
