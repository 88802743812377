import { Pipe, PipeTransform } from '@angular/core';
import { RdvInterneType } from '../../../../models/rdv.model';

@Pipe({
  name: 'typeRdvInterneStr',
  pure: true,
})
export class TypeRdvInterneStrPipe implements PipeTransform {
  constructor() {}

  transform(value: RdvInterneType | string): string {
    switch (value) {
      case RdvInterneType.PHYSIQUE:
        return 'Physique';
      case RdvInterneType.VIRTUEL:
        return 'Virtuel';
      default:
        return '-';
    }
  }
}
