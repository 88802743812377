/** ApiValidationError */
export interface ApiValidationError {
  /** Message */
  message: string;

  /** Code */
  code: string;
}

/** ApiError */
export class ApiError extends Error {
  /** The error details */
  details?: any;

  /**
   * ApiError
   *
   * @param message The error message
   * @param details The error details
   */
  constructor(message: string, details?: ApiValidationError) {
    super(message);

    this.details = details;
  }
}
